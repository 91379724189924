import { createStyles, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import clone from 'clone';
import { useContext, useState } from 'react';
import Recaptcha from "react-google-recaptcha";
import { RequestResetPasswordInfo, SystemApi } from '~/axios';
import { AppContext } from '~/component/base/App';
import InputField from '~/component/common/InputField';
import MessageDialog from '~/component/common/MessageDialog';
import ScrollDialog from '~/component/common/ScrollDialog';
import { ErrorUtil } from '~/util/ErrorUtil';
import { StringUtil } from '~/util/StringUtil';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	recaptcha: {
		padding: theme.spacing(3, 0, 0, 3),
	}
}));

// ページ情報
class PageInfo {
	// メールアドレス
	mailAddress = "";
	// reCAPTCHAトークン
	recaptchaToken = "";

	/** ログイン用RequestResetPasswordInfoへ変換する */
	toRequestResetPasswordInfo(): RequestResetPasswordInfo {
		const ret: RequestResetPasswordInfo = {};
		ret.mailAddress = this.mailAddress;
		ret.recaptchaToken = this.recaptchaToken;
		return ret;
	}
}

type Props = {
	// 閉じるイベント
	onClose: () => void,
};

/** パスワードリセット要求ダイアログ */
export default function PasswordResetRequest(props: Props): JSX.Element {
	// ページ情報
	const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());
	// 通知メッセージ
	const [informMessage, setInformMessage] = useState<string>("");
	// エラーメッセージ
	const [errorMessage, setErrorMessage] = useState<string>("");
	// モバイルか
	const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));
	// appFunctions
	const appFuncs = useContext(AppContext)!;

	// 送信する
	const submit = async () => {
		if (!pageInfo.recaptchaToken) {	// reCAPTCHAトークンがないとき
			appFuncs.setErrorMessage("あなたがロボットでないことを確認してください。");
			return;
		}
		const mailAddress = pageInfo.mailAddress;
		try {
			const res = await new SystemApi().requestResetPassword(pageInfo.toRequestResetPasswordInfo());
			setInformMessage(`パスワード再設定のURLリンクを${mailAddress}に送信しました。パスワード再設定用リンクの有効期限は${res.data.passwordResetExpireDate!}です。`);
		} catch (err) {
			// TODO: enohata appFuncじゃだめ？err
			setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 入力内容が変更された際に呼ばれる
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const name = event.target.name as keyof PageInfo;
		if (name == 'mailAddress') {	// 半角英数入力制限のとき
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p[name] = StringUtil.filterAscii(value);
				return p;
			});
		}
	};

	// reCAPTCHAが変更された際に呼ばれる
	const onRecaptchaChange = (token: string | null) => {
		if (token) {	// トークンがあるとき
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p.recaptchaToken = token;
				return p;
			});
		}
	};

	// 通知メッセージを閉じる
	const closeInform = () => {
		props.onClose();
	};

	// エラーメッセージを閉じる
	const closeError = () => {
		setErrorMessage("");
	};

	// 描画
	const classes = useStyles();
	return (
		<ScrollDialog title="パスワードをリセットする" isOutsideClose={false} onClose={props.onClose}
			buttons={[
				{ label: "送信", variant: 'contained', color: 'primary', onClick: submit },
				{ label: "キャンセル", variant: 'outlined', color: 'default', onClick: props.onClose },
			]}>
			<span>登録されているメールアドレスを入力してください。</span>
			<InputField label="メールアドレス" name='mailAddress' width='300px'
				value={pageInfo.mailAddress} onChange={onInputChange} autoFocus={true} />
			<div className={classes.recaptcha}>
				<Recaptcha sitekey="6LeiGZ4cAAAAANOXXwwRzpg27_xX7sVony8hkSPI"
					size={isMobile ? 'compact' : 'normal'} onChange={onRecaptchaChange} />
			</div>
			{/** パスワードリセット通知メッセージ */}
			<MessageDialog title="通知" message={informMessage} onClose={closeInform} />
			{/** エラーメッセージ */}
			<MessageDialog title="エラー" message={errorMessage} onClose={closeError} />
		</ScrollDialog>
	);
}