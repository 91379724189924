import clone from 'clone';
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Customer, SystemApi } from "~/axios";
import { AppContext } from '~/component/base/App';
import InputField from '~/component/common/InputField';
import ScrollDialog from '~/component/common/ScrollDialog';
import { ErrorUtil } from '~/util/ErrorUtil';

// ページ情報
class PageInfo {
	// 名前
	name = "";
	// ふりがな
	ruby = "";
	// ニックネーム
	nickname = "";

	/** レコード保存用Customerへ変換する */
	toCustomer(): Customer {
		const ret: Customer = {};
		ret.name = this.name;
		ret.ruby = this.ruby;
		ret.nickname = this.nickname;
		return ret;
	}
}

// プロパティ
type Props = {
	/** 閉じるイベント */
	onClose: () => void,
};

/** 簡易登録ダイアログ */
export default function SimpleRegister(props: Props): JSX.Element {
	// ページ情報
	const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// history
	const history = useHistory();

	// 送信する
	const register = async () => {
		try {
			const res = await new SystemApi().createCustomer(pageInfo.toCustomer(), true);
			history.push(`/customers/${res.data.customerId!}`);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 入力内容が変更された際に呼ばれる
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const split = event.target.name.split('-');
		const name = split[0] as keyof PageInfo;
		if (name == 'name' || name == 'ruby' || name == 'nickname') {	// 入力欄のとき
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p[name] = value;
				return p;
			});
		}
	};

	// 描画
	return (
		<ScrollDialog title="過去の顧客簡易登録" isOutsideClose={false} onClose={props.onClose}
			buttons={[
				{ label: "登録", variant: 'contained', color: 'primary', onClick: register },
				{ label: "キャンセル", variant: 'outlined', color: 'default', onClick: props.onClose },
			]}>
			<InputField label="名前" name='name' width='200px' required={true}
				value={pageInfo.name} onChange={onInputChange} maxLength={50} />
			<InputField label="ふりがな(平仮名)" name='ruby' width='200px' placeholder="未入力可"
				value={pageInfo.ruby} onChange={onInputChange} maxLength={50} />
			<InputField label="ニックネーム" name='nickname' width='200px' placeholder="未入力可"
				value={pageInfo.nickname} onChange={onInputChange} maxLength={50} />
		</ScrollDialog>
	);
}
