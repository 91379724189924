import { createStyles, FormControlLabel, Grid, makeStyles, Switch } from '@material-ui/core';
import clone from 'clone';
import React, { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { AdminApi, Contract, Customer, SystemApi } from "~/axios";
import { AppContext } from '~/component/base/App';
import InputField from '~/component/common/InputField';
import MessageDialog from '~/component/common/MessageDialog';
import ScrollDialog, { ButtonInfo } from '~/component/common/ScrollDialog';
import { PermissionValueType } from '~/constant/PermissionValueType';
import { CustomerAuxil } from '~/model/CustomerAuxil';
import { DateUtil } from '~/util/DateUtil';
import { ErrorUtil } from '~/util/ErrorUtil';
import { ObjectUtil } from '~/util/ObjectUtil';
import { StringUtil } from "~/util/StringUtil";

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	switchBox: {
		maxWidth: '100%',
		width: '350px',
		textAlign: 'right',
		'& div': {
			display: 'inline-grid',
			width: '225px',
		},
		'& .MuiTypography-body1': {
			fontSize: '0.8rem',
		}
	},
	labelArea: {
		padding: theme.spacing(1, 3, 1, 3),
	},
	label: {
		paddingRight: theme.spacing(1),
		whiteSpace: 'nowrap',
	},
	selectArea: {
		padding: theme.spacing(2, 3, 0, 3),
	},
	select: {
		minWidth: '200px',
	},
	gray: {
		whiteSpace: 'nowrap',
		opacity: '0.75',
	},
}));

// ページ情報
class PageInfo {
	// 契約ID
	contractId = 0;
	// 顧客ID
	customerId = 0;
	// 名前
	name = "";
	// ニックネーム
	nickname = "";
	// 回数
	count = "";
	// キャンセル回数
	cancelCount = "";
	// 支払い金額
	paymentAmount = "";
	// 支払い日時
	paymentDate = "";
	// 顧客リスト
	customers: Customer[] = [];
	// 更新日時
	updateDate = "";
	// モード 'read' | 'write' | 'add' 
	mode = 'read';
	// 保存可能か
	enable = false;

	/** 入力内容が変化したか */
	isChanged(src: PageInfo): boolean {
		return (this.customerId != src.customerId || this.count != src.count
			|| this.cancelCount != src.cancelCount || this.paymentAmount != src.paymentAmount || this.paymentDate != src.paymentDate);
	}
	/** Customer配列から変換する */
	static fromCustomerList(src: Customer[]): PageInfo {
		const p = new PageInfo();
		p.customers = src;
		p.mode = 'add';
		p.enable = true;
		return p;
	}
	/** Contractから変換する */
	static fromContract(src: Contract): PageInfo {
		const p = new PageInfo();
		p.contractId = src.contractId!;
		p.customerId = src.customerId!;
		p.name = src.customer?.name ?? p.name;
		p.nickname = src.customer?.nickname ?? p.nickname;
		p.count = String(src.count!);
		p.cancelCount = String(src.cancelCount!);
		p.paymentAmount = String(src.paymentAmount!);
		p.paymentDate = src.paymentDate ? DateUtil.toDateString(src.paymentDate, true) : p.paymentDate;
		p.updateDate = src.updateDate!;
		p.mode = 'read';
		p.enable = true;
		return p;
	}
	/** レコード保存用Contractへ変換する */
	toContract(): Contract {
		const ret: Contract = {};
		if (this.customerId == -1) {	// 新規顧客のとき
			const name = this.customers.find((c) => c.customerId == -1)!.name!;
			ret.customer = { name: name };
		} else {
			ret.customerId = this.customerId;
		}
		ret.count = Number(this.count);
		ret.cancelCount = this.cancelCount ? Number(this.cancelCount) : undefined;
		ret.paymentAmount = Number(this.paymentAmount);
		ret.paymentDate = this.paymentDate || null;
		ret.updateDate = this.updateDate || undefined;
		return ret;
	}
}

// プロパティ
type Props = {
	/** 契約ID 0なら追加モード */
	contractId: number,
	/** 閉じるイベント */
	onClose: (isSaved: boolean) => void,
};

/** 契約情報編集ダイアログ */
export default function ContractEdit(props: Props): JSX.Element {
	// ページ情報
	const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());
	// オープン時ページ情報
	const [openInfo, setOpenInfo] = useState<PageInfo>(new PageInfo());
	// 確認メッセージ
	const [confirmMessage, setConfirmMessage] = useState<string>("");
	// appFunctions
	const appFuncs = useContext(AppContext)!;

	// レンダリング後フック
	useEffect(() => {
		void getRecord();
	}, []);

	// レコードを取得する
	const getRecord = async () => {
		try {
			if (props.contractId == 0) {	// 追加モードのとき
				const res = await new SystemApi().getCustomerList('select');
				const customers = res.data.filter((c) => !CustomerAuxil.isTemporaryCustomer(c.customerId!));
				const newCustomers = res.data.filter((c) => CustomerAuxil.isTemporaryCustomer(c.customerId!));
				ObjectUtil.sort(customers, 'lastTreatmentDate', 'desc');
				const p = PageInfo.fromCustomerList(newCustomers.concat(customers));
				setOpenInfo(p);
				setPageInfo(p);
			} else {	// 閲覧モードまたは編集モードのとき
				const res = await new AdminApi().getContract(props.contractId, 'contract');
				const p = PageInfo.fromContract(res.data);
				setOpenInfo(p);
				setPageInfo(p);
			}
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err), true);
		}
	};

	// 保存する
	const save = async () => {
		try {
			if (pageInfo.mode == 'add') {	// 追加モードのとき
				await new AdminApi().addContract(pageInfo.toContract());
			} else {	// 編集モードのとき
				await new AdminApi().saveContract(pageInfo.contractId, pageInfo.toContract());
			}
			props.onClose(true);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 閉じる
	const close = () => {
		props.onClose(false);
	};

	// 入力内容が変更された際に呼ばれる
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const split = event.target.name.split('-');
		const name = split[0] as keyof PageInfo;
		if (name == 'count' || name == 'cancelCount' || name == 'paymentAmount' || name == 'paymentDate') {	// 入力欄のとき
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				if (name == 'count' || name == 'paymentAmount') {	// 1以上の整数入力制限のとき
					p[name] = StringUtil.filterIntegerGreaterEqualOne(value);
				} else if (name == 'cancelCount') {	// 0以上の整数入力制限のとき
					p[name] = StringUtil.filterIntegerGreaterEqualZero(value);
				} else {
					p[name] = value;
				}
				return p;
			});
		} else if (name == 'mode') {	// スイッチのとき
			if (!event.target.checked && pageInfo.isChanged(openInfo)) {	// スイッチをOFFにし、入力内容が変化しているとき
				const msg = "変更内容が破棄されます。編集モードをOFFにしますか？";
				setConfirmMessage(msg);
			} else {
				setPageInfo((pageInfo) => {
					const p = clone(pageInfo);
					p[name] = !event.target.checked ? 'read' : 'write';
					return p;
				});
			}
		}
	};

	// 編集モード切り替え確認を承認したときに呼ばれる
	const onChangeModeConfirmSubmit = () => {
		setConfirmMessage("");
		setPageInfo((pageInfo) => {
			const p = clone(pageInfo);
			p.mode = 'read';
			return p;
		});
	};

	// 編集モード切り替え確認を閉じる
	const closeConfirm = () => {
		setConfirmMessage("");
	};

	const isAdminAll = appFuncs.checkPermission(PermissionValueType.AdminAll);
	let title: string;
	let buttons: ButtonInfo[];
	if (pageInfo.mode == 'read') {	// 閲覧モードのとき
		title = "契約情報";
		buttons = [
			{ label: "閉じる", variant: 'outlined', color: 'default', onClick: close },
		];
	} else {	// 編集モードまたは追加モードのとき
		title = (pageInfo.mode == 'write') ? "契約情報編集" : "契約情報追加";
		buttons = [
			{
				label: "保存", variant: 'contained', color: 'primary', onClick: save,
				disabled: !pageInfo.enable || !pageInfo.isChanged(openInfo), validate: true
			},
			{ label: "キャンセル", variant: 'outlined', color: 'default', onClick: close },
		];
	}

	// 描画
	const classes = useStyles();
	return (
		<ScrollDialog title={title} buttons={buttons} isOutsideClose={false} onClose={close}>
			{(isAdminAll && pageInfo.mode != 'add')
				&& <Grid container justifyContent='flex-end' alignItems='flex-end'>
					<Grid item className={classes.switchBox}>
						<FormControlLabel label={`編集モード${(pageInfo.mode == 'write') ? "ON" : "OFF"}`}
							control={<Switch checked={pageInfo.mode == 'write'}
								name='mode' color='primary' onChange={onInputChange} />} />
					</Grid>
				</Grid>}
			{(pageInfo.mode != 'read')
				? <>
					<InputField label="回数" name='count' width='200px' required={true}
						value={pageInfo.count} onChange={onInputChange} maxLength={8} />
					{(pageInfo.mode == 'write')
						&& <InputField label="キャンセル回数" name='cancelCount' width='200px' required={true}
							value={pageInfo.cancelCount} onChange={onInputChange} maxLength={8} />}
					<InputField label="支払金額" name='paymentAmount' width='200px' required={true}
						value={pageInfo.paymentAmount} onChange={onInputChange} maxLength={8} />
					<InputField label="支払日" name='paymentDate' type='date' width='300px'
						value={pageInfo.paymentDate} onChange={onInputChange} placeholder="プレース" />
				</>
				: <table>
					<tbody>
						<tr>
							<td className={classes.label}>名前 :</td>
							<td>
								{CustomerAuxil.isTemporaryCustomer(pageInfo.customerId)
									? <span>{pageInfo.name}</span>
									: <Link to={`/customers/${pageInfo.customerId}`}>
										<span>{pageInfo.name}</span>
										{pageInfo.nickname && <span className={classes.gray}>&ensp;{pageInfo.nickname}</span>}
									</Link>}
							</td>
						</tr>
						<tr>
							<td className={classes.label}>回数 :</td>
							<td>{(pageInfo.count == "1") ? "単発" : `${pageInfo.count}回コース`}</td>
						</tr>
						{(pageInfo.cancelCount != "0") && <tr>
							<td className={classes.label}>キャンセル回数 :</td>
							<td>{pageInfo.cancelCount}回</td>
						</tr>}
						<tr>
							<td className={classes.label}>支払金額 :</td>
							<td>{pageInfo.paymentAmount}円</td>
						</tr>
						{pageInfo.paymentDate && <tr>
							<td className={classes.label}>支払日 :</td>
							<td>{DateUtil.toDateString(pageInfo.paymentDate)}</td>
						</tr>}
					</tbody>
				</table>}
			{/** 編集モード切り替え確認メッセージ */}
			{confirmMessage
				&& <MessageDialog title="確認" message={confirmMessage} buttonType='okCancel'
					onClose={closeConfirm} onSubmit={onChangeModeConfirmSubmit} />}
		</ScrollDialog>
	);
}
