import { createStyles, makeStyles } from '@material-ui/core';
import Cookies from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Customer, SystemApi } from '~/axios';
import { AppContext } from '~/component/base/App';
import Pane from '~/component/common/Pane';
import VirtualizedTable from '~/component/common/VirtualizedTable';
import { DateUtil } from '~/util/DateUtil';
import { ErrorUtil } from '~/util/ErrorUtil';
import { ObjectUtil } from '~/util/ObjectUtil';

// スタイル
const useStyles = makeStyles(() => createStyles({
	noWrap: {
		whiteSpace: 'nowrap',
	},
	gray: {
		color: 'gray',
	},
}));

/** 顧客リスト画面 */
export default function CustomerList(): JSX.Element {
	// 行情報
	const [rows, setRows] = useState<Customer[]>([]);
	// 有効か
	const [enable, setEnable] = useState<boolean>(false);
	// 検索値
	const [searchValue, setSearchValue] = useState<string>("");
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// history
	const history = useHistory();

	// レンダリング後フック
	useEffect(() => {
		void getRecord();
	}, []);

	// レコードを取得する
	const getRecord = async () => {
		try {
			const res = await new SystemApi().getCustomerList('list');
			if (Cookies.get('phrase')) {    // ログイン権限があり、パスフレーズが設定されているとき
				history.push('/check');
			}
			const customers = res.data;
			for (const c of customers) {
				if (!c.nextTreatmentDate) {
					c.nextTreatmentDate = "なし";
				}
			}
			ObjectUtil.sort(customers, 'lastTreatmentDate', 'desc');
			setRows(customers);
			setEnable(true);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err), true);
		}
	};

	// フィルター判定
	const filterPredicate = (value: Customer, index: number, array: Customer[]): boolean => {
		if (!searchValue) { // 検索値がないとき
			return true;
		}
		return ((value.name?.indexOf(searchValue) ?? -1) != -1
			|| (value.ruby?.indexOf(searchValue) ?? -1) != -1
			|| (value.nickname?.indexOf(searchValue) ?? -1) != -1);
	};

	// 行を押したときに呼ばれる
	const onRowClick = (rowData: Customer) => {
		history.push(`/customers/${rowData.customerId!}`);
	};

	// 日時を描画する
	const renderDate = (rowData: Customer, dataKey?: keyof Customer): React.ReactNode => {
		if (!rowData[dataKey!] || rowData[dataKey!] == "なし") {
			return "なし";
		}
		return (
			<div>
				<span className={classes.noWrap}>{DateUtil.toDateString(rowData[dataKey!] as string)}</span>
				<span className={classes.gray}>&ensp;{DateUtil.toTimeString(rowData[dataKey!] as string)}</span>
			</div>
		);
	};

	// 描画
	const classes = useStyles();
	const filteredRows = rows.filter(filterPredicate);
	return (
		<Pane title="顧客リスト" enableContent={enable}
			searchValue={searchValue} onSearchChange={setSearchValue}
			rightElementOfSearch={<div>件数: {filteredRows.length}</div>}>
			<VirtualizedTable
				data={filteredRows}
				columns={[
					{ label: '名前', dataKey: 'name', width: 200 },
					{ label: 'ニックネーム', dataKey: 'nickname', width: 200 },
					{ label: '次回予約日', dataKey: 'nextTreatmentDate', width: 250, renderCellData: renderDate },
					{ label: '最終施術日', dataKey: 'lastTreatmentDate', width: 250, renderCellData: renderDate },
				]}
				defaultSortColumnDataKey='nextTreatmentDate'
				defaultSortOrder='asc'
				onRowClick={onRowClick}
			/>
		</Pane>
	);
}