/** 権限値タイプ */
export enum PermissionValueType {
	/** 権限なし */
	None = '-',
	/** 個人管理権限（個人の閲覧権限=ログイン権限） */
	AdminPersonal = 'P',
	/** 全体管理権限（全体の編集権限） */
	AdminAll = 'A',
	/** 個人情報編集権限 */
	PersonalWrite = 'W',
	/** 通常会員予約権限 */
	ReservationNormal = 'N',
	/** コース会員予約権限 */
	ReservationCourse = 'C',
}

export namespace PermissionValueType {
	/** 文字列を取得する */
	export function getString(value: PermissionValueType): string {
		switch (value) {
			case PermissionValueType.ReservationCourse:
				return "コース会員";
			case PermissionValueType.ReservationNormal:
				return "通常会員";
			default:
				return "";
		}
	}
}