import { createStyles, makeStyles, TableCell, TableSortLabel } from '@material-ui/core';
import clsx from 'clsx';
import { useState } from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { ObjectUtil } from '~/util/ObjectUtil';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	root: {
		width: '100%',
		maxHeight: '80%',
		border: '1px solid',
		borderColor: theme.palette.primary.light,
		marginTop: theme.spacing(1),
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	flexContainer: {
		display: 'flex',
		alignItems: 'center',
		boxSizing: 'border-box',
	},
	table: {
		'& .ReactVirtualized__Table__headerRow': {
			flip: false,
			paddingRight: (theme.direction === 'rtl') ? '0 !important' : undefined,
		},
	},
	tableRow: {
		minWidth: '100%',
		cursor: 'pointer',
	},
	tableRowHover: {
		'&:hover': {
			backgroundColor: theme.palette.grey[200],
		},
	},
	tableCell: {
		flex: 1,
		height: 60,
		padding: theme.spacing(1, 0, 1, 2),
		whiteSpace: 'normal',
	},
	noClick: {
		cursor: 'initial',
	},
}));

// 列情報
export type ColumnInfo<T> = {
	/** 列のラベル */
	label: string;
	/** 列のキー */
	dataKey?: keyof T;
	/** データ位置 default='left' */
	align?: 'left' | 'center' | 'right';
	/** 列の幅 default=200 */
	width?: number;
	/** ソートボタンが非表示か default=false */
	isSortHidden?: boolean;
	/** ソート関数 */
	sort?: (data: T[], orderBy: keyof T, order: 'asc' | 'desc') => void;
	/** セルの描画関数 */
	renderCellData?: (rowData: T, dataKey?: keyof T) => React.ReactNode;
};

// プロパティ
type Props<T> = {
	/** データ */
	data: T[];
	/** 列情報リスト */
	columns: ColumnInfo<T>[];
	/** 標準ソート列のキー */
	defaultSortColumnDataKey: keyof T;
	/** 標準ソートの向き default='asc' */
	defaultSortOrder?: 'asc' | 'desc';
	/** 表示列フィルター関数 defaultはフィルターしない */
	filterColumnPredicate?: (value: ColumnInfo<T>, index: number, array: ColumnInfo<T>[]) => boolean;
	/** 行クリックイベント */
	onRowClick?: (rowData: T) => void;
	/** 行クリック可能か判定する関数 defaultはonRowClick依存 */
	canRowClick?: (rowData: T) => boolean;
};

/** 仮想テーブル */
export default function VirtualizedTable<T>(props: Props<T>): JSX.Element {
	// ソート列のキー
	const [orderBy, setOrderBy] = useState<keyof T>(props.defaultSortColumnDataKey);
	// ソートの向き
	const [order, setOrder] = useState<'asc' | 'desc'>(props.defaultSortOrder ?? 'asc');

	// 見出しラベルが押されたときに呼ばれる
	const onHeadLabelClick = (dataKey: keyof T) => {
		if (dataKey == orderBy) { // 既にソート対象のとき
			setOrder((order) => (order == 'asc') ? 'desc' : 'asc');
		} else {  // ソート対象になるとき
			setOrderBy(dataKey);
			setOrder('asc');
		}
	};

	// ヘッダー描画関数
	const headerRenderer = (label: React.ReactNode, column: ColumnInfo<T>) => {
		return (
			<TableCell
				component='div'
				className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
				variant='head'
				sortDirection={(orderBy == column.dataKey) ? order : false}>
				{(!column.dataKey || (column.isSortHidden ?? false)) ? <span>{label}</span>
					: <TableSortLabel
						active={orderBy == column.dataKey}
						direction={(orderBy == column.dataKey) ? order : 'asc'}
						onClick={column.dataKey && (() => onHeadLabelClick(column.dataKey!))}
					>
						<span>{label}</span>
						{(orderBy == column.dataKey)
							&& <span className={classes.visuallyHidden}>
								{(order == 'asc') ? 'sorted ascending' : 'sorted descending'}
							</span>}
					</TableSortLabel>}
			</TableCell>
		);
	};

	// セル描画関数
	const cellRenderer = (row: T, column: ColumnInfo<T>) => {
		const canRowClick = (props.onRowClick !== undefined && (!props.canRowClick || props.canRowClick(row)));
		return (
			<TableCell
				component='div'
				className={clsx(classes.tableCell, classes.flexContainer, {
					[classes.noClick]: !canRowClick,
				})}
				variant='body'
				align={column.align ?? 'left'}
				onClick={() => { canRowClick && props.onRowClick!(row); }}>
				{column.renderCellData ? column.renderCellData(row, column.dataKey)
					: column.dataKey ? row[column.dataKey] : ""}
			</TableCell>
		);
	};

	// 描画
	const sortFunc = props.columns.find((c) => c.dataKey == orderBy)?.sort;
	if (sortFunc) {  // ソート関数があるとき
		sortFunc(props.data, orderBy, order);
	} else {
		ObjectUtil.sort(props.data, orderBy, order);
	}
	let sumWidth = 0;
	const columns = props.filterColumnPredicate
		? props.columns.filter(props.filterColumnPredicate) : props.columns;
	columns.forEach((column) => { sumWidth += (column.width ?? 200); });
	const rowHeight = 60;
	const rowCount = props.data.length;
	const classes = useStyles();
	return (
		<div className={classes.root} style={{ height: rowHeight * (rowCount + 1) }}>
			<AutoSizer>
				{({ width, height }) => (
					<Table
						height={height}
						width={width}
						rowHeight={rowHeight}
						gridStyle={{ direction: 'inherit' }}
						headerHeight={rowHeight}
						className={classes.table}
						rowCount={rowCount}
						rowGetter={({ index }) => props.data[index]}
						rowClassName={clsx(classes.tableRow, classes.flexContainer, {
							[classes.tableRowHover]: (props.onRowClick !== undefined),
						})}
						rowStyle={{ minWidth: '100%' }}>
						{columns.map((column) =>
							<Column
								key={column.label}
								headerRenderer={(headerProps) => headerRenderer(headerProps.label, column)}
								label={column.label}
								disableSort={column.dataKey === undefined || (column.isSortHidden ?? false)}
								className={classes.flexContainer}
								cellRenderer={(cellProps) => cellRenderer(cellProps.rowData as T, column)}
								dataKey={(column.dataKey ?? "") as string}
								width={(column.width ?? 200) * width / sumWidth}
							/>
						)}
					</Table>)}
			</AutoSizer>
		</div>
	);
}