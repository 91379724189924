import Cookies from "js-cookie";
import { useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { AppContext } from "~/component/base/App";
import InputDialog from "~/component/common/InputDialog";
import { PermissionValueType } from "~/constant/PermissionValueType";

/** パスフレーズ確認画面 */
export default function CheckPhrase(): JSX.Element {
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// history
	const history = useHistory();

	const customerId = Number(Cookies.get('register') ?? '0');

	// 送信する
	const submit = (input: string) => {
		if (input == Cookies.get('phrase')) {	// パスフレーズがあっていたとき
			Cookies.remove('phrase');
			if (customerId != 0) {	// 登録が完了しているとき
				history.push(`/customers/${customerId}`);
			} else {
				history.push('/');
			}
		} else {	// 間違っていたとき
			appFuncs.setErrorMessage("合言葉が間違っています。");
		}
	};

	// 戻る
	const back = () => {
		history.push('/register');
	};

	// 描画
	if (!Cookies.get('phrase') || !appFuncs.checkPermission(PermissionValueType.AdminAll)) {	// パスフレーズが設定されていない、または、全体管理権限がないとき
		return <Redirect to="/" />;
	}
	const title = (customerId != 0) ? "登録完了" : "登録が完了していません";
	const message = (customerId != 0) ? "店員に端末を渡してください。" : "登録画面に戻るか、他の画面に移動したい場合は合言葉を入力してください。";
	const buttonType = (customerId != 0) ? 'ok' : 'okBack';
	return (
		<InputDialog title={title} message={message} label="合言葉" buttonType={buttonType}
			onBack={back} onSubmit={submit} />
	);
}
