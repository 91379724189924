import { createStyles, FormControlLabel, Grid, makeStyles, Switch } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clone from 'clone';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import { Measurement, SystemApi, Treatment } from '~/axios';
import { AppContext } from '~/component/base/App';
import InputField from '~/component/common/InputField';
import MessageDialog from '~/component/common/MessageDialog';
import ScrollDialog, { ButtonInfo } from '~/component/common/ScrollDialog';
import { MeasurementType } from '~/constant/MeasurementType';
import { PermissionValueType } from '~/constant/PermissionValueType';
import { MeasurementAuxil, MeasurementPoint } from '~/model/MeasurementAuxil';
import { ErrorUtil } from '~/util/ErrorUtil';
import { StringUtil } from '~/util/StringUtil';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	sum: {
		fontSize: '1rem',
		padding: theme.spacing(0, 2, 0, 1),
	},
	switchBox: {
		width: '160px',
		'& .MuiTypography-body1': {
			fontSize: '0.8rem',
		}
	},
	table: {
		borderSpacing: '0',
		'& tr': {
			verticalAlign: 'middle',
		},
		'& tr td': {
			paddingTop: theme.spacing(1),
			lineHeight: '1',
		},
		'& tr td div': {
			display: 'inline-block',
		},
		'& .MuiOutlinedInput-input': {
			padding: '10px 8px',
		},
	},
	label: {
		fontSize: '0.95rem',
		paddingRight: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			boxSizing: 'border-box',
			maxWidth: '60px',
		},
	},
	value: {
		boxSizing: 'border-box',
		width: '55px',
		height: '39px',
		lineHeight: '20px',
		padding: '10px 8px',
		verticalAlign: 'middle',
		whiteSpace: 'nowrap',
	},
	diff: {
		boxSizing: 'border-box',
		minWidth: '50px',
		paddingLeft: theme.spacing(1),
	},
	minus: {
		color: 'blue',
	},
	plus: {
		color: 'red',
	},
	gray: {
		color: 'gray',
	},
}));

// 測定情報
class MeasurementInfo {
	// 測定ID
	measurementId = 0;
	// バスト
	bust = "";
	// アンダーバスト
	underBust = "";
	// ウエスト
	waist = "";
	// 下腹部
	underAbdomen = "";
	// ヒップ
	hip = "";
	// 左大腿部
	leftFemur = "";
	// 右大腿部
	rightFemur = "";
	// 左膝
	leftKnee = "";
	// 右膝
	rightKnee = "";
	// 左ふくらはぎ
	leftCalf = "";
	// 右ふくらはぎ
	rightCalf = "";
	// 左足首
	leftAnkle = "";
	// 右足首
	rightAnkle = "";
	// 左二の腕
	leftUpperArm = "";
	// 右二の腕
	rightUpperArm = "";
	// 更新日時
	updateDate = "";

	/** 入力内容が変化したか */
	isChanged(src: MeasurementInfo): boolean {
		for (const point of MeasurementAuxil.getPointKeys()) {
			if (this[point] != src[point]) {
				return true;
			}
		}
		return false;
	}
	/** Measurementから変換する */
	static fromMeasurement(src: Measurement): MeasurementInfo {
		const m = new MeasurementInfo();
		m.measurementId = src.measurementId!;
		for (const point of MeasurementAuxil.getPointKeys()) {
			m[point] = String(src[point]!);
		}
		m.updateDate = src.updateDate!;
		return m;
	}
	/** レコード保存用Measurementへ変換する */
	toMeasurement(): Measurement {
		const ret: Measurement = {};
		for (const point of MeasurementAuxil.getPointKeys()) {
			ret[point] = Number(this[point]!);
		}
		ret.updateDate = this.updateDate!;
		return ret;
	}
}

// ページ情報
class PageInfo {
	// 施術ID
	treatmentId = 0;
	// 施術日
	treatmentDate = "";
	// 名前
	name = "";
	// ニックネーム
	nickname = "";
	// 回数
	customerCount = 0;
	// 施術前の測定情報
	beforeMeasurement = new MeasurementInfo();
	// 施術後の測定情報
	afterMeasurement = new MeasurementInfo();
	// モード 'read' | 'write' | 'addBefore' | 'addAfter' | 'addNone'
	mode = 'read';
	// 保存可能か
	enable = false;
	// 一時JSON用ID
	jsonId = "";

	/** 入力内容が変化したか */
	isChanged(src: PageInfo): boolean {
		return (this.beforeMeasurement.isChanged(src.beforeMeasurement)
			|| this.afterMeasurement.isChanged(src.afterMeasurement));
	}
	/** Treatmentから変換する */
	static fromTreatment(src: Treatment): PageInfo {
		const p = new PageInfo();
		p.treatmentId = src.treatmentId!;
		p.treatmentDate = src.treatmentDate!;
		p.name = src.customer!.name!;
		p.nickname = src.customer!.nickname!;
		p.customerCount = src.customerCount!;
		if (src.measurements === null) {	// 測定なしのとき
			p.mode = 'addNone';
		} else {
			for (const m of src.measurements ?? []) {
				if (m.measurementType == MeasurementType.BeforeTreatment) {
					p.beforeMeasurement = MeasurementInfo.fromMeasurement(m);
				} else if (m.measurementType == MeasurementType.AfterTreatment) {
					p.afterMeasurement = MeasurementInfo.fromMeasurement(m);
				}
			}
			if (!p.beforeMeasurement.measurementId) {	// 施術前の測定結果がないとき
				p.mode = 'addBefore';
			} else if (!p.afterMeasurement.measurementId) {	// 施術前の測定結果があり施術後の測定結果がないとき
				p.mode = 'addAfter';
			} else {	// 施術前後の測定結果があるとき
				p.mode = 'read';
			}
		}
		p.enable = true;
		return p;
	}
	/** レコード保存用のMeasurementへ変換する */
	toMeasurement(type: MeasurementType): Measurement {
		let ret: Measurement = {};
		if (type == MeasurementType.BeforeTreatment) {
			ret = this.beforeMeasurement.toMeasurement();
		} else if (type == MeasurementType.AfterTreatment) {
			ret = this.afterMeasurement.toMeasurement();
		}
		ret.treatmentId = this.treatmentId;
		ret.measurementType = type;
		return ret;
	}
	/** 一時保存用JSONから変換する */
	static fromJson(treatmentId: number, json?: string): PageInfo {
		if (!json) {	// 一時保存用JSONがないとき
			return new PageInfo();
		}
		try {
			const obj = JSON.parse(json) as Record<string, unknown>;
			if (obj.jsonId == `measurement-${treatmentId}`) {	// 対象の一時保存用JSONがあるとき
				const p = Object.assign(new PageInfo(), obj);
				p.beforeMeasurement = Object.assign(new MeasurementInfo(), obj.beforeMeasurement);
				p.afterMeasurement = Object.assign(new MeasurementInfo(), obj.afterMeasurement);
				return p;
			} else {
				return new PageInfo();
			}
		} catch {
			return new PageInfo();
		}
	}
	/** 一時保存用JSONへ変換する */
	toJson(): string {
		this.jsonId = `measurement-${this.treatmentId}`;
		return JSON.stringify(this);
	}
	/** 指定された測定位置の値の前後の差を取得する */
	getDifference(point: MeasurementPoint): number | null {
		if (!this.beforeMeasurement[point] || !this.afterMeasurement[point]) {	// 前後どちらかの測定値がないとき
			return null;
		}
		const diff = Number(this.afterMeasurement[point]) - Number(this.beforeMeasurement[point]);
		return Math.round(diff * 10) / 10;
	}
}

// プロパティ
type Props = {
	/** 施術ID */
	treatmentId: number,
	/** 閉じるイベント */
	onClose: (isSaved: boolean) => void,
};

/** 測定結果編集ダイアログ */
export default function MeasurementEdit(props: Props): JSX.Element {
	// ページ情報
	const [pageInfo, setPageInfo] = useState<PageInfo>(PageInfo.fromJson(props.treatmentId, Cookies.get('tmp')));
	// オープン時ページ情報
	const [openInfo, setOpenInfo] = useState<PageInfo>(new PageInfo());
	// 保存回数
	const [saveCount, setSaveCount] = useState<number>(0);
	// 確認メッセージ
	const [confirmMessage, setConfirmMessage] = useState<string>("");
	// appFunctions
	const appFuncs = useContext(AppContext)!;

	// レンダリング後フック
	useEffect(() => {
		void getRecord();
	}, [saveCount]);

	// レンダリング後フック
	useEffect(() => {
		if (pageInfo.mode != 'read' && pageInfo.isChanged(openInfo)) {	// 入力内容が変化しているとき
			Cookies.set('tmp', pageInfo.toJson());
		}
	}, [pageInfo]);

	// レコードを取得する
	const getRecord = async () => {
		try {
			const res = await new SystemApi().getTreatment(props.treatmentId, 'measurement');
			const p = PageInfo.fromTreatment(res.data);
			setOpenInfo(p);
			if (saveCount > 0 || !pageInfo.enable) {	// オープン時の一時保存からの復帰ではないとき
				setPageInfo(p);
			}
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err), true);
		}
	};

	// 保存する
	const save = async () => {
		try {
			if (pageInfo.mode == 'addNone') {	// 測定なし追加モードのとき
				await new SystemApi().addMeasurement(pageInfo.toMeasurement(MeasurementType.None));
			} else if (pageInfo.mode == 'addBefore') {	// 施術前測定結果追加モードのとき
				await new SystemApi().addMeasurement(pageInfo.toMeasurement(MeasurementType.BeforeTreatment));
			} else if (pageInfo.mode == 'addAfter') {	// 施術後測定結果追加モードのとき
				await new SystemApi().addMeasurement(pageInfo.toMeasurement(MeasurementType.AfterTreatment));
			} else { // 編集モードのとき
				const saveBeforeP = new SystemApi().saveMeasurement(pageInfo.beforeMeasurement.measurementId,
					pageInfo.toMeasurement(MeasurementType.BeforeTreatment));
				const saveAfterP = new SystemApi().saveMeasurement(pageInfo.afterMeasurement.measurementId,
					pageInfo.toMeasurement(MeasurementType.AfterTreatment));
				await Promise.all([saveBeforeP, saveAfterP]);
			}
			setSaveCount((saveCount) => saveCount + 1);
			Cookies.remove('tmp');
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 閉じる
	const close = () => {
		props.onClose(saveCount > 0);
	};

	// 入力内容が変更された際に呼ばれる
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const split = event.target.name.split('-');
		const name = split[0] as keyof PageInfo;
		if (name == 'beforeMeasurement' || name == 'afterMeasurement') {	// 実数入力制限のとき
			const mName = split[1] as MeasurementPoint;
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p[name][mName] = StringUtil.filterFloat(value);
				return p;
			});
		} else if (name == 'mode') {	// スイッチのとき
			let nextMode: string;
			if (pageInfo.mode == 'addBefore' || pageInfo.mode == 'addNone') {	// 測定なし追加モードスイッチのとき
				if (event.target.checked) {	// 測定なし追加モードをONにするとき
					nextMode = 'addNone';
					if (pageInfo.isChanged(openInfo)) {	// 入力内容が変化しているとき
						openConfirm(nextMode);
						return;
					}
					Cookies.remove('tmp');
				} else {	// 測定なし追加モードをOFFにするとき
					nextMode = 'addBefore';
				}
			} else {	// 編集モードスイッチのとき
				if (!event.target.checked) {	// 編集モードをOFFにするとき
					if (openInfo.afterMeasurement.measurementId != 0) {	// 閲覧モードに戻るとき 
						nextMode = 'read';
						if (pageInfo.isChanged(openInfo)) {	// 入力内容が変化しているとき
							openConfirm(nextMode);
							return;
						}
						Cookies.remove('tmp');
					} else {	// 施術後測定結果追加モードに戻るとき
						nextMode = 'addAfter';
						if (pageInfo.beforeMeasurement.isChanged(openInfo.beforeMeasurement)) {	// 施術前測定結果の入力内容が変化しているとき
							openConfirm(nextMode);
							return;
						}
					}
				} else {	// 編集モードをONにするとき
					nextMode = 'write';
				}
			}
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p[name] = nextMode;
				return p;
			});
		}
	};

	// モード切り替え確認ダイアログを開く
	const openConfirm = (mode: string) => {
		let msg: string;
		if (mode == 'read') {	// 編集モードから閲覧モードに戻るとき
			msg = "変更内容が破棄されます。編集モードをOFFにしますか？\n（変更内容を保存したい場合は、保存ボタンを押してください。）";
		} else if (mode == 'addAfter') {	// 編集モードから施術後測定結果追加モードに戻るとき
			msg = "施術前の測定結果の変更内容が破棄されます。編集モードをOFFにして、施術後の測定結果の入力モードに戻りますか？";
		} else {	// 施術前測定結果追加モードから測定なし追加モードに変更するとき
			msg = "入力内容が破棄されます。測定なしに変更しますか？";
		}
		setConfirmMessage(msg);
	};

	// 編集モード切り替え確認を承認したときに呼ばれる
	const onConfirmSubmit = () => {
		if (confirmMessage.indexOf("施術後") != -1) {	// 施術後測定結果追加モードに戻るとき
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p.beforeMeasurement = clone(openInfo.beforeMeasurement);
				p.mode = 'addAfter';
				return p;
			});
		} else {	// 測定なし追加モードに変更するまたは閲覧モードに戻るとき
			Cookies.remove('tmp');
			const p = openInfo;
			p.mode = (confirmMessage.indexOf("測定なし") != -1) ? 'addNone' : 'read';
			setPageInfo(p);
		}
		setConfirmMessage("");
	};

	// 編集モード切り替え確認を閉じる
	const closeConfirm = () => {
		setConfirmMessage("");
	};

	// 測定値を描画する
	const renderPointValue = (point: MeasurementPoint, type: MeasurementType): React.ReactNode => {
		const measurement = (type == MeasurementType.AfterTreatment) ? 'afterMeasurement' : 'beforeMeasurement';
		const readModes = ['read', (type == MeasurementType.AfterTreatment) ? 'addBefore' : 'addAfter'];
		const value = pageInfo[measurement][point];
		return readModes.includes(pageInfo.mode)
			? <div className={classes.value}>{value || "未測定"}</div>
			: <InputField name={`${measurement}-${point}`} width='55px' padding='0'
				value={value} onChange={onInputChange} maxLength={8} />;
	};

	// 差を描画する
	const renderDifference = (point: MeasurementPoint): React.ReactNode => {
		const diff = pageInfo.getDifference(point);
		const className = clsx(classes.diff, {
			[classes.plus]: ((diff ?? 0) > 0),
			[classes.minus]: ((diff ?? 0) < 0)
		});
		const diffStr = (diff == null) ? "" : (diff > 0) ? `+${diff}` : diff;
		return <td className={className}>{diffStr}</td>;
	};

	// 差を描画する
	const renderDifferenceSum = (type: 'grid' | 'table'): React.ReactNode => {
		let sum = 0;
		for (const point of MeasurementAuxil.getPointKeys()) {
			if (point == 'bust') {
				continue;
			}
			const diff = pageInfo.getDifference(point);
			if (diff == null) {	// 差が取得できないとき
				return (type == 'grid') ? <Grid item></Grid> : "";
			}
			sum += diff;
		}
		return (type == 'grid')
			? <Grid item>
				<span className={classes.sum}>合計</span>
				<span className={clsx({ [classes.plus]: (sum > 0), [classes.minus]: (sum < 0) })}>
					{(sum > 0) ? `+${sum}` : sum}
				</span>
			</Grid>
			: <tr>
				<td className={classes.label}>合計（バストを除く）</td>
				<td><div className={classes.value}></div></td>
				<td></td>
				<td></td>
				<td>
					<span className={clsx(classes.diff, { [classes.plus]: (sum > 0), [classes.minus]: (sum < 0) })}>
						{(sum > 0) ? `+${sum}` : sum}
					</span>
				</td>
			</tr>;
	};

	let title: string;
	let buttons: ButtonInfo[];
	if (pageInfo.mode == 'read') {	// 閲覧モードのとき
		title = "測定結果";
		buttons = [
			{ label: "閉じる", variant: 'outlined', color: 'default', onClick: close },
		];
	} else {	// 編集モードまたは追加モードのとき
		title = (pageInfo.mode == 'write') ? "測定結果編集" : "測定結果入力";
		const isChanged = pageInfo.isChanged(openInfo)
			|| (pageInfo.mode == 'addNone' && pageInfo.mode != openInfo.mode);
		buttons = [
			{
				label: "保存", variant: 'contained', color: 'primary', onClick: save,
				disabled: !pageInfo.enable || !isChanged
			},
			{ label: "閉じる", variant: 'outlined', color: 'default', onClick: close },
		];
	}

	// 描画
	const isAdminAll = appFuncs.checkPermission(PermissionValueType.AdminAll);
	const classes = useStyles();
	return (
		<ScrollDialog title={title} buttons={buttons} isOutsideClose={false} onClose={close}>
			<div>
				{isAdminAll && <>
					<span>{pageInfo.name}</span>
					{pageInfo.nickname && <span className={classes.gray}>&ensp;{pageInfo.nickname}</span>}
				</>}
				{(pageInfo.customerCount > 0) && <span>&emsp;{pageInfo.customerCount}回目</span>}
			</div>
			{isAdminAll
				&& <Grid container justifyContent='space-between' alignItems='flex-end'>
					{renderDifferenceSum('grid')}
					<Grid item className={classes.switchBox}>
						{(pageInfo.mode == 'addBefore' || pageInfo.mode == 'addNone')
							? <FormControlLabel label='測定なし'
								control={<Switch checked={pageInfo.mode == 'addNone'}
									name='mode' color='primary' onChange={onInputChange} />} />
							: <FormControlLabel label={`編集モード${(pageInfo.mode == 'write') ? "ON" : "OFF"}`}
								control={<Switch checked={pageInfo.mode == 'write'}
									name='mode' color='primary' onChange={onInputChange} />} />}
					</Grid>
				</Grid>}
			{(pageInfo.mode != 'addNone')
				&& <table>
					<tbody className={classes.table}>
						{MeasurementAuxil.getPointKeys().map((point) =>
							<tr key={point}>
								<td className={classes.label}>{MeasurementAuxil.getPointString(point)}</td>
								<td>{renderPointValue(point, MeasurementType.BeforeTreatment)}</td>
								<td><ArrowForwardIcon /></td>
								<td>{renderPointValue(point, MeasurementType.AfterTreatment)}</td>
								{renderDifference(point)}
							</tr>)}
						{renderDifferenceSum('table')}
					</tbody>
				</table>}
			{/** 編集モード切り替え確認メッセージ */}
			<MessageDialog title="確認" message={confirmMessage} buttonType='okCancel'
				onClose={closeConfirm} onSubmit={onConfirmSubmit} />
		</ScrollDialog>
	);
}