import { Button, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AdminApi, ScheduleRelease } from '~/axios';
import ScheduleReleaseSettingAdd, { MEMBER_TYPE_LIST } from '~/component/admin/ScheduleReleaseSettingAdd';
import { AppContext } from '~/component/base/App';
import Pane from '~/component/common/Pane';
import VirtualizedTable from '~/component/common/VirtualizedTable';
import { PermissionValueType } from '~/constant/PermissionValueType';
import { DateUtil } from '~/util/DateUtil';
import { ErrorUtil } from '~/util/ErrorUtil';
import { ObjectUtil } from '~/util/ObjectUtil';

// TODO: Colorクラスに定数化
const blue = '#3788d8';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	noWrap: {
		whiteSpace: 'nowrap',
	},
	gray: {
		color: 'gray',
	},
	blue: {
		color: blue,
	},
	button: {
		margin: theme.spacing(1),
	},
}));

/** スケジュール公開設定画面 */
export default function ScheduleReleaseSetting(): JSX.Element {
	// 行情報
	const [rows, setRows] = useState<ScheduleRelease[]>([]);
	// 有効か
	const [enable, setEnable] = useState<boolean>(false);
	// 現在の公開設定ID
	const [releaseIds, setReleaseIds] = useState<number[]>([]);
	// メッセージ
	const [releaseMessage, setReleaseMessage] = useState<string[]>([]);
	// 検索値
	const [searchValue, setSearchValue] = useState<string>("");
	// 保存回数
	const [saveCount, setSaveCount] = useState<number>(0);
	// スケジュール公開設定登録ダイアログを開くか
	const [isReleaseAddOpen, setIsReleaseAddOpen] = useState<boolean>(false);
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// history
	const history = useHistory();

	// レンダリング後フック
	useEffect(() => {
		void getRecord();
	}, [saveCount]);

	// レコードを取得する
	const getRecord = async () => {
		try {
			const res = await new AdminApi().getScheduleReleaseList();
			if (Cookies.get('phrase')) {	// ログイン権限があり、パスフレーズが設定されているとき
				history.push('/check');
			}
			setRows(res.data);
			const resRelease = await new AdminApi().getScheduleReleaseCurrent();
			const ids: number[] = [];
			const msgs: string[] = [];
			for (const type of MEMBER_TYPE_LIST) {
				const release = resRelease.data.find((sr) => sr.memberType == type);
				if (release && ids.indexOf(release.releaseId!) == -1) {	// 同じ公開設定IDではないとき
					ids.push(release.releaseId!);
				}
				const msg = release ? `${DateUtil.toDateString(release.scheduleEndDate!)}まで公開` : `未公開`;
				msgs.push(`${PermissionValueType.getString(type)}: ${msg}`);
			}
			setReleaseIds(ids);
			setReleaseMessage(msgs);
			setEnable(true);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err), true);
		}
	};

	// フィルター判定
	const filterPredicate = (value: ScheduleRelease, index: number, array: ScheduleRelease[]): boolean => {
		if (!searchValue) {	// 検索値がないとき
			return true;
		}
		return ((value.memberType?.indexOf(searchValue) ?? -1) != -1);
	};

	// 追加する
	const addRelease = () => {
		setIsReleaseAddOpen(true);
	};

	// スケジュール公開設定登録ダイアログを閉じる
	const closeReleaseAdd = (isSaved: boolean) => {
		setIsReleaseAddOpen(false);
		if (isSaved) {	// 保存済のとき
			setSaveCount((saveCount) => saveCount + 1);
		}
	};

	// 公開日時をソートする
	const sortReleaseDate = (data: ScheduleRelease[], orderBy: keyof ScheduleRelease,
		order: 'asc' | 'desc'): void => {
		const releases = data.splice(0, data.length);
		for (const id of releaseIds) {
			const index = releases.findIndex((sr) => sr.releaseId == id);
			data.push(releases.splice(index, 1)[0]);
		}
		const now = Date.now();
		const oldReleases = releases.filter((r) => new Date(r.releaseDate!).getTime() <= now);
		const newReleases = releases.filter((r) => new Date(r.releaseDate!).getTime() > now);
		ObjectUtil.sort(oldReleases, 'releaseDate', 'desc');
		ObjectUtil.sort(newReleases, 'releaseDate', 'asc');
		if (order == 'asc') {
			data.push(...newReleases, ...oldReleases);
		} else {
			data.push(...oldReleases, ...newReleases);
		}
	};

	// 日付を描画する
	const renderDate = (rowData: ScheduleRelease, dataKey?: keyof ScheduleRelease): React.ReactNode => {
		if (dataKey == 'scheduleEndDate') {
			return (
				<div>
					<span className={classes.noWrap}>{DateUtil.toDateString(rowData[dataKey]!)}</span>
				</div>
			);
		} else if (dataKey == 'releaseDate' || dataKey == 'createDate') {
			const className = clsx(classes.noWrap, {
				[classes.blue]: (dataKey == 'releaseDate' && new Date(rowData[dataKey]!).getTime() > Date.now())
			});
			return (
				<div>
					<span className={className}>{DateUtil.toDateString(rowData[dataKey]!)}</span>
					&ensp;<span className={classes.gray}>{DateUtil.toTimeString(rowData[dataKey]!)}</span>
				</div>
			);
		}
		return "";
	};

	// 描画
	const filteredRows = rows.filter(filterPredicate);
	const classes = useStyles();
	return (
		<Pane title="スケジュール公開設定" enableContent={enable}
			searchValue={searchValue} onSearchChange={setSearchValue}
			headerElement={<div>{releaseMessage.map((msg, idx) => <div key={idx}>{msg}</div>)}</div>}
			rightElementOfSearch={<Button className={classes.button} type='submit'
				variant='contained' color='primary' onClick={addRelease}>
				追加
			</Button>}>
			<VirtualizedTable
				data={filteredRows}
				columns={[
					{ label: 'タイプ', dataKey: 'memberType', width: 100, isSortHidden: true },
					{
						label: '公開日時', dataKey: 'releaseDate', width: 200,
						renderCellData: renderDate, sort: sortReleaseDate
					},
					{ label: '対象期日', dataKey: 'scheduleEndDate', width: 200, renderCellData: renderDate },
					{ label: '作成日時', dataKey: 'createDate', width: 200, renderCellData: renderDate },
				]}
				defaultSortColumnDataKey='releaseDate'
				defaultSortOrder='asc' />
			{/** スケジュール公開設定登録ダイアログ */}
			{isReleaseAddOpen && <ScheduleReleaseSettingAdd onClose={closeReleaseAdd} />}
		</Pane>
	);
}