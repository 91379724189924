import { createStyles, IconButton, InputAdornment, makeStyles, Theme } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import React, { useRef, useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import { useToggle } from "react-use";

// スタイル
const useStyles = makeStyles<Theme, Props>((theme) => createStyles({
	root: {
		padding: (props) => props.padding ?? theme.spacing(2, 3, 0, 3),
	},
	field: {
		position: 'relative',
		maxWidth: '100%',
		width: (props) => props.width,
	},
}));

// プロパティ
type Props = {
	/** 表示タイプ default='outlined' */
	variant?: 'standard' | 'outlined' | 'filled',
	/** 入力タイプ default='text' */
	type?: 'text' | 'date' | 'time' | 'datetime-local' | 'password' | 'label',
	/** ラベル */
	label?: string,
	/** 名前 */
	name: string,
	/** 値 */
	value: string,
	/** 幅 */
	width: string,
	/** パディング */
	padding?: string,
	/** 必須か default=true */
	required?: boolean,
	/** 最小文字数 */
	minLength?: number,
	/** 最大文字数 */
	maxLength?: number,
	/** メールアドレスか default=false */
	isEmail?: boolean,
	/** 列数 */
	rows?: number,
	/** オートフォーカス default=false */
	autoFocus?: boolean,
	/** プレイスフォルダ */
	placeholder?: string,
	/** 入力欄の右端の表示 default='' type='passwordのとき非表示ボタン'*/
	endAdornment?: string,
	/** 変更イベント */
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
};

/** 入力フィールド */
export default function InputField(props: Props): JSX.Element {
	// フォーカスアウトされたか
	const [isBlurred, setIsBlurred] = useState<boolean>(false);
	// 入力文字が非表示マークか
	const [isTextHidden, invertIsTextHidden] = useToggle(props.type == 'password');
	// バリデーター
	const validator = useRef<TextValidator | null>(null);

	// フォーカスアウトした時に呼ばれる
	const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		validator.current?.validate(event.target.value, true, false);
		if (!isBlurred) {   // フォーカスアウトされる前のとき
			setIsBlurred(true);
		}
	};

	// 内容が変化した時に呼ばれる
	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.onChange(event);
		if (isBlurred) {    // フォーカスアウトされた後のとき
			validator.current?.validate(event.target.value, true, false);
		}
	};

	// 入力欄の右端
	const inputAdornment = (
		<InputAdornment position='end'>
			{(props.type == 'password')
				? <IconButton onClick={invertIsTextHidden}>
					{isTextHidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
				</IconButton>
				: props.endAdornment ?? ""}
		</InputAdornment>
	);

	// 描画
	const validators = [];
	const errorMessages = [];
	if (props.required) {   // 必須のとき
		validators.push('required');
		errorMessages.push("入力必須です");
	}
	if (props.maxLength) {  // 最大文字数が指定されているとき
		validators.push(`maxStringLength: ${props.maxLength}`);
		errorMessages.push(`${props.maxLength}文字以下で入力してください`);
	}
	if (props.minLength) {
		validators.push(`minStringLength: ${props.minLength}`);
		errorMessages.push(`${props.minLength}文字以上で入力してください`);
	}
	if (props.isEmail ?? false) {
		validators.push('isEmail');
		errorMessages.push("メールアドレスを正しく入力してください");
	}
	let type = props.type ?? 'text';
	if (type == 'password' && !isTextHidden) {  // パスワードモードの表示状態のとき
		type = 'text';
	}
	let inputProps: { [key: string]: unknown } | undefined = undefined;
	if (props.type == 'label') {
		if (props.endAdornment) {
			inputProps = { endAdornment: inputAdornment, readOnly: true };
		} else {
			inputProps = { readOnly: true };
		}
	} else {
		if (props.endAdornment || props.type == 'password') { // 指定されているまたはパスワードタイプのとき
			inputProps = { endAdornment: inputAdornment };
		}
	}
	let inputLabelProps: { [key: string]: unknown } | undefined = undefined;
	if (props.type == 'date' || props.placeholder) {
		inputLabelProps = { shrink: true };
	}
	const classes = useStyles(props);
	return (
		<div className={classes.root}>
			<TextValidator ref={validator} variant={props.variant} label={props.label}
				name={props.name} value={props.value} className={classes.field}
				rows={props.rows} multiline={((props.rows ?? 1) > 1)} autoFocus={props.autoFocus ?? false}
				placeholder={props.placeholder} autoComplete='off' validators={validators}
				errorMessages={errorMessages} onChange={onChange} onBlur={onBlur}
				type={type} InputProps={inputProps} InputLabelProps={inputLabelProps}
			/>
		</div>
	);
}
