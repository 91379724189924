import { format } from 'date-fns';

/** 日付ユーティリティ */
export class DateUtil {
	/** 文字列へ変換する */
	static toString(date: string | number | Date): string {
		return format(new Date(date), 'yyyy/MM/dd HH:mm');
	}

	/** 時間文字列へ変換する */
	static toTimeString(date: string | number | Date): string {
		return format(new Date(date), 'HH:mm');
	}

	/** 日付文字列へ変換する */
	static toDateString(date: string | number | Date, isHyphen?: boolean): string {
		if (isHyphen ?? false) {
			return format(new Date(date), 'yyyy-MM-dd');
		} else {
			return format(new Date(date), 'yyyy/MM/dd');
		}
	}

	/** 指定された秒数加える */
	static addSeconds(date: string | number | Date, seconds: number): Date {
		date = new Date(date);
		return new Date(date.setTime(date.getTime() + seconds * 1000));
	}

	/** 指定された時刻に秒数加える */
	static addSecondsAtTimeString(time: string, seconds: number): string {
		const dateStr = `${DateUtil.toDateString(new Date(), true)}T${time}`;
		const date = new Date(dateStr);
		return DateUtil.toTimeString(date.setTime(date.getTime() + seconds * 1000));
	}

	/** 指定された分数加える */
	static addMinutes(date: string | number | Date, minutes: number): Date {
		return DateUtil.addSeconds(date, minutes * 60);
	}

	/** 指定された日数を加えた日の00:00:00の基準時間からの経過ミリ秒を返す */
	static getMidnightTime(date: string | number | Date, addDays?: number): number {
		date = new Date(date);
		const midnight = new Date(date.setHours(0, 0, 0, 0));
		return midnight.setDate(midnight.getDate() + (addDays ?? 0));
	}

	/** 指定された日数を加えた日の00:00:00を返す */
	static getMidnight(date: string | number | Date, addDays?: number): Date {
		return new Date(DateUtil.getMidnightTime(date, addDays));
	}

	/** 年齢に変換する */
	static convertToAge(birthday: string | number | Date, date?: string | number | Date): number {
		date = date ? new Date(date) : new Date();
		birthday = new Date(birthday);
		const diffYear = date.getFullYear() - birthday.getFullYear();
		birthday.setFullYear(date.getFullYear());
		return (date < birthday) ? diffYear - 1 : diffYear;
	}
}