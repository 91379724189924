import { createStyles, FormControl, FormControlLabel, FormHelperText, FormLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import InputField from '~/component/common/InputField';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	root: {
		padding: theme.spacing(2, 1, 0, 3),
	},
	legend: {
		fontSize: '0.9rem',
	},
	input: {
		padding: '0',
	},
}));

/** ラジオボタン情報 */
export type RadioButtonInfo = {
	/** ラベル */
	label: string,
	/** 値 */
	value: string,
	/** 入力欄を表示するか default=false */
	input?: {
		/** 名前 */
		name: string,
		/** 値 */
		value: string,
		/** 最大文字数 */
		maxLength?: number,
		/** プレイスフォルダ */
		placeholder?: string,
		/** 必須か default=true */
		required?: boolean,
	},
};

// プロパティ
type Props = {
	/** 列方向表示か default=true */
	row?: boolean,
	/** ラベル */
	label?: string,
	/** 名前 */
	name: string,
	/** 値 */
	value: string,
	/** 読み取りモードか default=false */
	isRead?: boolean,
	/** ラジオボタン */
	radioButtons: RadioButtonInfo[],
	/** バリデーションするか default=false */
	validate?: boolean,
	/** 無効か default=false */
	disabled?: boolean,
	/** クリックイベント */
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
};

/** ラジオフィールド */
export default function RadioField(props: Props): JSX.Element {
	// クリックイベント
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		(props.onChange !== undefined) && props.onChange(event);
	};

	// エラーを表示するか
	const isShowError = (): boolean => {
		if (!(props.validate ?? false)) {   // 検証しないとき
			return false;
		}
		for (const radio of props.radioButtons) {
			if (radio.value == props.value) {   // 値がラジオボタンの値のとき
				return false;
			}
		}
		return true;
	};

	const classes = useStyles();
	if (props.isRead ?? false) {
		let radioLabel = "未回答";
		for (const radio of props.radioButtons) {
			if (props.value == radio.value) {   // 値が同じとき
				if (radio.input && radio.input.value) {  // 入力欄がある選択肢で入力されているとき
					radioLabel = radio.input.value;
				} else {
					radioLabel = radio.label;
				}
			}
		}
		return (
			<div className={classes.root}>
				<FormLabel className={classes.legend} component='legend'>{props.label}</FormLabel>
				<span>{radioLabel}</span>
			</div>
		);
	}
	return (
		<div className={classes.root}>
			<FormLabel className={classes.legend} component='legend'>{props.label}</FormLabel>
			<RadioGroup row={props.row ?? true} name={props.name} value={props.value}
				onChange={props.onChange}>
				{props.radioButtons.map((radio) =>
					<FormControlLabel control={<Radio />} label={radio.label} value={radio.value}
						key={radio.label} disabled={props.disabled ?? false} />
				)}
			</RadioGroup>
			{isShowError() && <FormControl error>
				<FormHelperText>選択必須です</FormHelperText>
			</FormControl>}
			{props.radioButtons.map((radio) =>
				radio.input && (props.value == radio.value)
				&& <InputField variant='standard' name={radio.input.name} key={radio.input.name}
					width='300px' padding='0' required={radio.input.required}
					value={radio.input.value} placeholder={radio.input.placeholder}
					onChange={handleChange} maxLength={radio.input.maxLength}
				/>
			)}
		</div>
	);
}