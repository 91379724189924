import clone from 'clone';
import { useContext, useState } from 'react';
import { Customer, SystemApi } from '~/axios';
import { AppContext } from '~/component/base/App';
import InputField from '~/component/common/InputField';
import MessageDialog from '~/component/common/MessageDialog';
import ScrollDialog from '~/component/common/ScrollDialog';
import { ErrorUtil } from '~/util/ErrorUtil';
import { StringUtil } from '~/util/StringUtil';

// ページ情報
class PageInfo {
	// 現在のパスワード
	currentPassword = "";
	// 新しいパスワード
	password = "";

	/** レコード保存用Customerへ変換する */
	toCustomer(passwordResetKey?: string, currentPassword?: string): Customer {
		const ret: Customer = {};
		ret.currentPassword = currentPassword ?? this.currentPassword;
		ret.password = this.password;
		ret.passwordResetKey = passwordResetKey ?? undefined;
		return ret;
	}
}

// プロパティ
type Props = {
	/** 顧客ID */
	customerId: number,
	/** パスワードリセットキー */
	passwordResetKey?: string,
	/** 現在のパスワード */
	currentPassword?: string,
	/** 閉じるイベント */
	onClose: (isSubmitted: boolean) => void,
};

/** パスワード編集ダイアログ、パスワード再設定ダイアログ、初期パスワード変更ダイアログ */
export default function PasswordEdit(props: Props): JSX.Element {
	// ページ情報
	const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());
	// 通知メッセージ
	const [informMessage, setInformMessage] = useState<string>("");
	// appFunctions
	const appFuncs = useContext(AppContext)!;

	// 送信する
	const submit = async () => {
		try {
			await new SystemApi().saveCustomerPassword(props.customerId, pageInfo.toCustomer(props.passwordResetKey, props.currentPassword));
			setInformMessage("パスワードを変更しました。");
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 閉じる
	const close = () => {
		props.onClose(false);
	};

	// 入力内容が変更された際に呼ばれる
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const name = event.target.name as keyof PageInfo;
		if (name == 'currentPassword' || name == 'password') {	// 半角英数入力制限のとき
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p[name] = StringUtil.filterAscii(value);
				return p;
			});
		}
	};

	// 通知メッセージを閉じる
	const closeInform = () => {
		props.onClose(true);
	};

	// 描画
	return (
		<ScrollDialog title={props.passwordResetKey ? "パスワード再設定"
			: props.currentPassword ? "仮パスワード変更" : "パスワード編集"}
			isOutsideClose={false} onClose={close}
			buttons={[
				{ label: "送信", variant: 'contained', color: 'primary', onClick: submit, validate: true },
				{ label: "キャンセル", variant: 'outlined', color: 'default', onClick: close },
			]}>
			{!props.passwordResetKey && !props.currentPassword
				&& <InputField label="現在のパスワード" name='currentPassword' type='password' width='300px'
					value={pageInfo.currentPassword} required={true} onChange={onInputChange} />}
			<InputField label="新しいパスワード" name='password' type='password' width='300px'
				value={pageInfo.password} required={true} onChange={onInputChange} />
			{/** パスワード変更通知メッセージ */}
			<MessageDialog title="通知" message={informMessage} onClose={closeInform} />
		</ScrollDialog>
	);
}