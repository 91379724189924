import { Checkbox, createStyles, FormControlLabel, Grid, makeStyles, MenuItem, Select, Switch } from '@material-ui/core';
import Anchor from '@material-ui/core/Link';
import clone from 'clone';
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Customer, Place, Reservation, SystemApi, Treatment } from "~/axios";
import { AppContext } from '~/component/base/App';
import InputField from '~/component/common/InputField';
import MessageDialog from '~/component/common/MessageDialog';
import ScrollDialog, { ButtonInfo } from '~/component/common/ScrollDialog';
import CustomerSelect from '~/component/system/CustomerSelect';
import MeasurementEdit from '~/component/system/MeasurementEdit';
import PlaceEdit, { AREA_LIST } from '~/component/system/PlaceEdit';
import { PermissionValueType } from '~/constant/PermissionValueType';
import { TreatmentStateType } from '~/constant/TreatmentStateType';
import { CustomerAuxil } from '~/model/CustomerAuxil';
import { DateUtil } from '~/util/DateUtil';
import { ErrorUtil } from '~/util/ErrorUtil';
import { StringUtil } from "~/util/StringUtil";

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	switchBox: {
		maxWidth: '100%',
		width: '300px',
		textAlign: 'right',
		'& div': {
			display: 'inline-grid',
			width: '225px',
		},
		'& .MuiTypography-body1': {
			fontSize: '0.8rem',
		},
	},
	labelArea: {
		padding: theme.spacing(2, 3, 0, 3),
	},
	label: {
		paddingRight: theme.spacing(1),
		whiteSpace: 'nowrap',
	},
	selectArea: {
		padding: theme.spacing(2, 3, 0, 3),
	},
	select: {
		minWidth: '200px',
	},
	selectPlace: {
		minWidth: '300px',
	},
	placeArea: {
		padding: theme.spacing(0, 3, 0, 3),
		maxWidth: '300px',
		wordBreak: 'break-all',
	},
	consent: {
		fontSize: '0.9rem',
		'& span': {
			fontSize: '0.9rem',
		},
	},
	gray: {
		whiteSpace: 'nowrap',
		opacity: '0.75',
	},
	anchor: {
		textDecoration: 'underline',
		color: '-webkit-link',
		cursor: 'pointer',
	},
	link: {
		marginLeft: theme.spacing(2),
	},
}));

// ページ情報
class PageInfo {
	// 施術ID
	treatmentId = 0;
	// 施術日
	treatmentDate = DateUtil.toDateString(new Date(), true);
	// 施術開始時刻
	treatmentTimePoint = "11:00";
	// 施術時間（単位：分）
	treatmentTime = "180";
	// 顧客ID
	customerId = 0;
	// 名前
	name = "";
	// ニックネーム
	nickname = "";
	// 地域
	area = "";
	// 場所ID
	placeId = 0;
	// 場所名
	placeName = "";
	// 地図URL
	mapUrl = "";
	// 場所詳細
	detail = "";
	// お茶
	tea = "";
	// アロマ
	aroma = "";
	// 測定結果の個数
	measurementCount: number | null = 0;
	// 状態
	state = TreatmentStateType.Empty;
	// 更新日時
	updateDate = "";
	// キャンセルポリシーに同意しているか
	isConsented = false;
	// モード 'read' | 'readReserved' | 'readReservedTemporary' | 'readTreated' | 'write' | 'writeTreated' | 'add' 
	mode = 'readTreated';
	// 保存可能か
	enable = false;

	/** 入力内容が変化したか */
	isChanged(src: PageInfo): boolean {
		return (this.treatmentDate != src.treatmentDate || this.treatmentTimePoint != src.treatmentTimePoint
			|| this.treatmentTime != src.treatmentTime || this.customerId != src.customerId
			|| this.area != src.area || this.placeId != src.placeId || this.tea != src.tea || this.aroma != src.aroma);
	}
	/** Treatmentから変換する */
	static fromTreatment(src: Treatment, isModeWrite: boolean): PageInfo {
		const p = new PageInfo();
		p.treatmentId = src.treatmentId!;
		p.treatmentDate = DateUtil.toDateString(src.treatmentDate!, true);
		p.treatmentTimePoint = DateUtil.toTimeString(src.treatmentDate!);
		p.treatmentTime = String(src.treatmentTime!);
		p.area = src.area!;
		p.placeId = src.placeId!;
		p.placeName = src.place?.placeName ?? p.placeName;
		p.mapUrl = src.place?.mapUrl ?? p.mapUrl;
		p.detail = src.place?.detail ?? p.detail;
		if (src.reservation) {	// 予約情報があるとき
			p.customerId = src.reservation.customerId!;
			p.name = src.reservation.customer?.name ?? p.name;
			p.nickname = src.reservation.customer?.nickname ?? p.nickname;
		}
		p.tea = src.tea ?? p.tea;
		p.aroma = src.aroma ?? p.aroma;
		p.measurementCount = (src.measurementCount === null) ? null : src.measurementCount!;
		p.state = src.state as TreatmentStateType;
		p.updateDate = src.updateDate ?? p.updateDate;
		if (isModeWrite) {	// write系モードのとき
			p.mode = (p.state == TreatmentStateType.Finished) ? 'writeTreated' : 'write';
		} else {	// read系モードのとき
			p.mode = (p.state == TreatmentStateType.Finished) ? 'readTreated'
				: (p.state == TreatmentStateType.Reserved) ? 'readReserved'
					: (p.state == TreatmentStateType.ReservedTemporary) ? 'readReservedTemporary'
						: 'read';
		}
		p.enable = true;
		return p;
	}
	/** レコード保存用Treatmentへ変換する */
	toTreatment(customerId?: number, name?: string): Treatment {
		const ret: Treatment = {};
		ret.treatmentDate = `${this.treatmentDate}T${this.treatmentTimePoint}`;
		ret.treatmentTime = Number(this.treatmentTime);
		ret.area = this.area;
		ret.placeId = this.placeId;
		ret.tea = this.tea;
		ret.aroma = this.aroma;
		ret.updateDate = this.updateDate || undefined;
		if (customerId != null) {	// 追加同時予約のとき
			if (customerId == -1) {	// 新規仮顧客のとき
				const c: Customer = {};
				c.name = name;
				ret.customer = c;
			} else {	// 既存顧客のとき
				ret.customerId = customerId;
			}
		}
		return ret;
	}
	/** レコード保存用Reservationへ変換する */
	toReservation(customerId: number, name?: string): Reservation {
		const ret: Reservation = {};
		if (customerId == -1) {	// 新規仮顧客のとき
			const c: Customer = {};
			c.name = name;
			ret.customer = c;
		} else {	// 既存顧客のとき
			ret.customerId = customerId;
		}
		return ret;
	}
}

// 顧客選択ダイアログ情報
type CustomerSelectInfo = {
	/** モード */
	mode: 'treat' | 'reserve',
	/** 予約している顧客ID */
	customerId?: number,
};

// メッセージ情報
type MessageInfo = {
	/** メッセージ */
	title: string,
	/** メッセージ */
	message: string,
};

// 確認メッセージ情報
type ConfirmMessageInfo = {
	/** メッセージ */
	message: string,
	/** 承認イベント */
	onSubmit: () => void,
};

// プロパティ
type Props = {
	/** 施術ID 0なら追加モード */
	treatmentId: number,
	/** 施術日 default=今日 */
	treatmentDate?: string,
	/** 閉じるイベント */
	onClose: (isSaved: boolean) => void,
};

/** 施術情報編集ダイアログ */
export default function TreatmentEdit(props: Props): JSX.Element {
	// ページ情報
	const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());
	// オープン時ページ情報
	const [openInfo, setOpenInfo] = useState<PageInfo>(new PageInfo());
	// 場所情報
	const [places, setPlaces] = useState<Place[]>([]);
	// 保存回数
	const [saveCount, setSaveCount] = useState<number>(0);
	// 次のモードが編集モードか
	const [isNextModeWrite, setIsNextModeWrite] = useState<boolean>(false);
	// 顧客選択ダイアログ情報
	const [customerSelect, setCustomerSelect] = useState<CustomerSelectInfo | null>(null);
	// 場所情報編集ダイアログ用場所ID
	const [placeId, setPlaceId] = useState<number>(0);
	// 測定結果編集ダイアログを開くか
	const [isMeasurementEditOpen, setIsMeasurementEditOpen] = useState<boolean>(false);
	// メッセージ
	const [message, setMessage] = useState<MessageInfo | null>(null);
	// 確認メッセージ
	const [confirmMessage, setConfirmMessage] = useState<ConfirmMessageInfo | null>(null);
	// appFunctions
	const appFuncs = useContext(AppContext)!;

	// レンダリング後フック
	useEffect(() => {
		void getRecord();
	}, [saveCount]);

	const isAdminAll = appFuncs.checkPermission(PermissionValueType.AdminAll);

	// レコードを取得する
	const getRecord = async () => {
		if (props.treatmentId == 0) {	// 追加モードのとき
			const p = new PageInfo();
			p.treatmentDate = props.treatmentDate || p.treatmentDate;
			p.mode = 'add';
			setPageInfo(p);
		} else {	// 閲覧モードまたは編集モードのとき
			try {
				const res = await new SystemApi().getTreatment(props.treatmentId, 'treatment');
				const p = PageInfo.fromTreatment(res.data, isNextModeWrite);
				setOpenInfo(p);
				setPageInfo(p);
				setIsNextModeWrite(false);
			} catch (err) {
				appFuncs.setErrorMessage(ErrorUtil.getMessage(err), true);
			}
		}
		if (isAdminAll) {	// 全体管理権限があるとき
			try {
				const res = await new SystemApi().getPlaceList();
				setPlaces(res.data);
				if (placeId < 0) {	// 保存した場所があるとき          
					const place = res.data.find((p) => p.placeId == -placeId);
					setPlaceId(0);
					setPageInfo((pageInfo) => {
						const p = clone(pageInfo);
						p.placeId = place?.placeId ?? 0;
						p.placeName = place?.placeName ?? "";
						p.mapUrl = place?.mapUrl ?? "";
						p.detail = place?.detail ?? "";
						return p;
					});
				}
			} catch (err) {
				appFuncs.setErrorMessage(ErrorUtil.getMessage(err), true);
			}
		}
	};

	// 保存する
	const save = async () => {
		try {
			await new SystemApi().saveTreatment(pageInfo.treatmentId, pageInfo.toTreatment());
			setSaveCount((saveCount) => saveCount + 1);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 追加する
	const add = async () => {
		try {
			await new SystemApi().addTreatment(pageInfo.toTreatment());
			props.onClose(true);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 施術実施する
	const treat = async () => {
		if (CustomerAuxil.isTemporaryCustomer(pageInfo.customerId)) {	// 仮顧客のとき
			setCustomerSelect({ mode: 'treat' });
			return;
		}
		try {
			await new SystemApi().saveTreatmentCustomerId(pageInfo.treatmentId);
			setIsNextModeWrite(true);
			setSaveCount((saveCount) => saveCount + 1);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 予約する
	const reserve = async () => {
		if (isAdminAll) {	// 全体管理権限があるとき
			setCustomerSelect({ mode: 'reserve' });
		} else {
			try {
				await new SystemApi().addTreatmentReservationId(pageInfo.treatmentId);
				setSaveCount((saveCount) => saveCount + 1);
				let title = "予約申し込み完了";
				let msg = `${DateUtil.toDateString(pageInfo.treatmentDate)} ${pageInfo.treatmentTimePoint}〜${DateUtil.addSecondsAtTimeString(pageInfo.treatmentTimePoint, Number(pageInfo.treatmentTime) * 60)}の予約を申し込みました。`;
				if (openInfo.state == TreatmentStateType.ReservedTemporary) {
					title += "（仮）";
					msg += "\nSalon de Gloriaの担当者が確認後、予約完了となります。";
				}
				setMessage({ title: title, message: msg });
			} catch (err) {
				appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
			}
		}
	};

	// 予約を取り消す
	const cancelReservation = () => {
		const msg = "予約を取り消します。";
		setConfirmMessage({ message: msg, onSubmit: onCancelReservationConfirmSubmit });
	};

	// 予約者を変更する
	const changeReservedCustomer = () => {
		setCustomerSelect({ mode: 'reserve', customerId: pageInfo.customerId });
	};

	// 追加同時予約する
	const addWithReserve = () => {
		setCustomerSelect({ mode: 'reserve' });
	};

	// 削除する
	const deleteTreatment = () => {
		const msg = "スケジュールを削除します。";
		setConfirmMessage({ message: msg, onSubmit: onDeleteTreatmentConfirmSubmit });
	};

	// 予約キャンセル確認を承認したときに呼ばれる
	const onCancelReservationConfirmSubmit = async () => {
		setConfirmMessage(null);
		try {
			await new SystemApi().deleteTreatmentReservationId(pageInfo.treatmentId);
			setSaveCount((saveCount) => saveCount + 1);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 削除確認を承認したときに呼ばれる
	const onDeleteTreatmentConfirmSubmit = async () => {
		setConfirmMessage(null);
		try {
			await new SystemApi().deleteTreatment(pageInfo.treatmentId);
			props.onClose(true);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 閉じる
	const close = () => {
		props.onClose(saveCount > 0);	// データが書き変わってない状態も余分に含まれてる
	};

	// 入力内容が変更された際に呼ばれる
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const split = event.target.name.split('-');
		const name = split[0] as keyof PageInfo;
		if (name == 'treatmentDate' || name == 'treatmentTimePoint' || name == 'treatmentTime'
			|| name == 'tea' || name == 'aroma') {	// 入力欄のとき
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				if (name == 'treatmentTime') {	// 0以上の整数入力制限のとき
					p[name] = StringUtil.filterIntegerGreaterEqualZero(value);
				} else {
					p[name] = value;
				}
				return p;
			});
		} else if (name == 'mode') {	// スイッチのとき
			let nextMode: string;
			if (!event.target.checked) {	// スイッチをOFFにするとき
				if (pageInfo.isChanged(openInfo)) {	// 入力内容が変化しているとき
					const msg = "変更内容が破棄されます。編集モードをOFFにしますか？";
					setConfirmMessage({ message: msg, onSubmit: onChangeModeConfirmSubmit });
					return;
				}
				nextMode = (openInfo.state == TreatmentStateType.Finished) ? 'readTreated'
					: (openInfo.state == TreatmentStateType.Reserved) ? 'readReserved'
						: (openInfo.state == TreatmentStateType.ReservedTemporary) ? 'readReservedTemporary'
							: 'read';
			} else {	// スイッチをONにするとき
				nextMode = (openInfo.state == TreatmentStateType.Finished) ? 'writeTreated' : 'write';
			}
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p[name] = nextMode;
				return p;
			});
		}
	};

	// チェックが変更された際に呼ばれる
	const onCheckChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		setPageInfo((pageInfo) => {
			const p = clone(pageInfo);
			p.isConsented = checked;
			return p;
		});
	};

	// 選択内容が変更された際に呼ばれる
	const onSelectChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
		const name = event.target.name as keyof PageInfo;
		if (name == 'area' || name == 'placeId') {	// 選択欄のとき
			if (name == 'placeId') {	// 場所IDのとき
				const value = event.target.value as number;
				if (value < 0) {	// 場所追加または場所編集のとき
					setPlaceId(-value);
					return;
				}
				const place = places.find((p) => p.placeId == value);
				setPageInfo((pageInfo) => {
					const p = clone(pageInfo);
					p[name] = value;
					p.placeName = place?.placeName ?? "";
					p.mapUrl = place?.mapUrl ?? "";
					p.detail = place?.detail ?? "";
					return p;
				});
			} else if (name == 'area') {	// 地域のとき
				const value = event.target.value as string;
				setPageInfo((pageInfo) => {
					const p = clone(pageInfo);
					p[name] = value;
					if (placeId > 0) {	// 場所が選択されていたとき
						p.placeId = 0;
						p.placeName = "";
						p.mapUrl = "";
						p.detail = "";
					}
					return p;
				});
			}
		}
	};

	// 編集モード切り替え確認を承認したときに呼ばれる
	const onChangeModeConfirmSubmit = () => {
		setConfirmMessage(null);
		setSaveCount((saveCount) => saveCount + 1);	// レコード更新ではないが再取得する
	};

	// 確認メッセージを閉じる
	const closeConfirm = () => {
		setConfirmMessage(null);
	};

	// 顧客選択ダイアログが承認される際に呼ばれる
	const onSelectSubmit = async (customerId: number, name?: string) => {
		const isTreat = (customerSelect!.mode == 'treat');
		setCustomerSelect(null);
		try {
			if (isTreat) {	// 施術実施のとき
				await new SystemApi().saveTreatmentCustomerId(pageInfo.treatmentId, customerId);
				setIsNextModeWrite(true);
				setSaveCount((saveCount) => saveCount + 1);
			} else if (pageInfo.mode == 'add') {	// 追加同時予約のとき
				await new SystemApi().addTreatment(pageInfo.toTreatment(customerId, name));
				props.onClose(true);
			} else {	// 予約または予約者変更のとき
				const withCancel = (pageInfo.customerId != 0);
				await new SystemApi().addTreatmentReservationId(pageInfo.treatmentId,
					withCancel, pageInfo.toReservation(customerId, name));
				setSaveCount((saveCount) => saveCount + 1);
			}
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 顧客選択ダイアログを閉じる
	const closeSelect = () => {
		setCustomerSelect(null);
	};

	// 場所情報編集ダイアログを閉じる
	const closePlaceEdit = (savedPlaceId?: number, area?: string) => {
		if (savedPlaceId) {	// 保存済のとき
			setPlaceId((area == pageInfo.area) ? -savedPlaceId
				: (savedPlaceId == pageInfo.placeId) ? -0.5 : -pageInfo.placeId);
			setIsNextModeWrite(true);
			setSaveCount((saveCount) => saveCount + 1);
		} else {
			setPlaceId(0);
		}
	};

	// 測定結果編集ダイアログを開く
	const openMeasurementEdit = () => {
		setIsMeasurementEditOpen(true);
	};

	// 測定結果編集ダイアログを閉じる
	const closeMeasurementEdit = (isSaved: boolean) => {
		setIsMeasurementEditOpen(false);
		if (isSaved) {	// 保存済のとき
			setSaveCount((saveCount) => saveCount + 1);
		}
	};

	// キャンセルポリシーを開く
	const openCancelPolicy = () => {
		setMessage({
			title: "キャンセルポリシー",
			message: "・予約完了時点で30%、\n　1週間〜3日前：50%\n　3日前〜前日：80%\n　当日：全額\nになりますので、よろしくお願いいたします。\n・予約変更1週間前以降は、30%手数料いただきますが、ご変更は可能です。\n・万が一サロンの都合で （江野畑美晴が病気・けがをして1か月以上動けない場合）は全額返金・もしくは復帰後、優先的に予約受付とさせていただきます。\n・通常の予約変更をお願いする場合で、お客様が予約をキャンセルされる場合には、キャンセルポリシー適用となります。"
		});
		setPageInfo((pageInfo) => {
			const p = clone(pageInfo);
			p.isConsented = true;
			return p;
		});
	};

	// メッセージを閉じる
	const closeMessage = () => {
		setMessage(null);
	};

	const isWriteMode = (pageInfo.mode == 'write' || pageInfo.mode == 'writeTreated');
	const isAfterTreatmentDay = (pageInfo.treatmentDate
		&& Date.now() > new Date(pageInfo.treatmentDate).getTime());
	let title: string;
	let buttons: ButtonInfo[];
	if (pageInfo.mode == 'read') {	// 閲覧モードのとき
		title = "スケジュール";
		if (isAdminAll) {	// 全体管理権限があるとき
			buttons = [
				{ label: "予約", variant: 'contained', color: 'primary', onClick: reserve, disabled: !pageInfo.enable },
				{ label: "削除", variant: 'outlined', color: 'secondary', onClick: deleteTreatment, disabled: !pageInfo.enable },
				{ label: "閉じる", variant: 'outlined', color: 'default', onClick: close },
			];
		} else {
			buttons = [
				{ label: "予約", variant: 'contained', color: 'primary', onClick: reserve, disabled: !(pageInfo.enable && pageInfo.isConsented) },
				{ label: "閉じる", variant: 'outlined', color: 'default', onClick: close },
			];
		}
	} else if (pageInfo.mode == 'readReserved' || pageInfo.mode == 'readReservedTemporary') {	// 予約閲覧モードまたは仮予約閲覧モードのとき
		title = (pageInfo.mode == 'readReserved') ? "予約情報" : "予約情報（仮）";
		if (isAdminAll) {	// 全体管理権限があるとき
			if (isAfterTreatmentDay) {	// 施術当日以降のとき
				buttons = [
					{ label: "施術実施", variant: 'contained', color: 'primary', onClick: treat, disabled: !pageInfo.enable },
					{ label: "予約削除", variant: 'outlined', color: 'secondary', onClick: cancelReservation, disabled: !pageInfo.enable },
					{ label: "予約者変更", variant: 'outlined', color: 'secondary', onClick: changeReservedCustomer, disabled: !pageInfo.enable },
					{ label: "閉じる", variant: 'outlined', color: 'default', onClick: close },
				];
			} else {	// 施術前日以前のとき
				buttons = [
					{ label: "予約削除", variant: 'outlined', color: 'secondary', onClick: cancelReservation, disabled: !pageInfo.enable },
					{ label: "予約者変更", variant: 'outlined', color: 'secondary', onClick: changeReservedCustomer, disabled: !pageInfo.enable },
					{ label: "閉じる", variant: 'outlined', color: 'default', onClick: close },
				];
			}
		} else {
			buttons = [
				{ label: "閉じる", variant: 'outlined', color: 'default', onClick: close },
			];
		}
	} else if (pageInfo.mode == 'readTreated') {	// 施術済み閲覧モードのとき
		title = "施術情報";
		buttons = [
			{ label: "閉じる", variant: 'outlined', color: 'default', onClick: close },
		];
	} else if (isWriteMode) {	// 編集モードまたは施術済み編集モードのとき
		title = (pageInfo.mode == 'writeTreated') ? "施術情報編集" : "スケジュール編集";
		buttons = [
			{
				label: "保存", variant: 'contained', color: 'primary', onClick: save,
				disabled: !pageInfo.enable || !pageInfo.isChanged(openInfo), validate: true
			},
			{ label: "キャンセル", variant: 'outlined', color: 'default', onClick: close },
		];
	} else {	// 追加モードのとき
		title = "予定追加";
		buttons = [
			{ label: "保存", variant: 'contained', color: 'primary', onClick: add, validate: true },
			{ label: "保存&予約", variant: 'contained', color: 'primary', onClick: addWithReserve, validate: true },
			{ label: "キャンセル", variant: 'outlined', color: 'default', onClick: close },
		];
	}

	// 描画
	const classes = useStyles();
	return (
		<ScrollDialog title={title} buttons={buttons} isOutsideClose={false} onClose={close}>
			{(isAdminAll && pageInfo.mode != 'add')
				&& <Grid container justifyContent='flex-end' alignItems='flex-end'>
					<Grid item className={classes.switchBox}>
						<FormControlLabel label={`編集モード${isWriteMode ? "ON" : "OFF"}`}
							control={<Switch checked={isWriteMode}
								name='mode' color='primary' onChange={onInputChange} />} />
					</Grid>
				</Grid>}
			{(isWriteMode || pageInfo.mode == 'add')
				? <>
					{(pageInfo.mode != 'writeTreated')
						? <>
							<InputField label="日付" name='treatmentDate' type='date' width='200px' required={true}
								value={pageInfo.treatmentDate} onChange={onInputChange} />
							<InputField label="開始時刻" name='treatmentTimePoint' type='time' width='200px' required={true}
								value={pageInfo.treatmentTimePoint} onChange={onInputChange} />
							<InputField label="時間（分）" name='treatmentTime' width='200px' required={true}
								value={pageInfo.treatmentTime} onChange={onInputChange} maxLength={3} />
						</>
						: <div className={classes.labelArea}>
							<span>{DateUtil.toDateString(pageInfo.treatmentDate)}</span>
							<span>&ensp;{pageInfo.treatmentTimePoint}
								〜{DateUtil.addSecondsAtTimeString(pageInfo.treatmentTimePoint, Number(pageInfo.treatmentTime) * 60)}</span>
						</div>}
					<div className={classes.selectArea}>
						<Select className={classes.select} name='area' variant='outlined'
							value={pageInfo.area || 0} onChange={onSelectChange}>
							<MenuItem disabled value={0}>
								<span className={classes.gray}>地域</span>
							</MenuItem>
							{AREA_LIST.map((area, idx) =>
								<MenuItem key={idx} value={area}>{area}</MenuItem>)}
						</Select>
					</div>
					<div className={classes.selectArea}>
						<Select className={classes.selectPlace} name='placeId' variant='outlined'
							value={pageInfo.placeId} onChange={onSelectChange}>
							<MenuItem disabled value={0}>
								<span className={classes.gray}>場所</span>
							</MenuItem>
							<MenuItem value={-0.5}>新規</MenuItem>
							{places.filter((p) => p.area == pageInfo.area).map((p, idx) =>
								<MenuItem key={idx} value={p.placeId}>{p.placeName}</MenuItem>)}
							{(pageInfo.placeId != 0) &&
								<MenuItem value={-pageInfo.placeId}>場所情報編集</MenuItem>}
						</Select>
					</div>
					<div className={classes.placeArea}>
						<table>
							<tbody>
								<tr>
									<td className={classes.label}>地図URL :</td>
									<td>
										{pageInfo.mapUrl && <a href={pageInfo.mapUrl} target='_blank' rel='noreferrer'>開く</a>}
									</td>
								</tr>
								<tr>
									<td className={classes.label}>場所詳細 :</td>
									<td>{pageInfo.detail}</td>
								</tr>
							</tbody>
						</table>
					</div>
					{(pageInfo.mode == 'writeTreated') && <>
						<InputField label="お茶" name='tea' width='200px' placeholder="未入力可"
							value={pageInfo.tea} onChange={onInputChange} maxLength={50} />
						<InputField label="アロマ" name='aroma' width='200px' placeholder="未入力可"
							value={pageInfo.aroma} onChange={onInputChange} maxLength={50} />
					</>}
				</>
				: <table>
					<tbody>
						<tr>
							<td className={classes.label}>日付 :</td>
							<td>{DateUtil.toDateString(pageInfo.treatmentDate)}</td>
						</tr>
						<tr>
							<td className={classes.label}>時間 :</td>
							<td>
								<span>{pageInfo.treatmentTimePoint}
									〜{DateUtil.addSecondsAtTimeString(pageInfo.treatmentTimePoint, Number(pageInfo.treatmentTime) * 60)}</span>
							</td>
						</tr>
						{(isAdminAll && pageInfo.customerId != 0) && <tr>
							<td className={classes.label}>名前 :</td>
							<td>
								{CustomerAuxil.isTemporaryCustomer(pageInfo.customerId)
									? <span>{pageInfo.name}</span>
									: <Link to={`/customers/${pageInfo.customerId}`}>
										<span>{pageInfo.name}</span>
										{pageInfo.nickname && <span className={classes.gray}>&ensp;{pageInfo.nickname}</span>}
									</Link>}
							</td>
						</tr>}
						<tr>
							<td className={classes.label}>地域 :</td>
							<td>{pageInfo.area}</td>
						</tr>
						<tr>
							<td className={classes.label}>場所 :</td>
							<td>
								<span>{pageInfo.placeName || (pageInfo.mapUrl ? "" : "未定")}</span>
								{pageInfo.mapUrl && <>
									{pageInfo.placeName && <span>&nbsp;</span>}
									<a href={pageInfo.mapUrl} target='_blank' rel='noreferrer'>URL</a>
								</>}
							</td>
						</tr>
						{pageInfo.detail && <tr>
							<td className={classes.label}>場所詳細 :</td>
							<td>{pageInfo.detail}</td>
						</tr>}
						{isAdminAll && pageInfo.tea && <tr>
							<td className={classes.label}>お茶 :</td>
							<td>{pageInfo.tea}</td>
						</tr>}
						{isAdminAll && pageInfo.aroma && <tr>
							<td className={classes.label}>アロマ :</td>
							<td>{pageInfo.aroma}</td>
						</tr>}
						{(pageInfo.measurementCount == 2
							|| pageInfo.measurementCount == null && isAdminAll
							|| isAdminAll && pageInfo.mode != 'read' && isAfterTreatmentDay
							&& !CustomerAuxil.isTemporaryCustomer(pageInfo.customerId))
							&& <tr>
								<td className={classes.label}>測定結果 :</td>
								<td>
									{(pageInfo.measurementCount == null) ? "なし"
										: <Anchor className={classes.anchor} onClick={openMeasurementEdit}>
											{(pageInfo.measurementCount == 2) ? "表示" : "入力"}
										</Anchor>}
									{(pageInfo.measurementCount == 2) && <Link className={classes.link}
										to={isAdminAll ? `/measurements?search=${pageInfo.name}` : '/measurements'}>
										一覧へ
									</Link>}
								</td>
							</tr>}
						{(!isAdminAll && pageInfo.mode == 'read') && <tr>
							<td className={classes.consent} colSpan={2}>
								<FormControlLabel label="キャンセルポリシーに同意"
									control={<Checkbox checked={pageInfo.isConsented} color='primary' onChange={onCheckChange} />} />
								<Anchor onClick={openCancelPolicy}>表示</Anchor>
							</td>
						</tr>}
					</tbody>
				</table>
			}
			{/** 顧客選択ダイアログ */}
			{customerSelect
				&& <CustomerSelect mode={customerSelect.mode} customerId={customerSelect.customerId}
					onClose={closeSelect} onSubmit={onSelectSubmit} />}
			{/** 場所情報編集ダイアログ */}
			{(placeId > 0)
				&& <PlaceEdit placeId={placeId} onClose={closePlaceEdit} />}
			{/** 測定結果編集ダイアログ */}
			{isMeasurementEditOpen
				&& <MeasurementEdit treatmentId={pageInfo.treatmentId} onClose={closeMeasurementEdit} />}
			{/** メッセージ */}
			{message
				&& <MessageDialog title={message.title} message={message.message} onClose={closeMessage} />}
			{/** 確認メッセージ */}
			{confirmMessage
				&& <MessageDialog title="確認" message={confirmMessage.message} buttonType='okCancel'
					onClose={closeConfirm} onSubmit={confirmMessage.onSubmit} />}
		</ScrollDialog>
	);
}
