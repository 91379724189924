import axios from 'axios';
import { ErrorInfo } from '~/axios';

/** エラーユーティリティ */
export class ErrorUtil {
	/** メッセージを取得する */
	static getMessage(error: unknown): string {
		if (axios.isAxiosError(error) && error.response) {
			return (error.response.data as ErrorInfo).error!;
		} else if (error instanceof Error) {
			return error.message;
		} else {
			return "不明なエラー";
		}
	}
}