import { Button, createStyles, Link, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AdminApi, Contract } from '~/axios';
import ContractEdit from '~/component/admin/ContractEdit';
import SimpleRegister from '~/component/admin/SimpleRegister';
import { AppContext } from '~/component/base/App';
import Pane from '~/component/common/Pane';
import VirtualizedTable from '~/component/common/VirtualizedTable';
import { DateUtil } from '~/util/DateUtil';
import { ErrorUtil } from '~/util/ErrorUtil';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	noWrap: {
		whiteSpace: 'nowrap',
	},
	gray: {
		color: 'gray',
	},
	button: {
		margin: theme.spacing(1),
	},
}));

/** 契約一覧画面 */
export default function ContractList(): JSX.Element {
	// 行情報
	const [rows, setRows] = useState<Contract[]>([]);
	// 有効か
	const [enable, setEnable] = useState<boolean>(false);
	// 検索値
	const [searchValue, setSearchValue] = useState<string>("");
	// 保存回数
	const [saveCount, setSaveCount] = useState<number>(0);
	// 契約情報編集ダイアログ用契約ID
	const [contractId, setContractId] = useState<number>(-1);
	// 過去の顧客簡易登録ダイアログを開くか
	const [isSimpleRegisterOpen, setIsSimpleRegisterOpen] = useState<boolean>(false);
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// history
	const history = useHistory();

	// レンダリング後フック
	useEffect(() => {
		void getRecord();
	}, [saveCount]);

	// レコードを取得する
	const getRecord = async () => {
		try {
			const res = await new AdminApi().getContractList('list');
			if (Cookies.get('phrase')) {	// ログイン権限があり、パスフレーズが設定されているとき
				history.push('/check');
			}
			setRows(res.data);
			setEnable(true);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err), true);
		}
	};

	// フィルター判定
	const filterPredicate = (value: Contract, index: number, array: Contract[]): boolean => {
		if (!searchValue) {	// 検索値がないとき
			return true;
		}
		if (searchValue.startsWith("コース")) {
			return ((value.count ?? 0) > 1);
		}
		return (value.paymentDate && DateUtil.toDateString(value.paymentDate).indexOf(searchValue.replace(/-/g, '/')) != -1
			|| (value.customer?.name?.indexOf(searchValue) ?? -1) != -1
			|| (value.customer?.ruby?.indexOf(searchValue) ?? -1) != -1
			|| (value.customer?.nickname?.indexOf(searchValue) ?? -1) != -1
			|| String(value.count ?? "").startsWith(searchValue));
	};

	// 追加する
	const addContract = () => {
		setContractId(0);
	};

	// 行を押したときに呼ばれる
	const onRowClick = (rowData: Contract) => {
		setContractId(rowData.contractId!);
	};

	// 契約情報編集ダイアログを閉じる
	const closeContractEdit = (isSaved: boolean) => {
		setContractId(-1);
		if (isSaved) {	// 保存済のとき
			setSaveCount((saveCount) => saveCount + 1);
		}
	};

	// 過去の顧客簡易登録ダイアログを閉じる
	const closeSimpleRegister = () => {
		setIsSimpleRegisterOpen(false);
	};

	// 日付を描画する
	const renderDate = (rowData: Contract, dataKey?: keyof Contract): React.ReactNode => {
		if (!rowData.paymentDate) {
			return "";
		}
		return (
			<div>
				<span className={classes.noWrap}>{DateUtil.toDateString(rowData.paymentDate)}</span>
			</div>
		);
	};

	// 名前を描画する
	const renderName = (rowData: Contract, dataKey?: keyof Contract): React.ReactNode => {
		return (
			<div>
				<span className={classes.noWrap}>{rowData.customer?.name ?? ""}</span>
				{rowData.customer?.nickname && <>
					&ensp;<span className={clsx(classes.noWrap, classes.gray)}>{rowData.customer.nickname}</span>
				</>}
			</div>
		);
	};

	// 金額を描画する
	const renderAmount = (rowData: Contract, dataKey?: keyof Contract): React.ReactNode => {
		return (
			<div>
				<span>{rowData.paymentAmount!.toLocaleString()}円</span>
			</div>
		);
	};

	// 描画
	const filteredRows = rows.filter(filterPredicate);
	const classes = useStyles();
	return (
		<Pane title="契約一覧" enableContent={enable}
			searchValue={searchValue} onSearchChange={setSearchValue}
			headerElement={<Link onClick={() => setIsSimpleRegisterOpen(true)}>簡易登録</Link>}
			rightElementOfSearch={<>
				<Button className={classes.button} type='submit' variant='contained'
					color='primary' onClick={addContract}>
					追加
				</Button>
				<div>件数: {filteredRows.length}</div>
			</>}>
			<VirtualizedTable
				data={filteredRows}
				columns={[
					{ label: '名前', width: 250, renderCellData: renderName },
					{ label: '回数', dataKey: 'count', width: 150 },
					{ label: '支払金額', dataKey: 'paymentAmount', width: 200, renderCellData: renderAmount },
					{ label: '支払日', dataKey: 'paymentDate', width: 250, renderCellData: renderDate },
				]}
				defaultSortColumnDataKey='paymentDate'
				defaultSortOrder='desc'
				onRowClick={onRowClick} />
			{/** 契約情報編集ダイアログ */}
			{(contractId != -1)
				&& <ContractEdit contractId={contractId} onClose={closeContractEdit} />}
			{/** 過去の顧客簡易登録ダイアログ */}
			{isSimpleRegisterOpen
				&& <SimpleRegister onClose={closeSimpleRegister} />}
		</Pane>
	);
}