import { useHistory } from 'react-router-dom';
import ScrollDialog, { ButtonInfo } from '~/component/common/ScrollDialog';

// プロパティ
type Props = {
	/** タイトル */
	title: string,
	/** メッセージ */
	message: string,
	/** ボタンタイプ default='close' */
	buttonType?: 'close' | 'back' | 'okCancel' | 'yesNo',
	/** 閉じるイベント */
	onClose: () => void,
	/** 戻るイベント */
	onBack?: () => void,
	/** 送信イベント */
	onSubmit?: () => void,
};

/** メッセージダイアログ */
export default function MessageDialog(props: Props): JSX.Element {
	// history
	const history = useHistory();

	if (props.message == "") {  // メッセージが空文字のとき
		return <></>;
	}

	// 閉じる
	const close = () => {
		if (props.onClose) {
			props.onClose();
		}
	};

	// 戻る
	const back = () => {
		if (props.onBack) {
			props.onBack();
		} else {
			history.goBack();
			props.onClose();
		}
	};

	// 送信する
	const submit = () => {
		if (props.onSubmit) {
			props.onSubmit();
		}
	};

	let buttons: ButtonInfo[];
	let isOutsideClose = true;
	if ((props.buttonType ?? 'close') == 'close') {
		buttons = [
			{ label: "閉じる", variant: 'outlined', color: 'default', onClick: close },
		];
	} else if (props.buttonType == 'back') {
		buttons = [
			{ label: "戻る", variant: 'outlined', color: 'secondary', onClick: back },
		];
		isOutsideClose = false;
	} else if (props.buttonType == 'okCancel') {
		buttons = [
			{ label: "OK", variant: 'contained', color: 'primary', onClick: submit },
			{ label: "キャンセル", variant: 'outlined', color: 'default', onClick: close },
		];
	} else {
		buttons = [
			{ label: "はい", variant: 'contained', color: 'primary', onClick: submit },
			{ label: "いいえ", variant: 'outlined', color: 'default', onClick: close },
		];
	}

	// 描画
	return (
		<ScrollDialog title={props.title} buttons={buttons} closeButton={false}
			isOutsideClose={isOutsideClose} onClose={close}>
			<span>
				{props.message.split('\n').map((msg) => <>{msg}<br /></>)}
			</span>
		</ScrollDialog>
	);
}