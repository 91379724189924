import { Button, CircularProgress, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, PropTypes } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext, useRef } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { AppContext } from '~/component/base/App';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	dialog: {
		position: 'relative',
	},
	content: {
		minWidth: '250px',
	},
	action: {
		justifyContent: 'center',
	},
	button: {
		margin: theme.spacing(0, 1, 0, 1),
	},
	closeButton: {
		position: 'absolute',
		right: 8,
		top: 8,
		color: 'gray',
	},
	indicator: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		margin: 'auto',
	},
}));

/** ボタン情報 */
export type ButtonInfo = {
	/** ラベル */
	label: string,
	/** タイプ default='outlined' */
	variant?: 'contained' | 'outlined' | 'text',
	/** 色 default='primary' */
	color?: PropTypes.Color
	/** 無効か default=false */
	disabled?: boolean,
	/** 検証するか default=false */
	validate?: boolean,
	/** クリックイベント */
	onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}

// プロパティ
type Props = {
	/** タイトル */
	title: string,
	/** ボタン情報 */
	buttons: ButtonInfo[],
	/** 右上の閉じるボタンがあるか default=true */
	closeButton?: boolean,
	/** ダイアログ外で閉じるか default=true */
	isOutsideClose?: boolean,
	/** 閉じるイベント（ダイアログ外クリック時とEsc押下時共通） */
	onClose: () => void,
	/** ウェイトインジケータを表示するか default=false */
	isWaiting?: boolean,
	/** 子要素 */
	children?: React.ReactNode,
};

/** ダイアログ */
export default function ScrollDialog(props: Props): JSX.Element {
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// 検証フォーム
	const form = useRef<ValidatorForm | null>(null);

	// 外タップまたはEscで閉じる
	const close = () => {
		if (props.isOutsideClose ?? true) {   // 外タップで閉じるとき
			props.onClose();
		}
	};

	// クリックイベント
	const handleClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
		validate?: boolean) => {
		if (validate ?? false) {    // 検証するとき
			const isValid = await form.current?.isFormValid(false);
			if (!isValid) {  // 入力エラーがあるとき
				appFuncs.setErrorMessage("入力エラーがあります。");
				return;
			}
		}
		onClick(event);
	};

	// 描画
	const classes = useStyles();
	return (
		<Dialog className={classes.dialog} open scroll='paper' onClose={close}>
			<ValidatorForm autoComplete='off' onSubmit={() => { return; }} ref={form}>
				<DialogTitle color='primary'>
					{props.title}
					{(props.closeButton ?? true)
						&& <IconButton className={classes.closeButton} onClick={props.onClose}>
							<CloseIcon />
						</IconButton>}
				</DialogTitle>
				<DialogContent className={classes.content} dividers>
					{props.children}
				</DialogContent>
				<DialogActions className={classes.action}>
					{props.buttons.map((btn) =>
						<Button className={classes.button} variant={btn.variant ?? 'outlined'}
							key={btn.label} color={btn.color ?? 'primary'} disabled={btn.disabled}
							onClick={async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
								handleClick(event, btn.onClick, btn.validate)}>
							{btn.label}
						</Button>
					)}
				</DialogActions>
			</ValidatorForm>
			{props.isWaiting && <CircularProgress className={classes.indicator} />}
		</Dialog>
	);
}