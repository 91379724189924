import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import FullCalendar, { EventClickArg, EventInput } from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Button, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AdminApi, SystemApi, Treatment } from '~/axios';
import { MEMBER_TYPE_LIST } from '~/component/admin/ScheduleReleaseSettingAdd';
import { AppContext } from '~/component/base/App';
import Pane from '~/component/common/Pane';
import VirtualizedTable, { ColumnInfo } from '~/component/common/VirtualizedTable';
import TreatmentEdit from '~/component/system/TreatmentEdit';
import { PermissionValueType } from '~/constant/PermissionValueType';
import { TreatmentStateType } from '~/constant/TreatmentStateType';
import { DateUtil } from '~/util/DateUtil';
import { ErrorUtil } from '~/util/ErrorUtil';
import { ObjectUtil } from '~/util/ObjectUtil';

// TODO: Colorクラスに定数化
const blue = '#3788d8';
const pink = '#ff3366';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	release: {
		color: 'red',
	},
	calendar: {
		marginBottom: theme.spacing(3),
		'& .fc-toolbar': {
			fontSize: '0.85rem',
		},
		'& .fc table': {
			fontSize: '0.85rem',
		},
	},
	noWrap: {
		whiteSpace: 'nowrap',
	},
	state: {
		fontWeight: 'bold',
	},
	blue: {
		color: blue,
	},
	orange: {
		color: 'orange',
	},
	pink: {
		color: pink,
	},
	gray: {
		color: 'gray',
	},
	button: {
		margin: theme.spacing(1),
	},
}));

// 施術情報編集ダイアログ情報
type TreatmentInfo = {
	/** メッセージ */
	id: number,
	/** 施術日 */
	date: string,
};

/** スケジュール画面、空き状況画面 */
export default function Schedule(): JSX.Element {
	// 行情報
	const [rows, setRows] = useState<Treatment[]>([]);
	// 有効か
	const [enable, setEnable] = useState<boolean>(false);
	// スケジュール公開設定メッセージ
	const [releaseMessage, setReleaseMessage] = useState<string[]>([]);
	// 検索値
	const [searchValue, setSearchValue] = useState<string>("");
	// 保存回数
	const [saveCount, setSaveCount] = useState<number>(0);
	// 施術情報編集ダイアログ用施術情報
	const [treatmentInfo, setTreatmentInfo] = useState<TreatmentInfo>({ id: -1, date: "" });
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// location
	const location = useLocation();
	// history
	const history = useHistory();

	// レンダリング後フック
	useEffect(() => {
		void getRecord();
	}, [saveCount]);

	const isAdminAll = appFuncs.checkPermission(PermissionValueType.AdminAll);

	// レコードを取得する
	const getRecord = async () => {
		try {
			const res = await new SystemApi().getTreatmentList('schedules');
			if (Cookies.get('phrase')) {	// ログイン権限があり、パスフレーズが設定されているとき
				history.push('/check');
			}
			if (!isAdminAll) {	// 全体管理権限がないとき
				const lastArea = res.data[0]?.customer?.lastTreatment?.area;
				// TODO: 最終施術地域ではなく専用地域にする（全ての予約も含めた施術が同じ地域の場合）。同じAPIに詰め込むのではなく、TreatmentContolerに指定した顧客の施術地域リスト取得API（/customers/:customerId/treatments/area getTreatmentAreaList）を作成する。→管理者の顧客情報画面でも使い、地域を表示する。
				if (lastArea) {	// 最終施術地域があるとき
					setSearchValue(lastArea);
				}
				ObjectUtil.sort(res.data, 'treatmentDate', 'asc');
			}
			setRows(res.data);
			if (isAdminAll) {	// 全体管理権限があるとき
				const resRelease = await new AdminApi().getScheduleReleaseCurrent();
				const msgs: string[] = [];
				for (const type of MEMBER_TYPE_LIST) {
					const release = resRelease.data.find((sr) => sr.memberType == type);
					const msg = release ? `${DateUtil.toDateString(release.scheduleEndDate!)}まで公開` : `未公開`;
					msgs.push(`${PermissionValueType.getString(type)}: ${msg}`);
				}
				setReleaseMessage(msgs);
				if (saveCount == 0) {	// 画面を開いたとき
					const params = new URLSearchParams(location.search);
					const id = params.get('id') ? Number(params.get('id')) : NaN;
					if (!isNaN(id)) {	// 施術IDが指定されているとき
						setTreatmentInfo({ id: id, date: "" });
					}
				}
			}
			setEnable(true);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err), true);
		}
	};

	// フィルター判定
	const filterPredicate = (value: Treatment, index: number, array: Treatment[]): boolean => {
		if (!searchValue) {	// 検索値がないとき
			return true;
		}
		return (value.treatmentDate && DateUtil.toDateString(value.treatmentDate).indexOf(searchValue.replace(/-/g, '/')) != -1
			|| (value.state?.indexOf(searchValue) ?? -1) != -1
			|| (value.area?.indexOf(searchValue) ?? -1) != -1
			|| (value.place?.placeName?.indexOf(searchValue) ?? -1) != -1
			|| (value.reservation?.customer?.name?.indexOf(searchValue) ?? -1) != -1
			|| (value.reservation?.customer?.ruby?.indexOf(searchValue) ?? -1) != -1
			|| (value.reservation?.customer?.nickname?.indexOf(searchValue) ?? -1) != -1);
	};

	// カレンダーの日付を押したときに呼ばれる
	const onDateClick = (arg: DateClickArg) => {
		if (isAdminAll) {	// 全体管理権限があるとき
			setTreatmentInfo({ id: 0, date: arg.dateStr });
		}
	};

	// カレンダーイベントを押したときに呼ばれる
	const onEventClick = (arg: EventClickArg) => {
		if (arg.event.id) {	// 編集ダイアログが開けるイベントのとき
			setTreatmentInfo({ id: Number(arg.event.id), date: "" });
		} else {	// 編集ダイアログが開けないイベントのとき
			arg.el.blur();
		}
	};

	// 追加する
	const addTreatment = () => {
		setTreatmentInfo({ id: 0, date: "" });
	};

	// 行を押したときに呼ばれる
	const onRowClick = (rowData: Treatment) => {
		setTreatmentInfo({ id: rowData.treatmentId!, date: "" });
	};

	// 施術情報編集ダイアログを閉じる
	const closeTreatmentEdit = (isSaved: boolean) => {
		setTreatmentInfo({ id: -1, date: "" });
		if (isSaved) {	// 保存済のとき
			setSaveCount((saveCount) => saveCount + 1);
		}
	};

	// カレンダーを描画する
	const renderCalendar = (): React.ReactNode => {
		const events: EventInput[] = filteredTreatedRows.map((t) => {
			const color = (t.state == TreatmentStateType.Empty) ? blue
				: (t.state == TreatmentStateType.ReservedTemporary) ? pink
					: 'orange';// (new Date(t.treatmentDate!).getTime() >= DateUtil.getMidnightTime(new Date())) ? 'orange' : '#ff8000';
			return {
				id: String(t.treatmentId!),
				title: t.area!,
				start: t.treatmentDate!,
				end: DateUtil.addSeconds(t.treatmentDate!, t.treatmentTime! * 60),
				color: color,
			};
		});
		return (
			<div className={classes.calendar}>
				<FullCalendar plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]} initialView='dayGridMonth'
					locale='ja' locales={allLocales}
					headerToolbar={{
						start: 'prev,next',
						center: 'title',
						end: 'dayGridMonth,timeGridWeek',
					}}
					businessHours={{
						daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
						startTime: '10:00',
						endTime: '22:30',
					}}
					views={{
						timeGridWeek: {
							allDaySlot: false,
							slotMinTime: '09:00',
							slotMaxTime: '23:00',
							titleFormat: (date) => {
								const start = date.start.month;
								const end = date.end?.month;
								if (end != null && start != end) {
									return `${start + 1}月〜${end + 1}月`;
								} else {
									return `${start + 1}月`;
								}
							},
							dayHeaderFormat: (date) => {
								const day = date.date.day;
								const weekNum = date.date.marker.getDay();
								const week = ['(日)', '(月)', '(火)', '(水)', '(木)', '(金)', '(土)'][weekNum];
								return String(day) + ' ' + week;
							},
						},
					}}
					dayCellContent={(e) => {
						e.dayNumberText = e.dayNumberText.replace('日', '');
					}}
					events={events} eventClick={onEventClick} dateClick={onDateClick} />
			</div>
		);
	};

	// 状態を描画する
	const renderState = (rowData: Treatment, dataKey?: keyof Treatment): React.ReactNode => {
		const className = clsx(classes.state, {
			[classes.blue]: (rowData.state == TreatmentStateType.Empty),
			[classes.pink]: (rowData.state == TreatmentStateType.ReservedTemporary),
			[classes.orange]: (rowData.state == TreatmentStateType.Reserved),
		});
		return (
			<div>
				<span className={className}>
					{TreatmentStateType.getString(rowData.state)}
				</span>
			</div>
		);
	};

	// 日時を描画する
	const renderDate = (rowData: Treatment, dataKey?: keyof Treatment): React.ReactNode => {
		return (
			<div>
				<span className={classes.noWrap}>{DateUtil.toDateString(rowData.treatmentDate!)}</span>
				&ensp;<span className={classes.gray}>{DateUtil.toTimeString(rowData.treatmentDate!)}</span>
			</div>
		);
	};

	// 名前を描画する
	const renderName = (rowData: Treatment, dataKey?: keyof Treatment): React.ReactNode => {
		return (
			<div>
				<span className={classes.noWrap}>{rowData.reservation?.customer?.name ?? ""}</span>
				{rowData.reservation?.customer?.nickname && <>
					&ensp;<span className={clsx(classes.noWrap, classes.gray)}>{rowData.reservation.customer.nickname}</span>
				</>}
			</div>
		);
	};

	// 場所を描画する
	const renderPlace = (rowData: Treatment, dataKey?: keyof Treatment): React.ReactNode => {
		return (
			<div>
				<span>{rowData.place?.placeName ?? "未定"}</span>
			</div>
		);
	};

	// テーブルを描画する
	const renderTable = (): React.ReactNode => {
		const columns: ColumnInfo<Treatment>[] = [
			{ label: '状態', dataKey: 'state', width: 100, renderCellData: renderState },
			{ label: '日時', dataKey: 'treatmentDate', width: 200, renderCellData: renderDate },
			{ label: '地域', dataKey: 'area', width: 150 },
		];
		if (isAdminAll) {
			columns.push({ label: '名前', width: 200, renderCellData: renderName });
		} else {
			columns.push({ label: '場所', width: 200, renderCellData: renderPlace });
		}
		return (
			<VirtualizedTable
				data={filteredTreatedRows}
				columns={columns}
				defaultSortColumnDataKey={isAdminAll ? 'treatmentDate' : 'state'}
				defaultSortOrder='asc'
				onRowClick={onRowClick} />
		);
	};

	const filteredTreatedRows = rows.filter(filterPredicate);
	const classes = useStyles();
	return (
		<Pane title={isAdminAll ? "スケジュール" : "空き状況"} enableContent={enable}
			searchValue={searchValue} onSearchChange={setSearchValue}
			headerElement={<>
				{isAdminAll && <div className={classes.release}>
					{releaseMessage.map((msg, idx) => <div key={idx}>{msg}</div>)}
				</div>}
				{renderCalendar()}
			</>}
			rightElementOfSearch={<>
				{isAdminAll && <Button className={classes.button} type='submit'
					variant='contained' color='primary' onClick={addTreatment}>
					追加
				</Button>}
				<div>件数: {filteredTreatedRows.length}</div>
			</>}>
			{renderTable()}
			{/** 施術情報編集ダイアログ */}
			{(treatmentInfo.id != -1)
				&& <TreatmentEdit treatmentId={treatmentInfo.id} treatmentDate={treatmentInfo.date}
					onClose={closeTreatmentEdit} />}
		</Pane>
	);
}