/** オブジェクトユーティリティ */
export class ObjectUtil {
	/** ソートする */
	static sort<T>(array: T[], orderBy: keyof T, order?: 'asc' | 'desc'): T[] {
		const isAsc = ((order ?? 'asc') == 'asc');
		array.sort((a: T, b: T) => {
			const aValue = a[orderBy] ?? "";
			const bValue = b[orderBy] ?? "";
			if (aValue < bValue) {
				return isAsc ? -1 : 1;
			} else if (aValue > bValue) {
				return isAsc ? 1 : -1;
			} else {
				return 0;
			}
		});
		return array;
	}
}