import { AppBar, createStyles, IconButton, makeStyles, Theme, Toolbar, useMediaQuery } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ShareIcon from '@material-ui/icons/Share';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Share from '~/component/base/Share';
import TitleImage from '~/img/title.png';

// 設定値
const topBarHeight = '56px';
const drawerWidth = '240px';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	bar: {
		marginLeft: drawerWidth,
		[theme.breakpoints.up('md')]: {
			width: `calc(100% - ${drawerWidth})`,
		},
	},
	toolbar: {
		justifyContent: 'space-between',
		minHeight: topBarHeight,
	},
	title: {
		display: 'flex',
		height: '48px',
	},
}));

type Props = {
	// ナビボタンクリックイベント
	onNavClick: () => void,
};

/** トップバー */
export default function TopBar(props: Props): JSX.Element {
	// シェアダイアログを開くか
	const [isShareOpen, setIsShareOpen] = useState(false);
	// タブレット以下か
	const isUnderTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

	// シェアボタンが押された際に呼ばれる
	const onShareClick = () => {
		setIsShareOpen(true);
	};

	// シェアダイアログを閉じる際
	const closeShare = () => {
		setIsShareOpen(false);
	};

	const classes = useStyles();
	return (
		<AppBar className={classes.bar} position='fixed'>
			<Toolbar className={classes.toolbar} variant='dense'>
				{isUnderTablet && <IconButton color='inherit' onClick={props.onNavClick}>
					<MenuIcon />
				</IconButton>}
				<Link to="/">
					<img src={TitleImage} className={classes.title} />
				</Link>
				<IconButton color='inherit' onClick={onShareClick}>
					<ShareIcon />
				</IconButton>
				{isShareOpen && <Share onClose={closeShare} />}
			</Toolbar>
		</AppBar>
	);
}