/** 施術状態タイプ */
export enum TreatmentStateType {
	/** 空き */
	Empty = '-',
	/** 予約 */
	Reserved = 'R',
	/** 予約（仮） */
	ReservedTemporary = 'T',
	/** 済み */
	Finished = 'F',
}

export namespace TreatmentStateType {
	/** 
	 * 文字列を取得する
	 * @param field フィールド 未指定=標準、'reservation'=予約系画面用、'treatment'=施術系画面用
	 */
	export function getString(state?: TreatmentStateType | string, field?: 'reservation' | 'treatment'): string {
		switch (state) {
			case TreatmentStateType.Empty:
				return "空";
			case TreatmentStateType.Reserved:
				if (field == 'reservation') {
					return "";
				}
				return "予約";
			case TreatmentStateType.ReservedTemporary:
				if (field == 'reservation') {
					return "仮";
				}
				return "予約（仮）";
			case TreatmentStateType.Finished:
				if (field == 'treatment') {
					return "";
				}
				return "施術済";
			default:
				return "";
		}
	}
}