/** 測定結果補助クラス */
export class MeasurementAuxil {
	/** 測定位置のキー一覧を取得する */
	static getPointKeys(): MeasurementPoint[] {
		return Object.keys(point) as MeasurementPoint[];
	}

	/** 指定された測定位置の文字列を取得する */
	static getPointString(key: MeasurementPoint): string {
		return point[key];
	}
}

/** 測定位置のキー名から文字列へのマップ */
const point = {
	bust: "バスト",
	underBust: "アンダーバスト",
	waist: "ウエスト",
	underAbdomen: "下腹部",
	hip: "ヒップ",
	rightFemur: "右大腿部",
	leftFemur: "左大腿部",
	rightKnee: "右膝",
	leftKnee: "左膝",
	rightCalf: "右ふくらはぎ",
	leftCalf: "左ふくらはぎ",
	rightAnkle: "右足首",
	leftAnkle: "左足首",
	rightUpperArm: "右二の腕",
	leftUpperArm: "左二の腕"
} as const;

/** 測定位置 */
export type MeasurementPoint = keyof typeof point;