import { createStyles, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import Cookies from "js-cookie";
import { createContext, useState } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { useOrientation } from 'react-use';
import BottomNav from "~/component/base/BottomNav";
import MainPane from "~/component/base/MainPane";
import SideNav from "~/component/base/SideNav";
import TopBar from "~/component/base/TopBar";
import MessageDialog from "~/component/common/MessageDialog";
import { PermissionValueType } from "~/constant/PermissionValueType";
import { PermissionUtil } from "~/util/PermissionUtil";

// スタイル
const useStyles = makeStyles(() => createStyles({
	app: {
		fontSize: '1vw',
	},
	root: {
		fontSize: '1rem',
		display: 'flex',
		overflow: 'hidden',
	},
}));

// App関数
type AppFunctions = {
	/** 権限チェック関数 */
	checkPermission: (type: PermissionValueType) => boolean,
	/** 権限状態更新関数 */
	updatePermission: () => void,
	/** 
	 * エラーメッセージ設定関数 
	 * オープン時エラーでかつログイン権限がなくなったときは権限状態を更新しエラーは表示しない。
	 * それ以外のときはエラーダイアログを閉じる際に権限状態を更新する。
	 * @param isOpenError オープン時エラーか default=false 
	 */
	setErrorMessage: (message?: string, isOpenError?: boolean) => void,
}

/** Appコンテキスト */
export const AppContext = createContext<AppFunctions | null>(null);

/** アプリケーション画面全体 */
export default function App() {
	// サイドナビを開くか（mobile）
	const [isSideNavOpen, setIsSideNavOpen] = useState<boolean>(false);
	// 権限フラグ
	const [permission, setPermission] = useState<string>(Cookies.get('permissionFlag') ?? "");
	// エラーダイアログ表示メッセージ
	const [message, setMessage] = useState<string>("");
	// orientation
	const orientation = useOrientation();
	// タブレット以下か
	const isUnderTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

	// 権限をチェックする
	const checkPermission = (type: PermissionValueType): boolean => {
		return PermissionUtil.check(permission, type);
	};

	/** 権限状態を更新する */
	const updatePermission = () => {
		setPermission(Cookies.get('permissionFlag') ?? "");
	};

	// エラーメッセージをセットする
	const setErrorMessage = (message?: string, isOpenError?: boolean) => {
		const permission = Cookies.get('permissionFlag') ?? "";
		if (!PermissionUtil.check(permission, PermissionValueType.AdminAll)) {	// 全体管理権限がないとき
			Cookies.remove('phrase');
		}
		if ((isOpenError ?? false) && !PermissionUtil.check(permission, PermissionValueType.AdminPersonal)) {	// オープン時エラーでログイン権限がないとき
			setPermission(permission);
		} else {
			setMessage(message ?? "不明なエラー");
		}
	};

	// App関数
	const appFunctions: AppFunctions = {
		checkPermission: checkPermission,
		updatePermission: updatePermission,
		setErrorMessage: setErrorMessage,
	};

	// ナビボタンを押した際に呼ばれる
	const onNavClick = () => {
		setIsSideNavOpen(true);
	};
	// サイドナビを閉じる
	const closeSideNav = () => {
		setIsSideNavOpen(false);
	};

	// エラーダイアログを閉じる
	const closeDialog = () => {
		setMessage("");
		updatePermission();
	};

	const classes = useStyles();
	return (
		<div className={classes.app}>
			<Router>
				<div className={classes.root}>
					<AppContext.Provider value={appFunctions}>
						<TopBar onNavClick={onNavClick} />
						<SideNav open={isSideNavOpen} onClose={closeSideNav} />
						<MainPane />
						{(isUnderTablet && (orientation.angle / 90) % 2 == 0) && <BottomNav />}
						<MessageDialog title="エラー" message={message} onClose={closeDialog} />
					</AppContext.Provider>
				</div>
			</Router>
		</div>
	);
}
