import { createStyles, makeStyles, Tab, Tabs, Theme, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SystemApi, Treatment } from '~/axios';
import { AppContext } from '~/component/base/App';
import Pane from '~/component/common/Pane';
import VirtualizedTable, { ColumnInfo } from '~/component/common/VirtualizedTable';
import MeasurementEdit from '~/component/system/MeasurementEdit';
import { MeasurementType } from '~/constant/MeasurementType';
import { PermissionValueType } from '~/constant/PermissionValueType';
import { MeasurementAuxil, MeasurementPoint } from '~/model/MeasurementAuxil';
import { DateUtil } from '~/util/DateUtil';
import { ErrorUtil } from '~/util/ErrorUtil';

// TODO: Colorクラスに定数化
const pink = '#ff3366';

// スタイル
const useStyles = makeStyles(() => createStyles({
	noWrap: {
		whiteSpace: 'nowrap',
	},
	pink: {
		color: pink,
	},
	gray: {
		color: 'gray',
	},
	minus: {
		color: 'blue',
	},
	plus: {
		color: 'red',
	},
}));

/** 測定結果一覧画面 */
export default function MeasurementList(): JSX.Element {
	// 行情報
	const [rows, setRows] = useState<Treatment[]>([]);
	// 有効か
	const [enable, setEnable] = useState<boolean>(false);
	// 検索値
	const [searchValue, setSearchValue] = useState<string>("");
	// タブ値
	const [tabValue, setTabValue] = useState<number>(0);
	// 保存回数
	const [saveCount, setSaveCount] = useState<number>(0);
	// 測定結果編集ダイアログ用施術ID
	const [treatmentId, setTreatmentId] = useState<number>(0);
	// モバイルか
	const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// history
	const history = useHistory();
	// location
	const location = useLocation();

	// レンダリング後フック
	useEffect(() => {
		void getRecord();
	}, [saveCount, new URLSearchParams(location.search).get('search') ?? ""]);

	// レコードを取得する
	const getRecord = async () => {
		try {
			const res = await new SystemApi().getTreatmentList('measurements');
			if (Cookies.get('phrase')) {	// ログイン権限があり、パスフレーズが設定されているとき
				history.push('/check');
			}
			const params = new URLSearchParams(location.search);
			setSearchValue(params.get('search') ?? "");
			setRows(res.data);
			setEnable(true);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err), true);
		}
	};

	// フィルター判定
	const filterPredicate = (value: Treatment, index: number, array: Treatment[]): boolean => {
		if (!searchValue) {	// 検索値がないとき
			return true;
		}
		return (value.treatmentDate && DateUtil.toDateString(value.treatmentDate).indexOf(searchValue.replace(/-/g, '/')) != -1
			|| value.customerCount?.toString().startsWith(searchValue)
			|| (value.customer?.name?.indexOf(searchValue) ?? -1) != -1
			|| (value.customer?.ruby?.indexOf(searchValue) ?? -1) != -1
			|| (value.customer?.nickname?.indexOf(searchValue) ?? -1) != -1);
	};

	// タブが変更されたに呼ばれる
	const onTabChange = (event: React.ChangeEvent<object>, value: number) => {
		setTabValue(value);
	};

	// 行を押したときに呼ばれる
	const onRowClick = (rowData: Treatment) => {
		setTreatmentId(rowData.treatmentId!);
	};

	// 測定結果編集ダイアログを閉じる
	const closeMeasurementEdit = (isSaved: boolean) => {
		setTreatmentId(0);
		if (isSaved) {	// 保存済のとき
			setSaveCount((saveCount) => saveCount + 1);
		}
	};

	// 日時を描画する
	const renderDate = (rowData: Treatment, dataKey?: keyof Treatment): React.ReactNode => {
		return (
			<div>
				<span className={classes.noWrap}>{DateUtil.toDateString(rowData.treatmentDate!)}</span>
			</div>
		);
	};

	// 名前を描画する
	const renderName = (rowData: Treatment, dataKey?: keyof Treatment): React.ReactNode => {
		return (
			<div>
				<span className={classes.noWrap}>{rowData.customer?.name ?? ""}</span>
				{rowData.customer?.nickname && <>
					&ensp;<span className={clsx(classes.noWrap, classes.gray)}>{rowData.customer.nickname}</span>
				</>}
			</div>
		);
	};

	// 合計を描画する
	const renderSum = (rowData: Treatment, dataKey?: keyof Treatment): React.ReactNode => {
		if (rowData.measurements === null) {	// 測定結果なしのとき
			return "測定なし";
		}
		const beforeMeasurement = rowData.measurements?.find((m) => m.measurementType == MeasurementType.BeforeTreatment);
		const afterMeasurement = rowData.measurements?.find((m) => m.measurementType == MeasurementType.AfterTreatment);
		if (!beforeMeasurement || !afterMeasurement) {	// 施術前後の測定結果がないとき
			return <div><span className={classes.pink}>未入力</span></div>;
		}
		let sum = 0;
		for (const point of MeasurementAuxil.getPointKeys()) {
			if (point == 'bust') {
				continue;
			}
			const diff = afterMeasurement[point]! - beforeMeasurement[point]!;
			sum += Math.round(diff * 10) / 10;
		}
		const className = clsx({ [classes.plus]: (sum > 0), [classes.minus]: (sum < 0) });
		return <div><span className={className}>{(sum > 0) ? `+${sum}` : sum}</span></div>;
	};

	// 測定値を描画する
	const renderValue = (name: MeasurementPoint, rowData: Treatment): React.ReactNode => {
		const beforeValue = rowData.measurements?.find((m) => m.measurementType == MeasurementType.BeforeTreatment)?.[name];
		const afterValue = rowData.measurements?.find((m) => m.measurementType == MeasurementType.AfterTreatment)?.[name];
		if (beforeValue == null && afterValue == null) {	// 施術前後の測定結果がないとき
			return "";
		}
		if (beforeValue == null || afterValue == null) {	// 施術前後どちらかの測定結果がないとき
			return (
				<div>
					<span>{beforeValue ?? ""}</span><br />
					<span>{afterValue ?? ""}</span><br />
					<span></span><br />
				</div>
			);
		}
		const diff = Math.round((afterValue - beforeValue) * 10) / 10;
		const className = clsx({ [classes.plus]: (diff > 0), [classes.minus]: (diff < 0) });
		return (
			<div>
				<span>{beforeValue}</span><br />
				<span>{afterValue}</span><br />
				<span className={className}>{(diff > 0) ? `+${diff}` : diff}</span>
			</div>
		);
	};

	// テーブルを描画する
	const renderTable = (): React.ReactNode => {
		const columns: ColumnInfo<Treatment>[] = [
			{ label: '施術日', dataKey: 'treatmentDate', width: 200, renderCellData: renderDate }
		];
		if (isAdminAll) {
			columns.push({ label: '名前', width: 200, renderCellData: renderName });
		}
		columns.push(
			{ label: '回数', dataKey: 'customerCount', width: 100 },
			{ label: '合計', width: 100, renderCellData: renderSum }
		);
		const pointLabels: string[] = [];
		for (const point of MeasurementAuxil.getPointKeys()) {
			const label = MeasurementAuxil.getPointString(point);
			pointLabels.push(label);
			columns.push({ label: label, width: 100, renderCellData: (rowData) => renderValue(point, rowData) });
		}
		const labels = ['回数'];
		if (tabValue == 0) {	// 全体系列表示のとき
			labels.push('施術日', '名前', '合計');
			if (!isMobile) {
				labels.push(pointLabels[0], pointLabels[2], pointLabels[4]);
			}
		} else {
			if (isMobile) {
				const len = Math.ceil(pointLabels.length / divideNum);
				labels.push(...pointLabels.slice((tabValue - 1) * len, tabValue * len));
			} else {
				labels.push('合計', ...pointLabels);
			}
		}
		return (
			<VirtualizedTable
				data={rows.filter(filterPredicate)}
				columns={columns}
				defaultSortColumnDataKey='treatmentDate'
				defaultSortOrder='desc'
				filterColumnPredicate={(column) => labels.includes(column.label)}
				canRowClick={(rowData) => isAdminAll || rowData.measurements !== null}
				onRowClick={onRowClick} />
		);
	};

	// 描画
	const isAdminAll = appFuncs.checkPermission(PermissionValueType.AdminAll);
	const divideNum = 3;
	const classes = useStyles();
	return (
		<Pane title="測定結果一覧" enableContent={enable}
			searchValue={searchValue} onSearchChange={setSearchValue}
			rightElementOfSearch={<Tabs value={tabValue} indicatorColor='primary' onChange={onTabChange}>
				<Tab label="全体" value={0} />
				{isMobile ? Array.from({ length: divideNum },
					(val, idx) => <Tab key={idx} label={idx + 1} value={idx + 1} />)
					: <Tab label="詳細" value={1} />}
			</Tabs>}>
			{renderTable()}
			{/** 測定結果編集ダイアログ */}
			{(treatmentId != 0)
				&& <MeasurementEdit treatmentId={treatmentId} onClose={closeMeasurementEdit} />}
		</Pane>
	);
}