import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

// プロパティ
type Props = {
	/** リスト名 */
	text: string,
	/** 遷移先URL */
	to: string,
	/** アイコン */
	icon: React.ReactNode,
	/** クリックイベント */
	onClick: () => void,
};

/** リストボタン */
export default function ListButton(props: Props): JSX.Element {
	return (
		<ListItem button component={Link} to={props.to} onClick={props.onClick}>
			<ListItemIcon>
				{props.icon}
			</ListItemIcon>
			<ListItemText primary={props.text} />
		</ListItem>
	);
}