import { BottomNavigation, BottomNavigationAction, createStyles, makeStyles, Paper } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import HomeIcon from '@material-ui/icons/Home';
import InputIcon from '@material-ui/icons/Input';
import PeopleIcon from '@material-ui/icons/People';
import { useContext } from "react";
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from "~/component/base/App";
import { Constant } from '~/constant/Constant';
import { PermissionValueType } from "~/constant/PermissionValueType";

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	paper: {
		display: 'block',
		width: '100%',
		position: 'fixed',
		left: 0,
		bottom: 0,
		zIndex: 1000,
		textAlign: 'center',
	},
	button: {
		maxWidth: '100%',
	},
}));

/** ボトムナビゲーション */
export default function BottomNav() {
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// location
	const location = useLocation();

	// サロンホームページを開く
	const openHomepage = () => {
		window.open(Constant.HOMEPAGE, '_blank');
	};

	const isAuthenticated = appFuncs.checkPermission(PermissionValueType.AdminPersonal);
	const isAllAdmin = appFuncs.checkPermission(PermissionValueType.AdminAll);
	const isPersonalAdmin = isAuthenticated && !isAllAdmin;
	const canReserve = appFuncs.checkPermission(PermissionValueType.ReservationNormal);

	let buttons = [];
	if (isAllAdmin) {	// 全体管理権限があるとき
		buttons = [
			{ label: "顧客リスト", icon: <PeopleIcon />, to: '/customers' },
			{ label: "スケジュール", icon: <EventIcon />, to: '/schedules' },
			{ label: "施術一覧", icon: <EventAvailableIcon />, to: '/treatments' },
		];
	} else if (isPersonalAdmin) {	// 個人管理権限があるとき
		if (canReserve) {	// 予約会員のとき
			buttons = [
				{ label: "空き状況", icon: <EventIcon />, to: '/schedules' },
				{ label: "予約リスト", icon: <EventAvailableIcon />, to: '/reservations' },
				{ label: "サロン情報", icon: <HomeIcon />, to: '/', onClick: openHomepage },
			];
		} else {
			buttons = [
				{ label: "予約リスト", icon: <EventAvailableIcon />, to: '/reservations' },
				{ label: "サロン情報", icon: <HomeIcon />, to: '/', onClick: openHomepage },
			];
		}
	} else {
		buttons = [
			{ label: "ログイン", icon: <InputIcon />, to: '/login' },
			{ label: "サロン情報", icon: <HomeIcon />, to: '/', onClick: openHomepage },
		];
	}

	// 描画
	const classes = useStyles();
	return (
		<Paper elevation={4} className={classes.paper}>
			<BottomNavigation value={location.pathname} showLabels
				children={buttons.map((button) =>
					<BottomNavigationAction key={button.label} value={button.onClick ? "" : button.to}
						label={button.label} className={classes.button} icon={button.icon}
						component={Link} to={button.to} onClick={button.onClick}
					/>)}
			/>
		</Paper>
	);
}