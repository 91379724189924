import { createStyles, makeStyles, MenuItem, Select } from '@material-ui/core';
import clone from 'clone';
import React, { ChangeEvent, useContext, useState } from "react";
import { AdminApi, ScheduleRelease } from "~/axios";
import { AppContext } from '~/component/base/App';
import InputField from '~/component/common/InputField';
import ScrollDialog from '~/component/common/ScrollDialog';
import { PermissionValueType } from '~/constant/PermissionValueType';
import { DateUtil } from '~/util/DateUtil';
import { ErrorUtil } from '~/util/ErrorUtil';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	selectArea: {
		padding: theme.spacing(2, 3, 0, 3),
	},
	select: {
		minWidth: '200px',
	},
	gray: {
		whiteSpace: 'nowrap',
		opacity: '0.75',
	},
}));

/** 会員タイプ一覧 */
export const MEMBER_TYPE_LIST = [PermissionValueType.ReservationCourse, PermissionValueType.ReservationNormal];

// ページ情報
class PageInfo {
	// 会員タイプ
	memberType = PermissionValueType.None;
	// 公開日
	releaseDate = DateUtil.toDateString(new Date(), true);
	// 公開時刻
	releaseTime = "21:00";
	// 対象期日
	scheduleEndDate = DateUtil.toDateString(DateUtil.getMidnight(new Date(), 30), true);

	/** レコード保存用ScheduleReleaseへ変換する */
	toRelease(): ScheduleRelease {
		const ret: ScheduleRelease = {};
		ret.memberType = this.memberType;
		ret.releaseDate = `${this.releaseDate}T${this.releaseTime}`;
		ret.scheduleEndDate = `${this.scheduleEndDate}T23:59:59`;
		return ret;
	}
}

// プロパティ
type Props = {
	/** 閉じるイベント */
	onClose: (isSaved: boolean) => void,
};

/** スケジュール公開設定登録ダイアログ */
export default function ScheduleReleaseSettingAdd(props: Props): JSX.Element {
	// ページ情報
	const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());
	// appFunctions
	const appFuncs = useContext(AppContext)!;

	// 追加する
	const add = async () => {
		try {
			await new AdminApi().addScheduleRelease(pageInfo.toRelease());
			props.onClose(true);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 閉じる
	const close = () => {
		props.onClose(false);
	};

	// 入力内容が変更された際に呼ばれる
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const name = event.target.name as keyof PageInfo;
		if (name == 'releaseDate' || name == 'releaseTime' || name == 'scheduleEndDate') { // 入力欄のとき
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p[name] = value;
				return p;
			});
		}
	};

	// 選択内容が変更された際に呼ばれる
	const onSelectChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
		const value = event.target.value;
		const name = event.target.name as keyof PageInfo;
		if (name == 'memberType') {	// 選択欄のとき
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p[name] = value as PermissionValueType;
				return p;
			});
		}
	};

	// 描画
	const classes = useStyles();
	return (
		<ScrollDialog title="スケジュール公開設定登録" isOutsideClose={false} onClose={close}
			buttons={[
				{ label: "保存", variant: 'contained', color: 'primary', onClick: add, validate: true },
				{ label: "キャンセル", variant: 'outlined', color: 'default', onClick: close },
			]}>
			<div className={classes.selectArea}>
				<Select className={classes.select} name='memberType' variant='outlined'
					value={pageInfo.memberType} onChange={onSelectChange}>
					<MenuItem disabled value={PermissionValueType.None}>
						<span className={classes.gray}>会員タイプ</span>
					</MenuItem>
					{MEMBER_TYPE_LIST.map((type) =>
						<MenuItem value={type}>{PermissionValueType.getString(type)}</MenuItem>
					)}
				</Select>
			</div>
			<InputField label="公開日" name='releaseDate' type='date' width='200px' required={true}
				value={pageInfo.releaseDate} onChange={onInputChange} />
			<InputField label="公開時刻" name='releaseTime' type='time' width='200px' required={true}
				value={pageInfo.releaseTime} onChange={onInputChange} />
			<InputField label="対象期日" name='scheduleEndDate' type='date' width='200px' required={true}
				value={pageInfo.scheduleEndDate} onChange={onInputChange} />
		</ScrollDialog>
	);
}
