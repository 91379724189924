import { Button, createStyles, FormLabel, makeStyles } from '@material-ui/core';
import clone from 'clone';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useRef, useState } from "react";
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';
import { usePostalJp } from 'use-postal-jp';
import { Customer, SystemApi } from "~/axios";
import { AppContext } from '~/component/base/App';
import InputField from '~/component/common/InputField';
import Pane from '~/component/common/Pane';
import RadioField from "~/component/common/RadioField";
import { InfoFlagType } from '~/constant/InfoFlagType';
import { PermissionValueType } from '~/constant/PermissionValueType';
import { ErrorUtil } from '~/util/ErrorUtil';
import { StringUtil } from "~/util/StringUtil";

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	form: {
		margin: theme.spacing(0, -2, 3, -2),
	},
	input: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-evenly',
		textAlign: 'left',
	},
	inputBox: {
		maxWidth: '100%',
		width: '350px',
	},
	inputLabel: {
		padding: theme.spacing(2, 1, 1, 3),
	},
	legend: {
		fontSize: '0.9rem',
	},
	button: {
		margin: theme.spacing(1),
	},
}));

// ページ情報
class PageInfo {
	// 名前
	name = "";
	// ふりがな
	ruby = "";
	// ニックネーム
	nickname = "";
	// 郵便番号
	postalCode = "";
	// 住所
	address = "";
	// 電話番号
	telephone = "";
	// メールアドレス
	mailAddress = "";
	// パスワード
	password = "";
	// 生年月日
	birthday = "";
	// 身長
	height = "";
	// 体重
	weight = "";
	// 情報フラグ
	infoFlag = StringUtil.getDefaultFlag(InfoFlagType);
	// 病気詳細
	illness = "";
	// 薬詳細
	medicine = "";
	// 妊娠詳細
	pregnancy = "";
	// 怪我詳細
	injury = "";
	// 一番求めるもの
	request = "";
	// 一時JSON用ID
	jsonId = "";

	/** 入力内容が変化したか */
	isChanged(): boolean {
		const init = new PageInfo();
		return (this.name != "" || this.ruby != "" || this.nickname != "" || this.postalCode != ""
			|| this.address != "" || this.telephone != "" || this.mailAddress != "" || this.password != ""
			|| this.birthday != init.birthday || this.height != "" || this.weight != ""
			|| this.infoFlag != init.infoFlag || this.illness != "" || this.medicine != ""
			|| this.pregnancy != "" || this.injury != "" || this.request != "");
	}
	/** レコード保存用Customerへ変換する */
	toCustomer(): Customer {
		const ret: Customer = {};
		ret.name = this.name;
		ret.ruby = this.ruby;
		ret.nickname = this.nickname;
		ret.postalCode = this.postalCode;
		ret.address = this.address;
		ret.birthday = this.birthday;
		ret.height = Number(this.height);
		ret.weight = Number(this.weight);
		ret.telephone = this.telephone;
		ret.mailAddress = this.mailAddress;
		ret.password = this.password;
		ret.infoFlag = this.infoFlag;
		ret.illness = this.illness;
		ret.medicine = this.medicine;
		ret.pregnancy = this.pregnancy;
		ret.injury = this.injury;
		ret.request = this.request;
		return ret;
	}
	/** 一時保存用JSONから変換する */
	static fromJson(json?: string): PageInfo {
		if (!json) {	// 一時保存用JSONがないとき
			return new PageInfo();
		}
		try {
			const obj = JSON.parse(json) as Record<string, unknown>;
			if (obj.jsonId == 'register') {	// 対象の一時保存用JSONがあるとき
				return Object.assign(new PageInfo(), obj);
			} else {
				return new PageInfo();
			}
		} catch {
			return new PageInfo();
		}
	}
	/** 一時保存用JSONへ変換する */
	toJson(): string {
		const replacer = (key: string, value: unknown) => {
			if (key == 'password') {	// パスワードのとき
				return undefined;
			}
			return value;
		};
		this.jsonId = 'register';
		return JSON.stringify(this, replacer);
	}
}

/** 新規登録画面 */
export default function Register(): JSX.Element {
	// ページ情報
	const [pageInfo, setPageInfo] = useState<PageInfo>(PageInfo.fromJson(Cookies.get('tmp')));
	// 有効か
	const [enable, setEnable] = useState<boolean>(false);
	// ラジオフィールドをバリデーションするか
	const [validate, setValidate] = useState<boolean>(false);
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// 検証フォーム
	const form = useRef<ValidatorForm | null>(null);
	// history
	const history = useHistory();
	// 郵便番号
	const [address, loading, error] = usePostalJp(pageInfo.postalCode, (pageInfo.postalCode.length >= 7));

	// レンダリング後フック
	useEffect(() => {
		if (appFuncs.checkPermission(PermissionValueType.AdminAll)) {	// 全体管理権限があるとき
			Cookies.set('phrase', 'たぬき', { expires: 0.1 });
			Cookies.set('register', '0');
			setEnable(true);
		} else {
			appFuncs.setErrorMessage("不正なリクエストです。", true);
		}
	}, []);

	// レンダリング後フック
	useEffect(() => {
		if (pageInfo.isChanged()) {	// 入力内容が変化しているとき
			Cookies.set('tmp', pageInfo.toJson());
		}
	}, [pageInfo]);

	// 送信する
	const register = async () => {
		setValidate(true);
		const isValid = await form.current?.isFormValid(false);
		if (!isValid || pageInfo.infoFlag.indexOf(StringUtil.FLAG_NONE_VALUE) != -1) {	// 入力エラーがあるとき
			appFuncs.setErrorMessage("入力エラーがあります。");
			return;
		}
		try {
			const res = await new SystemApi().createCustomer(pageInfo.toCustomer());
			Cookies.remove('tmp');
			Cookies.set('register', String(res.data.customerId!));
			history.push('/check');
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 入力内容が変更された際に呼ばれる
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const split = event.target.name.split('-');
		const name = split[0] as keyof PageInfo;
		if (name == 'name' || name == 'ruby' || name == 'nickname' || name == 'postalCode'
			|| name == 'address' || name == 'birthday' || name == 'telephone' || name == 'mailAddress'
			|| name == 'password' || name == 'height' || name == 'weight' || name == 'illness'
			|| name == 'medicine' || name == 'pregnancy' || name == 'injury' || name == 'request') {	// 入力欄のとき
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				if (name == 'mailAddress' || name == 'password') {	// 半角英数入力制限のとき
					p[name] = StringUtil.filterAscii(value);
				} else if (name == 'height' || name == 'weight') {	// 実数入力制限のとき
					p[name] = StringUtil.filterFloat(value);
				} else if (name == 'postalCode') {	// 郵便番号入力制限のとき
					p[name] = StringUtil.filterPostalCode(value);
				} else if (name == 'telephone') {	// 電話番号入力制限のとき
					p[name] = StringUtil.filterTelephone(value);
				} else {
					p[name] = value;
				}
				return p;
			});
		} else if (name == 'infoFlag') {	// ラジオボタンのとき
			const type = Number(split[1]);
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p.infoFlag = StringUtil.setFlag(p.infoFlag, type, value, InfoFlagType);
				return p;
			});
		}
	};

	// 情報フラグの値を取得する
	const getInfoFlagValue = (type: InfoFlagType): string => {
		return StringUtil.getFlag(pageInfo.infoFlag, type, InfoFlagType);
	};

	// 郵便番号から変換された住所
	const addressSearched = pageInfo.address || (address ? [
		address.prefecture,
		address.address1,
		address.address2,
		address.address3,
		address.address4
	].join("") : "");

	// TODO: 免責事項のURL追加
	// 描画
	const classes = useStyles();
	return (
		<Pane title="新規登録" enableContent={enable}>
			<ValidatorForm className={classes.form} instantValidate={false} autoComplete='off'
				onSubmit={() => { return; }} ref={form}>
				<div className={classes.input}>
					<div className={classes.inputBox}>
						<InputField label="名前" name='name' width='200px' required={true}
							value={pageInfo.name} onChange={onInputChange} maxLength={50} />
						<InputField label="ふりがな(平仮名)" name='ruby' width='200px' required={true}
							value={pageInfo.ruby} onChange={onInputChange} maxLength={50} />
						<InputField label="ニックネーム" name='nickname' width='200px' placeholder="未入力可"
							value={pageInfo.nickname} onChange={onInputChange} maxLength={50} />
						<InputField label="郵便番号" name='postalCode' width='100px' placeholder="未入力可"
							value={pageInfo.postalCode} onChange={onInputChange} maxLength={8} />
						<InputField label="住所" name='address' width='300px' required={true}
							value={addressSearched} onChange={onInputChange} maxLength={200} />
						<InputField label="誕生日" name='birthday' type='date' width='200px' required={true}
							value={pageInfo.birthday} onChange={onInputChange} />
						<InputField label="身長" name='height' width='100px'
							value={pageInfo.height} endAdornment="cm" onChange={onInputChange} maxLength={5} />
						<InputField label="体重" name='weight' width='100px'
							value={pageInfo.weight} endAdornment="kg" onChange={onInputChange} maxLength={4} />
						<InputField label="電話番号" name='telephone' width='200px' placeholder="未入力可"
							value={pageInfo.telephone} onChange={onInputChange} maxLength={20} />
						<InputField label="メールアドレス" name='mailAddress' width='300px' required={true} isEmail={true}
							value={pageInfo.mailAddress} onChange={onInputChange} maxLength={200} />
						<InputField label="パスワード" name='password' type='password' width='200px' required={true}
							value={pageInfo.password} onChange={onInputChange} minLength={8} maxLength={50} />
					</div>
					<div className={classes.inputBox}>
						<RadioField label="今までにかかった病気はありますか？"
							name={`infoFlag-${InfoFlagType.IsIll}`}
							value={getInfoFlagValue(InfoFlagType.IsIll)}
							radioButtons={[
								{
									label: "はい", value: 'I', input: {
										name: 'illness', value: pageInfo.illness, required: true,
										maxLength: 200, placeholder: "心臓に関する病気 etc."
									}
								},
								{ label: "いいえ", value: 'n' },
							]}
							validate={validate} onChange={onInputChange} />
						<RadioField label="現在服用している薬はありますか？"
							name={`infoFlag-${InfoFlagType.IsTakingMedicine}`}
							value={getInfoFlagValue(InfoFlagType.IsTakingMedicine)}
							radioButtons={[
								{
									label: "はい", value: 'M', input: {
										name: 'medicine', value: pageInfo.medicine, required: true, maxLength: 200
									}
								},
								{ label: "いいえ", value: 'n' },
							]}
							validate={validate} onChange={onInputChange} />
						<RadioField label="妊娠されていますか？"
							name={`infoFlag-${InfoFlagType.IsPregnant}`}
							value={getInfoFlagValue(InfoFlagType.IsPregnant)}
							radioButtons={[
								{
									label: "はい", value: 'P', input: {
										name: 'pregnancy', value: pageInfo.pregnancy, required: true,
										maxLength: 200, placeholder: "妊娠〇週目"
									}
								},
								{ label: "いいえ", value: 'n' },
							]}
							validate={validate} onChange={onInputChange} />
						<RadioField label="触れられたくない部位や、怪我をしている部位はありますか？"
							name={`infoFlag-${InfoFlagType.IsInjured}`}
							value={getInfoFlagValue(InfoFlagType.IsInjured)}
							radioButtons={[
								{
									label: "はい", value: 'I', input: {
										name: 'injury', value: pageInfo.injury, required: true, maxLength: 200
									}
								},
								{ label: "いいえ", value: 'n' },
							]}
							validate={validate} onChange={onInputChange} />
						<RadioField label="「別人級美人エステ」について知っていますか？"
							name={`infoFlag-${InfoFlagType.IsKnowingEsthetic}`}
							value={getInfoFlagValue(InfoFlagType.IsKnowingEsthetic)}
							radioButtons={[
								{ label: "はい", value: 'K' },
								{ label: "いいえ", value: 'n' },
							]}
							validate={validate} onChange={onInputChange} />
						<div className={classes.inputLabel}>
							<FormLabel className={classes.legend} component="legend">このエステで一番求めるものは何ですか？</FormLabel>
							<InputField variant='standard' name='request' width='300px' padding='0'
								required={true} value={pageInfo.request} onChange={onInputChange} maxLength={200} />
						</div>
						<RadioField label="ご自身の希望のプランをお答えください"
							name={`infoFlag-${InfoFlagType.Plan}`}
							value={getInfoFlagValue(InfoFlagType.Plan)}
							radioButtons={[
								{ label: "ゆるっと6ヵ月プラン", value: 'L' },
								{ label: "ぼちぼち3ヵ月プラン", value: 'M' },
								{ label: "しっかり1ヵ月プラン", value: 'H' },
							]}
							validate={validate} onChange={onInputChange} />
						<RadioField label="ご自宅でのケアを考えていますか？"
							name={`infoFlag-${InfoFlagType.IsCaring}`}
							value={getInfoFlagValue(InfoFlagType.IsCaring)}
							radioButtons={[
								{ label: "はい", value: 'C' },
								{ label: "いいえ", value: 'n' },
							]}
							validate={validate} onChange={onInputChange} />
					</div>
				</div>
				<Button className={classes.button} variant='contained' color='primary' onClick={register}>
					新規登録
				</Button>
			</ValidatorForm>
		</Pane>
	);
}
