/* tslint:disable */
/* eslint-disable */
/**
 * Gloria API
 * This is the api of salon de gloria.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: toyou.themostbeautifulever@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 契約情報
 * @export
 * @interface Contract
 */
export interface Contract {
    /**
     * 契約ID
     * @type {number}
     * @memberof Contract
     */
    contractId?: number;
    /**
     * 顧客ID
     * @type {number}
     * @memberof Contract
     */
    customerId?: number;
    /**
     * 回数
     * @type {number}
     * @memberof Contract
     */
    count?: number;
    /**
     * キャンセル回数
     * @type {number}
     * @memberof Contract
     */
    cancelCount?: number;
    /**
     * 支払い金額
     * @type {number}
     * @memberof Contract
     */
    paymentAmount?: number;
    /**
     * 支払い日時
     * @type {string}
     * @memberof Contract
     */
    paymentDate?: string | null;
    /**
     * 
     * @type {Customer}
     * @memberof Contract
     */
    customer?: Customer;
    /**
     * キャンセル対象の予約ID（API用）
     * @type {number}
     * @memberof Contract
     */
    cancelReservationId?: number;
    /**
     * 更新日時
     * @type {string}
     * @memberof Contract
     */
    updateDate?: string;
}
/**
 * 顧客情報
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 顧客ID
     * @type {number}
     * @memberof Customer
     */
    customerId?: number;
    /**
     * メールアドレス
     * @type {string}
     * @memberof Customer
     */
    mailAddress?: string;
    /**
     * パスワード
     * @type {string}
     * @memberof Customer
     */
    password?: string;
    /**
     * 名前
     * @type {string}
     * @memberof Customer
     */
    name?: string;
    /**
     * ふりがな
     * @type {string}
     * @memberof Customer
     */
    ruby?: string;
    /**
     * ニックネーム
     * @type {string}
     * @memberof Customer
     */
    nickname?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof Customer
     */
    postalCode?: string;
    /**
     * 住所
     * @type {string}
     * @memberof Customer
     */
    address?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof Customer
     */
    telephone?: string;
    /**
     * 誕生日
     * @type {string}
     * @memberof Customer
     */
    birthday?: string;
    /**
     * 身長
     * @type {number}
     * @memberof Customer
     */
    height?: number;
    /**
     * 体重
     * @type {number}
     * @memberof Customer
     */
    weight?: number;
    /**
     * パスワードリセットキー
     * @type {string}
     * @memberof Customer
     */
    passwordResetKey?: string;
    /**
     * パスワードリセット有効期限
     * @type {string}
     * @memberof Customer
     */
    passwordResetExpireDate?: string | null;
    /**
     * 権限フラグ
     * @type {string}
     * @memberof Customer
     */
    permissionFlag?: string;
    /**
     * 情報フラグ
     * @type {string}
     * @memberof Customer
     */
    infoFlag?: string;
    /**
     * 病気情報
     * @type {string}
     * @memberof Customer
     */
    illness?: string;
    /**
     * 服薬情報
     * @type {string}
     * @memberof Customer
     */
    medicine?: string;
    /**
     * 妊娠情報
     * @type {string}
     * @memberof Customer
     */
    pregnancy?: string;
    /**
     * 怪我情報
     * @type {string}
     * @memberof Customer
     */
    injury?: string;
    /**
     * 求めるもの
     * @type {string}
     * @memberof Customer
     */
    request?: string;
    /**
     * 備考
     * @type {string}
     * @memberof Customer
     */
    remark?: string;
    /**
     * 作成日時
     * @type {string}
     * @memberof Customer
     */
    createDate?: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof Customer
     */
    updateDate?: string;
    /**
     * 現在のパスワード（API用）
     * @type {string}
     * @memberof Customer
     */
    currentPassword?: string;
    /**
     * 
     * @type {Treatment}
     * @memberof Customer
     */
    nextTreatment?: Treatment;
    /**
     * 
     * @type {Treatment}
     * @memberof Customer
     */
    lastTreatment?: Treatment;
    /**
     * 次回予約の施術日時（リスト用）
     * @type {string}
     * @memberof Customer
     */
    nextTreatmentDate?: string;
    /**
     * 最終施術日時（リスト用）
     * @type {string}
     * @memberof Customer
     */
    lastTreatmentDate?: string;
}
/**
 * エラー
 * @export
 * @interface ErrorInfo
 */
export interface ErrorInfo {
    /**
     * エラーメッセージ
     * @type {string}
     * @memberof ErrorInfo
     */
    error?: string;
}
/**
 * 初期パスワード
 * @export
 * @interface InitialPassword
 */
export interface InitialPassword {
    /**
     * 顧客ID
     * @type {number}
     * @memberof InitialPassword
     */
    customerId?: number;
    /**
     * パスワード
     * @type {string}
     * @memberof InitialPassword
     */
    password?: string;
    /**
     * 有効期限
     * @type {string}
     * @memberof InitialPassword
     */
    expireDate?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 顧客ID
     * @type {number}
     * @memberof InlineResponse200
     */
    customerId?: number;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 場所ID
     * @type {number}
     * @memberof InlineResponse2001
     */
    placeId?: number;
}
/**
 * ログイン情報
 * @export
 * @interface LoginInfo
 */
export interface LoginInfo {
    /**
     * メールアドレス
     * @type {string}
     * @memberof LoginInfo
     */
    mailAddress?: string;
    /**
     * パスワード
     * @type {string}
     * @memberof LoginInfo
     */
    password?: string;
}
/**
 * 測定結果
 * @export
 * @interface Measurement
 */
export interface Measurement {
    /**
     * 測定ID
     * @type {number}
     * @memberof Measurement
     */
    measurementId?: number;
    /**
     * 施術ID
     * @type {number}
     * @memberof Measurement
     */
    treatmentId?: number;
    /**
     * 測定タイプ
     * @type {string}
     * @memberof Measurement
     */
    measurementType?: string;
    /**
     * バスト
     * @type {number}
     * @memberof Measurement
     */
    bust?: number;
    /**
     * アンダーバスト
     * @type {number}
     * @memberof Measurement
     */
    underBust?: number;
    /**
     * ウエスト
     * @type {number}
     * @memberof Measurement
     */
    waist?: number;
    /**
     * 下腹部
     * @type {number}
     * @memberof Measurement
     */
    underAbdomen?: number;
    /**
     * ヒップ
     * @type {number}
     * @memberof Measurement
     */
    hip?: number;
    /**
     * 左大腿部
     * @type {number}
     * @memberof Measurement
     */
    leftFemur?: number;
    /**
     * 右大腿部
     * @type {number}
     * @memberof Measurement
     */
    rightFemur?: number;
    /**
     * 左膝
     * @type {number}
     * @memberof Measurement
     */
    leftKnee?: number;
    /**
     * 右膝
     * @type {number}
     * @memberof Measurement
     */
    rightKnee?: number;
    /**
     * 左ふくらはぎ
     * @type {number}
     * @memberof Measurement
     */
    leftCalf?: number;
    /**
     * 右ふくらはぎ
     * @type {number}
     * @memberof Measurement
     */
    rightCalf?: number;
    /**
     * 左足首
     * @type {number}
     * @memberof Measurement
     */
    leftAnkle?: number;
    /**
     * 右足首
     * @type {number}
     * @memberof Measurement
     */
    rightAnkle?: number;
    /**
     * 左二の腕
     * @type {number}
     * @memberof Measurement
     */
    leftUpperArm?: number;
    /**
     * 右二の腕
     * @type {number}
     * @memberof Measurement
     */
    rightUpperArm?: number;
    /**
     * 更新日時
     * @type {string}
     * @memberof Measurement
     */
    updateDate?: string;
}
/**
 * 場所情報
 * @export
 * @interface Place
 */
export interface Place {
    /**
     * 場所ID
     * @type {number}
     * @memberof Place
     */
    placeId?: number;
    /**
     * 場所名
     * @type {string}
     * @memberof Place
     */
    placeName?: string;
    /**
     * 地域
     * @type {string}
     * @memberof Place
     */
    area?: string;
    /**
     * 地図URL
     * @type {string}
     * @memberof Place
     */
    mapUrl?: string;
    /**
     * 詳細
     * @type {string}
     * @memberof Place
     */
    detail?: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof Place
     */
    updateDate?: string;
}
/**
 * パスワードリセット要求情報
 * @export
 * @interface RequestResetPasswordInfo
 */
export interface RequestResetPasswordInfo {
    /**
     * メールアドレス
     * @type {string}
     * @memberof RequestResetPasswordInfo
     */
    mailAddress?: string;
    /**
     * reCAPTCHAトークン
     * @type {string}
     * @memberof RequestResetPasswordInfo
     */
    recaptchaToken?: string;
}
/**
 * 予約履歴
 * @export
 * @interface Reservation
 */
export interface Reservation {
    /**
     * 予約ID
     * @type {number}
     * @memberof Reservation
     */
    reservationId?: number;
    /**
     * 顧客ID
     * @type {number}
     * @memberof Reservation
     */
    customerId?: number;
    /**
     * 
     * @type {Customer}
     * @memberof Reservation
     */
    customer?: Customer;
    /**
     * キャンセル対象の予約ID（API用）
     * @type {number}
     * @memberof Reservation
     */
    cancelReservationId?: number;
}
/**
 * スケジュール公開設定
 * @export
 * @interface ScheduleRelease
 */
export interface ScheduleRelease {
    /**
     * 公開設定ID
     * @type {number}
     * @memberof ScheduleRelease
     */
    releaseId?: number;
    /**
     * 会員タイプ
     * @type {string}
     * @memberof ScheduleRelease
     */
    memberType?: string;
    /**
     * 公開日時
     * @type {string}
     * @memberof ScheduleRelease
     */
    releaseDate?: string;
    /**
     * スケジュール最終日時
     * @type {string}
     * @memberof ScheduleRelease
     */
    scheduleEndDate?: string;
    /**
     * 作成日時
     * @type {string}
     * @memberof ScheduleRelease
     */
    createDate?: string;
}
/**
 * 施術情報
 * @export
 * @interface Treatment
 */
export interface Treatment {
    /**
     * 施術ID
     * @type {number}
     * @memberof Treatment
     */
    treatmentId?: number;
    /**
     * 施術日時
     * @type {string}
     * @memberof Treatment
     */
    treatmentDate?: string;
    /**
     * 施術時間（単位：分）
     * @type {number}
     * @memberof Treatment
     */
    treatmentTime?: number;
    /**
     * 地域
     * @type {string}
     * @memberof Treatment
     */
    area?: string;
    /**
     * 場所ID
     * @type {number}
     * @memberof Treatment
     */
    placeId?: number;
    /**
     * 予約ID
     * @type {number}
     * @memberof Treatment
     */
    reservationId?: number;
    /**
     * 顧客ID
     * @type {number}
     * @memberof Treatment
     */
    customerId?: number;
    /**
     * 顧客の施術回数
     * @type {number}
     * @memberof Treatment
     */
    customerCount?: number;
    /**
     * お茶
     * @type {string}
     * @memberof Treatment
     */
    tea?: string;
    /**
     * アロマ
     * @type {string}
     * @memberof Treatment
     */
    aroma?: string;
    /**
     * 更新日時
     * @type {string}
     * @memberof Treatment
     */
    updateDate?: string;
    /**
     * 
     * @type {Place}
     * @memberof Treatment
     */
    place?: Place;
    /**
     * 
     * @type {Reservation}
     * @memberof Treatment
     */
    reservation?: Reservation;
    /**
     * 
     * @type {Customer}
     * @memberof Treatment
     */
    customer?: Customer;
    /**
     * 測定結果（API用）
     * @type {Array<Measurement>}
     * @memberof Treatment
     */
    measurements?: Array<Measurement> | null;
    /**
     * 測定結果数（API用）
     * @type {number}
     * @memberof Treatment
     */
    measurementCount?: number | null;
    /**
     * 金額（API用）
     * @type {number}
     * @memberof Treatment
     */
    amount?: number;
    /**
     * 支払い済か（API用）
     * @type {boolean}
     * @memberof Treatment
     */
    isPaid?: boolean;
    /**
     * 状態（リスト用）
     * @type {string}
     * @memberof Treatment
     */
    state?: string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 契約情報追加。契約情報編集ダイアログ用。管理者用。
         * @summary 契約情報追加
         * @param {Contract} contract 契約情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContract: async (contract: Contract, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contract' is not null or undefined
            assertParamExists('addContract', 'contract', contract)
            const localVarPath = `/api/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スケジュール公開設定登録。スケジュール公開設定登録ダイアログ用。管理者用。
         * @summary スケジュール公開設定登録
         * @param {ScheduleRelease} scheduleRelease スケジュール公開設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addScheduleRelease: async (scheduleRelease: ScheduleRelease, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleRelease' is not null or undefined
            assertParamExists('addScheduleRelease', 'scheduleRelease', scheduleRelease)
            const localVarPath = `/api/releases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduleRelease, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 契約情報取得。契約情報編集ダイアログ用。管理者用。
         * @summary 契約情報取得
         * @param {number} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract: async (contractId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('getContract', 'contractId', contractId)
            const localVarPath = `/api/contracts/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 契約情報一覧取得。契約一覧画面用。管理者用。
         * @summary 契約情報一覧取得
         * @param {string} field フィールド \&#39;list\&#39;&#x3D;契約一覧画面用 \&#39;customer\&#39;&#x3D;\&#39;顧客情報画面用\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractList: async (field: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'field' is not null or undefined
            assertParamExists('getContractList', 'field', field)
            const localVarPath = `/api/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 現在のスケジュール公開設定取得。スケジュール画面用。管理者用。
         * @summary 現在のスケジュール公開設定取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleReleaseCurrent: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/releases/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スケジュール公開設定一覧取得。スケジュール公開設定一覧画面用。管理者用。
         * @summary スケジュール公開設定一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleReleaseList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/releases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 契約情報編集。契約情報編集ダイアログ用。管理者用。
         * @summary 契約情報編集
         * @param {number} contractId 
         * @param {Contract} contract 契約情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveContract: async (contractId: number, contract: Contract, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('saveContract', 'contractId', contractId)
            // verify required parameter 'contract' is not null or undefined
            assertParamExists('saveContract', 'contract', contract)
            const localVarPath = `/api/contracts/{contractId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 契約情報追加。契約情報編集ダイアログ用。管理者用。
         * @summary 契約情報追加
         * @param {Contract} contract 契約情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addContract(contract: Contract, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addContract(contract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スケジュール公開設定登録。スケジュール公開設定登録ダイアログ用。管理者用。
         * @summary スケジュール公開設定登録
         * @param {ScheduleRelease} scheduleRelease スケジュール公開設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addScheduleRelease(scheduleRelease: ScheduleRelease, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addScheduleRelease(scheduleRelease, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 契約情報取得。契約情報編集ダイアログ用。管理者用。
         * @summary 契約情報取得
         * @param {number} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContract(contractId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContract(contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 契約情報一覧取得。契約一覧画面用。管理者用。
         * @summary 契約情報一覧取得
         * @param {string} field フィールド \&#39;list\&#39;&#x3D;契約一覧画面用 \&#39;customer\&#39;&#x3D;\&#39;顧客情報画面用\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractList(field: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contract>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContractList(field, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 現在のスケジュール公開設定取得。スケジュール画面用。管理者用。
         * @summary 現在のスケジュール公開設定取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduleReleaseCurrent(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduleRelease>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduleReleaseCurrent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スケジュール公開設定一覧取得。スケジュール公開設定一覧画面用。管理者用。
         * @summary スケジュール公開設定一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduleReleaseList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduleRelease>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduleReleaseList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 契約情報編集。契約情報編集ダイアログ用。管理者用。
         * @summary 契約情報編集
         * @param {number} contractId 
         * @param {Contract} contract 契約情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveContract(contractId: number, contract: Contract, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveContract(contractId, contract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 契約情報追加。契約情報編集ダイアログ用。管理者用。
         * @summary 契約情報追加
         * @param {Contract} contract 契約情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContract(contract: Contract, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.addContract(contract, options).then((request) => request(axios, basePath));
        },
        /**
         * スケジュール公開設定登録。スケジュール公開設定登録ダイアログ用。管理者用。
         * @summary スケジュール公開設定登録
         * @param {ScheduleRelease} scheduleRelease スケジュール公開設定
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addScheduleRelease(scheduleRelease: ScheduleRelease, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.addScheduleRelease(scheduleRelease, options).then((request) => request(axios, basePath));
        },
        /**
         * 契約情報取得。契約情報編集ダイアログ用。管理者用。
         * @summary 契約情報取得
         * @param {number} contractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContract(contractId: number, options?: any): AxiosPromise<Contract> {
            return localVarFp.getContract(contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * 契約情報一覧取得。契約一覧画面用。管理者用。
         * @summary 契約情報一覧取得
         * @param {string} field フィールド \&#39;list\&#39;&#x3D;契約一覧画面用 \&#39;customer\&#39;&#x3D;\&#39;顧客情報画面用\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractList(field: string, options?: any): AxiosPromise<Array<Contract>> {
            return localVarFp.getContractList(field, options).then((request) => request(axios, basePath));
        },
        /**
         * 現在のスケジュール公開設定取得。スケジュール画面用。管理者用。
         * @summary 現在のスケジュール公開設定取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleReleaseCurrent(options?: any): AxiosPromise<Array<ScheduleRelease>> {
            return localVarFp.getScheduleReleaseCurrent(options).then((request) => request(axios, basePath));
        },
        /**
         * スケジュール公開設定一覧取得。スケジュール公開設定一覧画面用。管理者用。
         * @summary スケジュール公開設定一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleReleaseList(options?: any): AxiosPromise<Array<ScheduleRelease>> {
            return localVarFp.getScheduleReleaseList(options).then((request) => request(axios, basePath));
        },
        /**
         * 契約情報編集。契約情報編集ダイアログ用。管理者用。
         * @summary 契約情報編集
         * @param {number} contractId 
         * @param {Contract} contract 契約情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveContract(contractId: number, contract: Contract, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.saveContract(contractId, contract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 契約情報追加。契約情報編集ダイアログ用。管理者用。
     * @summary 契約情報追加
     * @param {Contract} contract 契約情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public addContract(contract: Contract, options?: any) {
        return AdminApiFp(this.configuration).addContract(contract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * スケジュール公開設定登録。スケジュール公開設定登録ダイアログ用。管理者用。
     * @summary スケジュール公開設定登録
     * @param {ScheduleRelease} scheduleRelease スケジュール公開設定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public addScheduleRelease(scheduleRelease: ScheduleRelease, options?: any) {
        return AdminApiFp(this.configuration).addScheduleRelease(scheduleRelease, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 契約情報取得。契約情報編集ダイアログ用。管理者用。
     * @summary 契約情報取得
     * @param {number} contractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getContract(contractId: number, options?: any) {
        return AdminApiFp(this.configuration).getContract(contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 契約情報一覧取得。契約一覧画面用。管理者用。
     * @summary 契約情報一覧取得
     * @param {string} field フィールド \&#39;list\&#39;&#x3D;契約一覧画面用 \&#39;customer\&#39;&#x3D;\&#39;顧客情報画面用\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getContractList(field: string, options?: any) {
        return AdminApiFp(this.configuration).getContractList(field, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 現在のスケジュール公開設定取得。スケジュール画面用。管理者用。
     * @summary 現在のスケジュール公開設定取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getScheduleReleaseCurrent(options?: any) {
        return AdminApiFp(this.configuration).getScheduleReleaseCurrent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * スケジュール公開設定一覧取得。スケジュール公開設定一覧画面用。管理者用。
     * @summary スケジュール公開設定一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getScheduleReleaseList(options?: any) {
        return AdminApiFp(this.configuration).getScheduleReleaseList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 契約情報編集。契約情報編集ダイアログ用。管理者用。
     * @summary 契約情報編集
     * @param {number} contractId 
     * @param {Contract} contract 契約情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public saveContract(contractId: number, contract: Contract, options?: any) {
        return AdminApiFp(this.configuration).saveContract(contractId, contract, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BaseApi - axios parameter creator
 * @export
 */
export const BaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ログイン状態の保持
         * @summary ログイン状態の保持
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keepLoggedIn: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keepLoggedIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログイン
         * @summary ログイン
         * @param {LoginInfo} loginInfo ログイン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginInfo: LoginInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginInfo' is not null or undefined
            assertParamExists('login', 'loginInfo', loginInfo)
            const localVarPath = `/api/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログアウト
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseApi - functional programming interface
 * @export
 */
export const BaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseApiAxiosParamCreator(configuration)
    return {
        /**
         * ログイン状態の保持
         * @summary ログイン状態の保持
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keepLoggedIn(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.keepLoggedIn(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログイン
         * @summary ログイン
         * @param {LoginInfo} loginInfo ログイン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginInfo: LoginInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログアウト
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseApi - factory interface
 * @export
 */
export const BaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseApiFp(configuration)
    return {
        /**
         * ログイン状態の保持
         * @summary ログイン状態の保持
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keepLoggedIn(options?: any): AxiosPromise<Array<any>> {
            return localVarFp.keepLoggedIn(options).then((request) => request(axios, basePath));
        },
        /**
         * ログイン
         * @summary ログイン
         * @param {LoginInfo} loginInfo ログイン情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginInfo: LoginInfo, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.login(loginInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * ログアウト
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<Array<any>> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseApi - object-oriented interface
 * @export
 * @class BaseApi
 * @extends {BaseAPI}
 */
export class BaseApi extends BaseAPI {
    /**
     * ログイン状態の保持
     * @summary ログイン状態の保持
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseApi
     */
    public keepLoggedIn(options?: any) {
        return BaseApiFp(this.configuration).keepLoggedIn(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログイン
     * @summary ログイン
     * @param {LoginInfo} loginInfo ログイン情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseApi
     */
    public login(loginInfo: LoginInfo, options?: any) {
        return BaseApiFp(this.configuration).login(loginInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログアウト
     * @summary ログアウト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseApi
     */
    public logout(options?: any) {
        return BaseApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 測定結果追加。測定結果ダイアログ用。管理者用。
         * @summary 測定結果追加
         * @param {Measurement} measurement 測定結果
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMeasurement: async (measurement: Measurement, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurement' is not null or undefined
            assertParamExists('addMeasurement', 'measurement', measurement)
            const localVarPath = `/api/measurements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(measurement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 場所情報追加。場所情報編集ダイアログ用。管理者用。
         * @summary 場所情報追加
         * @param {Place} place 場所情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlace: async (place: Place, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'place' is not null or undefined
            assertParamExists('addPlace', 'place', place)
            const localVarPath = `/api/places`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(place, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 施術情報追加。スケジュール画面用。管理者用。
         * @summary 施術情報追加
         * @param {Treatment} treatment 施術情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTreatment: async (treatment: Treatment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'treatment' is not null or undefined
            assertParamExists('addTreatment', 'treatment', treatment)
            const localVarPath = `/api/treatments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(treatment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 予約、予約者変更。施術情報編集ダイアログ用。
         * @summary 予約、予約者変更
         * @param {number} treatmentId 
         * @param {boolean} [withCancel] 
         * @param {Reservation} [reservation] 予約情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTreatmentReservationId: async (treatmentId: number, withCancel?: boolean, reservation?: Reservation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'treatmentId' is not null or undefined
            assertParamExists('addTreatmentReservationId', 'treatmentId', treatmentId)
            const localVarPath = `/api/treatments/{treatmentId}/reservationId`
                .replace(`{${"treatmentId"}}`, encodeURIComponent(String(treatmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (withCancel !== undefined) {
                localVarQueryParameter['withCancel'] = withCancel;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reservation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 顧客情報新規作成。新規登録画面用。管理者用。
         * @summary 顧客情報新規作成
         * @param {Customer} customer 顧客情報
         * @param {boolean} [isPast] 過去の顧客の簡易登録か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer: async (customer: Customer, isPast?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('createCustomer', 'customer', customer)
            const localVarPath = `/api/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isPast !== undefined) {
                localVarQueryParameter['isPast'] = isPast;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 初期パスワード発行。顧客情報編集画面用。
         * @summary 初期パスワード発行
         * @param {InitialPassword} initialPassword 初期パスワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInitialPassword: async (initialPassword: InitialPassword, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'initialPassword' is not null or undefined
            assertParamExists('createInitialPassword', 'initialPassword', initialPassword)
            const localVarPath = `/api/initialPasswords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initialPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 施術情報削除。施術情報編集ダイアログ用。管理者用。
         * @summary 施術情報削除
         * @param {number} treatmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTreatment: async (treatmentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'treatmentId' is not null or undefined
            assertParamExists('deleteTreatment', 'treatmentId', treatmentId)
            const localVarPath = `/api/treatments/{treatmentId}`
                .replace(`{${"treatmentId"}}`, encodeURIComponent(String(treatmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 予約キャンセル。施術情報編集ダイアログ用。管理者用。
         * @summary 予約キャンセル
         * @param {number} treatmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTreatmentReservationId: async (treatmentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'treatmentId' is not null or undefined
            assertParamExists('deleteTreatmentReservationId', 'treatmentId', treatmentId)
            const localVarPath = `/api/treatments/{treatmentId}/reservationId`
                .replace(`{${"treatmentId"}}`, encodeURIComponent(String(treatmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 顧客情報取得。顧客閲覧・編集画面用。
         * @summary 顧客情報取得
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer: async (customerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomer', 'customerId', customerId)
            const localVarPath = `/api/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 顧客情報一覧取得。顧客一覧画面、顧客選択ダイアログ用。管理者用。
         * @summary 顧客情報一覧取得
         * @param {string} field フィールド \&#39;list\&#39;&#x3D;\&#39;顧客一覧画面用\&#39; \&#39;select\&#39;&#x3D;\&#39;顧客選択ダイアログ用\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerList: async (field: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'field' is not null or undefined
            assertParamExists('getCustomerList', 'field', field)
            const localVarPath = `/api/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自身の顧客情報取得。個人情報画面用。
         * @summary 自身の顧客情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonal: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/personal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 場所情報一覧取得。施術情報編集ダイアログ、場所情報編集ダイアログ用。管理者用。
         * @summary 場所情報一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaceList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/places`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 施術情報取得。施術情報編集ダイアログ、測定結果編集ダイアログ用。
         * @summary 施術情報取得
         * @param {number} treatmentId 
         * @param {string} field フィールド \&#39;treatment\&#39;&#x3D;施術情報編集ダイアログ用 \&#39;measurement\&#39;&#x3D;\&#39;測定結果編集ダイアログ用\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTreatment: async (treatmentId: number, field: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'treatmentId' is not null or undefined
            assertParamExists('getTreatment', 'treatmentId', treatmentId)
            // verify required parameter 'field' is not null or undefined
            assertParamExists('getTreatment', 'field', field)
            const localVarPath = `/api/treatments/{treatmentId}`
                .replace(`{${"treatmentId"}}`, encodeURIComponent(String(treatmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 施術情報一覧取得。施術一覧画面、予約一覧画面、スケジュール画面用。
         * @summary 施術情報一覧取得
         * @param {string} field フィールド \&#39;treatments\&#39;&#x3D;施術一覧画面用 \&#39;schedules\&#39;&#x3D;\&#39;スケジュール画面用\&#39; \&#39;measurements\&#39;&#x3D;\&#39;測定結果画面用\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTreatmentList: async (field: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'field' is not null or undefined
            assertParamExists('getTreatmentList', 'field', field)
            const localVarPath = `/api/treatments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (field !== undefined) {
                localVarQueryParameter['field'] = field;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * パスワードリセット要求。パスワードリセット要求ダイアログ用。
         * @summary パスワードリセット要求
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPassword: async (customer: Customer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('requestResetPassword', 'customer', customer)
            const localVarPath = `/api/requestResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 顧客情報編集。顧客閲覧・編集画面用。
         * @summary 顧客情報編集
         * @param {number} customerId 
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCustomer: async (customerId: number, customer: Customer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('saveCustomer', 'customerId', customerId)
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('saveCustomer', 'customer', customer)
            const localVarPath = `/api/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * パスワード編集。パスワード編集ダイアログ、パスワード再設定ダイアログ、初期パスワード変更ダイアログ用。
         * @summary パスワード編集
         * @param {number} customerId 
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCustomerPassword: async (customerId: number, customer: Customer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('saveCustomerPassword', 'customerId', customerId)
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('saveCustomerPassword', 'customer', customer)
            const localVarPath = `/api/customers/{customerId}/password`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 測定結果編集。測定結果編集ダイアログ用。
         * @summary 測定結果編集
         * @param {number} measurementId 
         * @param {Measurement} measurement 測定結果
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMeasurement: async (measurementId: number, measurement: Measurement, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'measurementId' is not null or undefined
            assertParamExists('saveMeasurement', 'measurementId', measurementId)
            // verify required parameter 'measurement' is not null or undefined
            assertParamExists('saveMeasurement', 'measurement', measurement)
            const localVarPath = `/api/measurements/{measurementId}`
                .replace(`{${"measurementId"}}`, encodeURIComponent(String(measurementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(measurement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自身の顧客情報編集。個人情報画面用。
         * @summary 自身の顧客情報編集
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePersonal: async (customer: Customer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('savePersonal', 'customer', customer)
            const localVarPath = `/api/personal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自身のパスワード編集。
         * @summary 自身のパスワード編集
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePersonalPassword: async (customer: Customer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('savePersonalPassword', 'customer', customer)
            const localVarPath = `/api/personal/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 場所情報編集。場所情報編集ダイアログ用。管理者用。
         * @summary 場所情報編集
         * @param {number} placeId 
         * @param {Place} place 場所情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePlace: async (placeId: number, place: Place, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'placeId' is not null or undefined
            assertParamExists('savePlace', 'placeId', placeId)
            // verify required parameter 'place' is not null or undefined
            assertParamExists('savePlace', 'place', place)
            const localVarPath = `/api/places/{placeId}`
                .replace(`{${"placeId"}}`, encodeURIComponent(String(placeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(place, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 施術情報編集。施術情報編集ダイアログ用。管理者用。
         * @summary 施術情報編集
         * @param {number} treatmentId 
         * @param {Treatment} treatment 施術情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTreatment: async (treatmentId: number, treatment: Treatment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'treatmentId' is not null or undefined
            assertParamExists('saveTreatment', 'treatmentId', treatmentId)
            // verify required parameter 'treatment' is not null or undefined
            assertParamExists('saveTreatment', 'treatment', treatment)
            const localVarPath = `/api/treatments/{treatmentId}`
                .replace(`{${"treatmentId"}}`, encodeURIComponent(String(treatmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(treatment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 施術実施。施術情報編集ダイアログ用。管理者用。
         * @summary 施術実施
         * @param {number} treatmentId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTreatmentCustomerId: async (treatmentId: number, customerId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'treatmentId' is not null or undefined
            assertParamExists('saveTreatmentCustomerId', 'treatmentId', treatmentId)
            const localVarPath = `/api/treatments/{treatmentId}/customerId`
                .replace(`{${"treatmentId"}}`, encodeURIComponent(String(treatmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 測定結果追加。測定結果ダイアログ用。管理者用。
         * @summary 測定結果追加
         * @param {Measurement} measurement 測定結果
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMeasurement(measurement: Measurement, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMeasurement(measurement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 場所情報追加。場所情報編集ダイアログ用。管理者用。
         * @summary 場所情報追加
         * @param {Place} place 場所情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPlace(place: Place, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPlace(place, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 施術情報追加。スケジュール画面用。管理者用。
         * @summary 施術情報追加
         * @param {Treatment} treatment 施術情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTreatment(treatment: Treatment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTreatment(treatment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 予約、予約者変更。施術情報編集ダイアログ用。
         * @summary 予約、予約者変更
         * @param {number} treatmentId 
         * @param {boolean} [withCancel] 
         * @param {Reservation} [reservation] 予約情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTreatmentReservationId(treatmentId: number, withCancel?: boolean, reservation?: Reservation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTreatmentReservationId(treatmentId, withCancel, reservation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 顧客情報新規作成。新規登録画面用。管理者用。
         * @summary 顧客情報新規作成
         * @param {Customer} customer 顧客情報
         * @param {boolean} [isPast] 過去の顧客の簡易登録か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomer(customer: Customer, isPast?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer(customer, isPast, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 初期パスワード発行。顧客情報編集画面用。
         * @summary 初期パスワード発行
         * @param {InitialPassword} initialPassword 初期パスワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInitialPassword(initialPassword: InitialPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitialPassword>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInitialPassword(initialPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 施術情報削除。施術情報編集ダイアログ用。管理者用。
         * @summary 施術情報削除
         * @param {number} treatmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTreatment(treatmentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTreatment(treatmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 予約キャンセル。施術情報編集ダイアログ用。管理者用。
         * @summary 予約キャンセル
         * @param {number} treatmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTreatmentReservationId(treatmentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTreatmentReservationId(treatmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 顧客情報取得。顧客閲覧・編集画面用。
         * @summary 顧客情報取得
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomer(customerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomer(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 顧客情報一覧取得。顧客一覧画面、顧客選択ダイアログ用。管理者用。
         * @summary 顧客情報一覧取得
         * @param {string} field フィールド \&#39;list\&#39;&#x3D;\&#39;顧客一覧画面用\&#39; \&#39;select\&#39;&#x3D;\&#39;顧客選択ダイアログ用\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerList(field: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Customer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerList(field, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自身の顧客情報取得。個人情報画面用。
         * @summary 自身の顧客情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonal(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 場所情報一覧取得。施術情報編集ダイアログ、場所情報編集ダイアログ用。管理者用。
         * @summary 場所情報一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlaceList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Place>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlaceList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 施術情報取得。施術情報編集ダイアログ、測定結果編集ダイアログ用。
         * @summary 施術情報取得
         * @param {number} treatmentId 
         * @param {string} field フィールド \&#39;treatment\&#39;&#x3D;施術情報編集ダイアログ用 \&#39;measurement\&#39;&#x3D;\&#39;測定結果編集ダイアログ用\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTreatment(treatmentId: number, field: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Treatment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTreatment(treatmentId, field, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 施術情報一覧取得。施術一覧画面、予約一覧画面、スケジュール画面用。
         * @summary 施術情報一覧取得
         * @param {string} field フィールド \&#39;treatments\&#39;&#x3D;施術一覧画面用 \&#39;schedules\&#39;&#x3D;\&#39;スケジュール画面用\&#39; \&#39;measurements\&#39;&#x3D;\&#39;測定結果画面用\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTreatmentList(field: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Treatment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTreatmentList(field, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * パスワードリセット要求。パスワードリセット要求ダイアログ用。
         * @summary パスワードリセット要求
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestResetPassword(customer: Customer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestResetPassword(customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 顧客情報編集。顧客閲覧・編集画面用。
         * @summary 顧客情報編集
         * @param {number} customerId 
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCustomer(customerId: number, customer: Customer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCustomer(customerId, customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * パスワード編集。パスワード編集ダイアログ、パスワード再設定ダイアログ、初期パスワード変更ダイアログ用。
         * @summary パスワード編集
         * @param {number} customerId 
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCustomerPassword(customerId: number, customer: Customer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCustomerPassword(customerId, customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 測定結果編集。測定結果編集ダイアログ用。
         * @summary 測定結果編集
         * @param {number} measurementId 
         * @param {Measurement} measurement 測定結果
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveMeasurement(measurementId: number, measurement: Measurement, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveMeasurement(measurementId, measurement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自身の顧客情報編集。個人情報画面用。
         * @summary 自身の顧客情報編集
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePersonal(customer: Customer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePersonal(customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自身のパスワード編集。
         * @summary 自身のパスワード編集
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePersonalPassword(customer: Customer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePersonalPassword(customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 場所情報編集。場所情報編集ダイアログ用。管理者用。
         * @summary 場所情報編集
         * @param {number} placeId 
         * @param {Place} place 場所情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePlace(placeId: number, place: Place, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePlace(placeId, place, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 施術情報編集。施術情報編集ダイアログ用。管理者用。
         * @summary 施術情報編集
         * @param {number} treatmentId 
         * @param {Treatment} treatment 施術情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTreatment(treatmentId: number, treatment: Treatment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTreatment(treatmentId, treatment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 施術実施。施術情報編集ダイアログ用。管理者用。
         * @summary 施術実施
         * @param {number} treatmentId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTreatmentCustomerId(treatmentId: number, customerId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTreatmentCustomerId(treatmentId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * 測定結果追加。測定結果ダイアログ用。管理者用。
         * @summary 測定結果追加
         * @param {Measurement} measurement 測定結果
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMeasurement(measurement: Measurement, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.addMeasurement(measurement, options).then((request) => request(axios, basePath));
        },
        /**
         * 場所情報追加。場所情報編集ダイアログ用。管理者用。
         * @summary 場所情報追加
         * @param {Place} place 場所情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlace(place: Place, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.addPlace(place, options).then((request) => request(axios, basePath));
        },
        /**
         * 施術情報追加。スケジュール画面用。管理者用。
         * @summary 施術情報追加
         * @param {Treatment} treatment 施術情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTreatment(treatment: Treatment, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.addTreatment(treatment, options).then((request) => request(axios, basePath));
        },
        /**
         * 予約、予約者変更。施術情報編集ダイアログ用。
         * @summary 予約、予約者変更
         * @param {number} treatmentId 
         * @param {boolean} [withCancel] 
         * @param {Reservation} [reservation] 予約情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTreatmentReservationId(treatmentId: number, withCancel?: boolean, reservation?: Reservation, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.addTreatmentReservationId(treatmentId, withCancel, reservation, options).then((request) => request(axios, basePath));
        },
        /**
         * 顧客情報新規作成。新規登録画面用。管理者用。
         * @summary 顧客情報新規作成
         * @param {Customer} customer 顧客情報
         * @param {boolean} [isPast] 過去の顧客の簡易登録か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer(customer: Customer, isPast?: boolean, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.createCustomer(customer, isPast, options).then((request) => request(axios, basePath));
        },
        /**
         * 初期パスワード発行。顧客情報編集画面用。
         * @summary 初期パスワード発行
         * @param {InitialPassword} initialPassword 初期パスワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInitialPassword(initialPassword: InitialPassword, options?: any): AxiosPromise<InitialPassword> {
            return localVarFp.createInitialPassword(initialPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 施術情報削除。施術情報編集ダイアログ用。管理者用。
         * @summary 施術情報削除
         * @param {number} treatmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTreatment(treatmentId: number, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.deleteTreatment(treatmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 予約キャンセル。施術情報編集ダイアログ用。管理者用。
         * @summary 予約キャンセル
         * @param {number} treatmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTreatmentReservationId(treatmentId: number, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.deleteTreatmentReservationId(treatmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 顧客情報取得。顧客閲覧・編集画面用。
         * @summary 顧客情報取得
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer(customerId: number, options?: any): AxiosPromise<Customer> {
            return localVarFp.getCustomer(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 顧客情報一覧取得。顧客一覧画面、顧客選択ダイアログ用。管理者用。
         * @summary 顧客情報一覧取得
         * @param {string} field フィールド \&#39;list\&#39;&#x3D;\&#39;顧客一覧画面用\&#39; \&#39;select\&#39;&#x3D;\&#39;顧客選択ダイアログ用\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerList(field: string, options?: any): AxiosPromise<Array<Customer>> {
            return localVarFp.getCustomerList(field, options).then((request) => request(axios, basePath));
        },
        /**
         * 自身の顧客情報取得。個人情報画面用。
         * @summary 自身の顧客情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonal(options?: any): AxiosPromise<Customer> {
            return localVarFp.getPersonal(options).then((request) => request(axios, basePath));
        },
        /**
         * 場所情報一覧取得。施術情報編集ダイアログ、場所情報編集ダイアログ用。管理者用。
         * @summary 場所情報一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaceList(options?: any): AxiosPromise<Array<Place>> {
            return localVarFp.getPlaceList(options).then((request) => request(axios, basePath));
        },
        /**
         * 施術情報取得。施術情報編集ダイアログ、測定結果編集ダイアログ用。
         * @summary 施術情報取得
         * @param {number} treatmentId 
         * @param {string} field フィールド \&#39;treatment\&#39;&#x3D;施術情報編集ダイアログ用 \&#39;measurement\&#39;&#x3D;\&#39;測定結果編集ダイアログ用\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTreatment(treatmentId: number, field: string, options?: any): AxiosPromise<Treatment> {
            return localVarFp.getTreatment(treatmentId, field, options).then((request) => request(axios, basePath));
        },
        /**
         * 施術情報一覧取得。施術一覧画面、予約一覧画面、スケジュール画面用。
         * @summary 施術情報一覧取得
         * @param {string} field フィールド \&#39;treatments\&#39;&#x3D;施術一覧画面用 \&#39;schedules\&#39;&#x3D;\&#39;スケジュール画面用\&#39; \&#39;measurements\&#39;&#x3D;\&#39;測定結果画面用\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTreatmentList(field: string, options?: any): AxiosPromise<Array<Treatment>> {
            return localVarFp.getTreatmentList(field, options).then((request) => request(axios, basePath));
        },
        /**
         * パスワードリセット要求。パスワードリセット要求ダイアログ用。
         * @summary パスワードリセット要求
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPassword(customer: Customer, options?: any): AxiosPromise<Customer> {
            return localVarFp.requestResetPassword(customer, options).then((request) => request(axios, basePath));
        },
        /**
         * 顧客情報編集。顧客閲覧・編集画面用。
         * @summary 顧客情報編集
         * @param {number} customerId 
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCustomer(customerId: number, customer: Customer, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.saveCustomer(customerId, customer, options).then((request) => request(axios, basePath));
        },
        /**
         * パスワード編集。パスワード編集ダイアログ、パスワード再設定ダイアログ、初期パスワード変更ダイアログ用。
         * @summary パスワード編集
         * @param {number} customerId 
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCustomerPassword(customerId: number, customer: Customer, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.saveCustomerPassword(customerId, customer, options).then((request) => request(axios, basePath));
        },
        /**
         * 測定結果編集。測定結果編集ダイアログ用。
         * @summary 測定結果編集
         * @param {number} measurementId 
         * @param {Measurement} measurement 測定結果
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMeasurement(measurementId: number, measurement: Measurement, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.saveMeasurement(measurementId, measurement, options).then((request) => request(axios, basePath));
        },
        /**
         * 自身の顧客情報編集。個人情報画面用。
         * @summary 自身の顧客情報編集
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePersonal(customer: Customer, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.savePersonal(customer, options).then((request) => request(axios, basePath));
        },
        /**
         * 自身のパスワード編集。
         * @summary 自身のパスワード編集
         * @param {Customer} customer 顧客情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePersonalPassword(customer: Customer, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.savePersonalPassword(customer, options).then((request) => request(axios, basePath));
        },
        /**
         * 場所情報編集。場所情報編集ダイアログ用。管理者用。
         * @summary 場所情報編集
         * @param {number} placeId 
         * @param {Place} place 場所情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePlace(placeId: number, place: Place, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.savePlace(placeId, place, options).then((request) => request(axios, basePath));
        },
        /**
         * 施術情報編集。施術情報編集ダイアログ用。管理者用。
         * @summary 施術情報編集
         * @param {number} treatmentId 
         * @param {Treatment} treatment 施術情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTreatment(treatmentId: number, treatment: Treatment, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.saveTreatment(treatmentId, treatment, options).then((request) => request(axios, basePath));
        },
        /**
         * 施術実施。施術情報編集ダイアログ用。管理者用。
         * @summary 施術実施
         * @param {number} treatmentId 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTreatmentCustomerId(treatmentId: number, customerId?: number, options?: any): AxiosPromise<Array<any>> {
            return localVarFp.saveTreatmentCustomerId(treatmentId, customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 測定結果追加。測定結果ダイアログ用。管理者用。
     * @summary 測定結果追加
     * @param {Measurement} measurement 測定結果
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public addMeasurement(measurement: Measurement, options?: any) {
        return SystemApiFp(this.configuration).addMeasurement(measurement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 場所情報追加。場所情報編集ダイアログ用。管理者用。
     * @summary 場所情報追加
     * @param {Place} place 場所情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public addPlace(place: Place, options?: any) {
        return SystemApiFp(this.configuration).addPlace(place, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 施術情報追加。スケジュール画面用。管理者用。
     * @summary 施術情報追加
     * @param {Treatment} treatment 施術情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public addTreatment(treatment: Treatment, options?: any) {
        return SystemApiFp(this.configuration).addTreatment(treatment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 予約、予約者変更。施術情報編集ダイアログ用。
     * @summary 予約、予約者変更
     * @param {number} treatmentId 
     * @param {boolean} [withCancel] 
     * @param {Reservation} [reservation] 予約情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public addTreatmentReservationId(treatmentId: number, withCancel?: boolean, reservation?: Reservation, options?: any) {
        return SystemApiFp(this.configuration).addTreatmentReservationId(treatmentId, withCancel, reservation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 顧客情報新規作成。新規登録画面用。管理者用。
     * @summary 顧客情報新規作成
     * @param {Customer} customer 顧客情報
     * @param {boolean} [isPast] 過去の顧客の簡易登録か
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public createCustomer(customer: Customer, isPast?: boolean, options?: any) {
        return SystemApiFp(this.configuration).createCustomer(customer, isPast, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 初期パスワード発行。顧客情報編集画面用。
     * @summary 初期パスワード発行
     * @param {InitialPassword} initialPassword 初期パスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public createInitialPassword(initialPassword: InitialPassword, options?: any) {
        return SystemApiFp(this.configuration).createInitialPassword(initialPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 施術情報削除。施術情報編集ダイアログ用。管理者用。
     * @summary 施術情報削除
     * @param {number} treatmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public deleteTreatment(treatmentId: number, options?: any) {
        return SystemApiFp(this.configuration).deleteTreatment(treatmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 予約キャンセル。施術情報編集ダイアログ用。管理者用。
     * @summary 予約キャンセル
     * @param {number} treatmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public deleteTreatmentReservationId(treatmentId: number, options?: any) {
        return SystemApiFp(this.configuration).deleteTreatmentReservationId(treatmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 顧客情報取得。顧客閲覧・編集画面用。
     * @summary 顧客情報取得
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getCustomer(customerId: number, options?: any) {
        return SystemApiFp(this.configuration).getCustomer(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 顧客情報一覧取得。顧客一覧画面、顧客選択ダイアログ用。管理者用。
     * @summary 顧客情報一覧取得
     * @param {string} field フィールド \&#39;list\&#39;&#x3D;\&#39;顧客一覧画面用\&#39; \&#39;select\&#39;&#x3D;\&#39;顧客選択ダイアログ用\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getCustomerList(field: string, options?: any) {
        return SystemApiFp(this.configuration).getCustomerList(field, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自身の顧客情報取得。個人情報画面用。
     * @summary 自身の顧客情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getPersonal(options?: any) {
        return SystemApiFp(this.configuration).getPersonal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 場所情報一覧取得。施術情報編集ダイアログ、場所情報編集ダイアログ用。管理者用。
     * @summary 場所情報一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getPlaceList(options?: any) {
        return SystemApiFp(this.configuration).getPlaceList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 施術情報取得。施術情報編集ダイアログ、測定結果編集ダイアログ用。
     * @summary 施術情報取得
     * @param {number} treatmentId 
     * @param {string} field フィールド \&#39;treatment\&#39;&#x3D;施術情報編集ダイアログ用 \&#39;measurement\&#39;&#x3D;\&#39;測定結果編集ダイアログ用\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getTreatment(treatmentId: number, field: string, options?: any) {
        return SystemApiFp(this.configuration).getTreatment(treatmentId, field, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 施術情報一覧取得。施術一覧画面、予約一覧画面、スケジュール画面用。
     * @summary 施術情報一覧取得
     * @param {string} field フィールド \&#39;treatments\&#39;&#x3D;施術一覧画面用 \&#39;schedules\&#39;&#x3D;\&#39;スケジュール画面用\&#39; \&#39;measurements\&#39;&#x3D;\&#39;測定結果画面用\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getTreatmentList(field: string, options?: any) {
        return SystemApiFp(this.configuration).getTreatmentList(field, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * パスワードリセット要求。パスワードリセット要求ダイアログ用。
     * @summary パスワードリセット要求
     * @param {Customer} customer 顧客情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public requestResetPassword(customer: Customer, options?: any) {
        return SystemApiFp(this.configuration).requestResetPassword(customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 顧客情報編集。顧客閲覧・編集画面用。
     * @summary 顧客情報編集
     * @param {number} customerId 
     * @param {Customer} customer 顧客情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public saveCustomer(customerId: number, customer: Customer, options?: any) {
        return SystemApiFp(this.configuration).saveCustomer(customerId, customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * パスワード編集。パスワード編集ダイアログ、パスワード再設定ダイアログ、初期パスワード変更ダイアログ用。
     * @summary パスワード編集
     * @param {number} customerId 
     * @param {Customer} customer 顧客情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public saveCustomerPassword(customerId: number, customer: Customer, options?: any) {
        return SystemApiFp(this.configuration).saveCustomerPassword(customerId, customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 測定結果編集。測定結果編集ダイアログ用。
     * @summary 測定結果編集
     * @param {number} measurementId 
     * @param {Measurement} measurement 測定結果
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public saveMeasurement(measurementId: number, measurement: Measurement, options?: any) {
        return SystemApiFp(this.configuration).saveMeasurement(measurementId, measurement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自身の顧客情報編集。個人情報画面用。
     * @summary 自身の顧客情報編集
     * @param {Customer} customer 顧客情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public savePersonal(customer: Customer, options?: any) {
        return SystemApiFp(this.configuration).savePersonal(customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自身のパスワード編集。
     * @summary 自身のパスワード編集
     * @param {Customer} customer 顧客情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public savePersonalPassword(customer: Customer, options?: any) {
        return SystemApiFp(this.configuration).savePersonalPassword(customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 場所情報編集。場所情報編集ダイアログ用。管理者用。
     * @summary 場所情報編集
     * @param {number} placeId 
     * @param {Place} place 場所情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public savePlace(placeId: number, place: Place, options?: any) {
        return SystemApiFp(this.configuration).savePlace(placeId, place, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 施術情報編集。施術情報編集ダイアログ用。管理者用。
     * @summary 施術情報編集
     * @param {number} treatmentId 
     * @param {Treatment} treatment 施術情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public saveTreatment(treatmentId: number, treatment: Treatment, options?: any) {
        return SystemApiFp(this.configuration).saveTreatment(treatmentId, treatment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 施術実施。施術情報編集ダイアログ用。管理者用。
     * @summary 施術実施
     * @param {number} treatmentId 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public saveTreatmentCustomerId(treatmentId: number, customerId?: number, options?: any) {
        return SystemApiFp(this.configuration).saveTreatmentCustomerId(treatmentId, customerId, options).then((request) => request(this.axios, this.basePath));
    }
}


