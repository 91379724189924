/** 文字列ユーティリティ */
export class StringUtil {
	/** フラグのなし値 */
	static readonly FLAG_NONE_VALUE = '-';

	/** 
	 * フラグの既定値を取得する
	 * @param flagEnumType フラグに対応するEnum型
	 */
	static getDefaultFlag(flagEnumType: object): string {
		const count = Object.keys(flagEnumType).length / 2;
		return StringUtil.FLAG_NONE_VALUE.repeat(count);
	}

	/** 
	 * フラグのn番目の値を取得する
	 * @param flagEnumType フラグに対応するEnum型
	 */
	static getFlag(flag: string, n: number, flagEnumType: object): string {
		return (n < flag.length) ? flag[n] : StringUtil.getDefaultFlag(flagEnumType)[n];
	}

	/** 
	 * フラグのn番目に値を設定する
	 * @param flagEnumType フラグに対応するEnum型
	 */
	static setFlag(flag: string, n: number, value: string, flagEnumType: object): string {
		const str = flag + StringUtil.getDefaultFlag(flagEnumType).slice(flag.length);
		if (n < 0 || n >= str.length) {
			return str;
		}
		return str.slice(0, n) + value[0] + str.slice(n + 1);
	}

	/** 0以上の整数に適さないものを取り除く */
	static filterIntegerGreaterEqualZero(src: string): string {
		let str = "";
		let isNumber = false;
		for (let i = 0; i < src.length; i++) {
			const char = src[i];
			if (char >= '0' && char <= '9') {
				if (isNumber) {    // ゼロ以外の数字があるとき
					str += char;
				} else if (char != '0') {
					str += char;
					isNumber = true;
				} else if (src.length == 1) {
					str += char;
				}
			}
		}
		return str;
	}

	/** 0以上の整数に適さないものを取り除く */
	static filterIntegerGreaterEqualOne(src: string): string {
		let str = "";
		let isNumber = false;
		for (let i = 0; i < src.length; i++) {
			const char = src[i];
			if (char >= '0' && char <= '9') {
				if (isNumber) {    // ゼロ以外の数字があるとき
					str += char;
				} else if (char != '0') {
					str += char;
					isNumber = true;
				}
			}
		}
		return str;
	}

	/** 実数に適さないものを取り除く */
	static filterFloat(src: string): string {
		let str = "";
		let hasDot = false;
		let isNumber = false;
		for (let i = 0; i < src.length; i++) {
			const char = src[i];
			if (char >= '0' && char <= '9') {
				if (isNumber) {    // ゼロ以外の数字があるとき
					str += char;
				} else if (char != '0') {
					str += char;
					isNumber = true;
				} else if (src == '0' || src[1] == '.' || hasDot) {
					str += char;
				}
			} else if (char == '.') {
				if (!hasDot && isNumber || i == 1) {    // ドットがなく、ゼロ以外の数字があるとき
					str += char;
					hasDot = true;
				}
			}
		}
		return str;
	}

	/** 郵便番号に適さないものを取り除く */
	static filterPostalCode(src: string): string {
		let str = "";
		for (let i = 0; i < src.length; i++) {
			const char = src[i];
			if (char >= '0' && char <= '9' || char == '-') {
				str += char;
			}
		}
		return str;
	}

	/** 電話番号に適さないものを取り除く */
	static filterTelephone(src: string): string {
		let str = "";
		for (let i = 0; i < src.length; i++) {
			const char = src[i];
			if (char >= '0' && char <= '9' || char == '-' || char == '+' || char == '(' || char == ')') {
				str += char;
			}
		}
		return str;
	}

	/** スペースと制御文字を除くASCII文字に適さないものを取り除く */
	static filterAscii(src: string): string {
		let str = "";
		for (let i = 0; i < src.length; i++) {
			const char = src[i];
			if (char >= '!' && char <= '~') {
				str += char;
			}
		}
		return str;
	}
}