import { Customer } from "~/axios";
import { PermissionType } from "~/constant/PermissionType";
import { PermissionValueType } from "~/constant/PermissionValueType";
import { StringUtil } from "~/util/StringUtil";

/** 権限ユーティリティ */
export class PermissionUtil {
	/** 権限値から権限タイプを取得する */
	static getType(value: PermissionValueType): PermissionType {
		switch (value) {
			case PermissionValueType.AdminPersonal:
			case PermissionValueType.AdminAll:
				return PermissionType.Admin;
			case PermissionValueType.PersonalWrite:
				return PermissionType.PersonalInfo;
			case PermissionValueType.ReservationNormal:
			case PermissionValueType.ReservationCourse:
				return PermissionType.Reservation;
			default:
				return -1;
		}
	}

	/** 指定された権限フラグを立てる */
	static setFlag(src: Customer | string, value: PermissionValueType): string {
		const str = (typeof src == 'string') ? src : src.permissionFlag!;
		const type = PermissionUtil.getType(value);
		return StringUtil.setFlag(str, type, value, PermissionType);
	}

	/** 指定された権限があるか判定する */
	static check(src: Customer | string, value: PermissionValueType): boolean {
		const str = (typeof src == 'string') ? src : src.permissionFlag!;
		const type = PermissionUtil.getType(value);
		const flag = StringUtil.getFlag(str, type, PermissionType);
		switch (value) {
			case PermissionValueType.AdminPersonal:
				return (flag == PermissionValueType.AdminPersonal
					|| flag == PermissionValueType.AdminAll);
			case PermissionValueType.AdminAll:
				return (flag == PermissionValueType.AdminAll);
			case PermissionValueType.PersonalWrite:
				return (flag == PermissionValueType.PersonalWrite);
			case PermissionValueType.ReservationNormal:
				return (flag == PermissionValueType.ReservationNormal
					|| flag == PermissionValueType.ReservationCourse);
			case PermissionValueType.ReservationCourse:
				return (flag == PermissionValueType.ReservationCourse);
			default:
				return false;
		}
	}

	/** 指定された権限タイプの種別の文字列を取得する。 */
	static getString(src: Customer | string, type: PermissionType): string {
		const str = (typeof src == 'string') ? src : src.permissionFlag!;
		const flag = StringUtil.getFlag(str, type, PermissionType);
		switch (type) {
			case PermissionType.Reservation:
				return PermissionUtil.check(src, PermissionValueType.ReservationCourse) ? "コース予約会員"
					: PermissionUtil.check(src, PermissionValueType.ReservationNormal) ? "通常予約会員"
						: "非予約会員";
			default:
				return "";
		}
	}
}