import { createStyles, makeStyles, MenuItem, Select } from '@material-ui/core';
import clone from 'clone';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Place, SystemApi } from '~/axios';
import { AppContext } from '~/component/base/App';
import InputField from '~/component/common/InputField';
import ScrollDialog from '~/component/common/ScrollDialog';
import { ErrorUtil } from '~/util/ErrorUtil';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	selectArea: {
		padding: theme.spacing(2, 3, 0, 3),
	},
	select: {
		minWidth: '200px',
	},
	gray: {
		whiteSpace: 'nowrap',
		opacity: '0.75',
	},
}));

/** 地域一覧 */
export const AREA_LIST = ["東京", "名古屋", "その他"];

// ページ情報
class PageInfo {
	// 場所ID
	placeId = 0;
	// 場所名
	placeName = "";
	// 地域
	area = "";
	// 地図URL
	mapUrl = "";
	// 場所詳細
	detail = "";
	// 更新日時
	updateDate = "";
	// 保存可能か
	enable = false;

	/** 入力内容が変化したか */
	isChanged(src?: Place): boolean {
		return (this.placeName != (src?.placeName ?? "") || this.area != (src?.area ?? "")
			|| this.mapUrl != (src?.mapUrl ?? "") || this.detail != (src?.detail ?? ""));
	}
	/** Placeから変換する */
	static fromPlace(src?: Place): PageInfo {
		const p = new PageInfo();
		if (src) {
			p.placeId = src.placeId!;
			p.placeName = src.placeName!;
			p.area = src.area!;
			p.mapUrl = src.mapUrl!;
			p.detail = src.detail!;
			p.updateDate = src.updateDate!;
		}
		p.enable = true;
		return p;
	}
	/** レコード保存用Placeへ変換する */
	toPlace(): Place {
		const ret: Place = {};
		ret.placeName = this.placeName;
		ret.area = this.area;
		ret.mapUrl = this.mapUrl;
		ret.detail = this.detail;
		ret.updateDate = this.updateDate || undefined;
		return ret;
	}
}

// プロパティ
type Props = {
	/** 場所ID */
	placeId: number,
	/** 閉じるイベント */
	onClose: (savedPlaceId?: number, area?: string) => void,
};

/** 場所情報編集ダイアログ */
export default function PlaceEdit(props: Props): JSX.Element {
	// ページ情報
	const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());
	// 場所情報
	const [places, setPlaces] = useState<Place[]>([]);
	// appFunctions
	const appFuncs = useContext(AppContext)!;

	// レンダリング後フック
	useEffect(() => {
		void getRecord();
	}, []);

	const isWrite = (props.placeId > 0);

	// レコードを取得する
	const getRecord = async () => {
		if (isWrite) {  // 編集モードのとき
			try {
				const res = await new SystemApi().getPlaceList();
				setPlaces(res.data);
				const place = res.data.find((p) => p.placeId == props.placeId);
				setPageInfo(PageInfo.fromPlace(place));
			} catch (err) {
				appFuncs.setErrorMessage(ErrorUtil.getMessage(err), true);
			}
		}
	};

	// 保存する
	const save = async () => {
		try {
			if (pageInfo.placeId == 0) {    // 追加のとき
				const res = await new SystemApi().addPlace(pageInfo.toPlace());
				props.onClose(res.data.placeId, pageInfo.area);
			} else {    // 編集のとき
				await new SystemApi().savePlace(pageInfo.placeId, pageInfo.toPlace());
				props.onClose(pageInfo.placeId, pageInfo.area);
			}
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 閉じる
	const close = () => {
		props.onClose();
	};

	// 入力内容が変更された際に呼ばれる
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const name = event.target.name as keyof PageInfo;
		if (name == 'placeName' || name == 'mapUrl' || name == 'detail') { // 入力欄のとき
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p[name] = value;
				return p;
			});
		}
	};

	// 選択内容が変更された際に呼ばれる
	const onSelectChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
		const name = event.target.name as keyof PageInfo;
		if (name == 'area' || name == 'placeId') { // 選択欄のとき
			if (name == 'placeId') { // 場所IDのとき
				const value = event.target.value as number;
				const place = places.find((p) => p.placeId == value);
				setPageInfo(PageInfo.fromPlace(place));
			} else {
				const value = event.target.value as string;
				setPageInfo((pageInfo) => {
					const p = clone(pageInfo);
					p[name] = value;
					return p;
				});
			}
		}
	};

	// 描画
	const isChanged = pageInfo.isChanged(places.find((p) => p.placeId == pageInfo.placeId));
	const classes = useStyles();
	return (
		<ScrollDialog title={isWrite ? "場所情報編集" : "場所追加"}
			isOutsideClose={false} onClose={close}
			buttons={[
				{
					label: "保存", variant: 'contained', color: 'primary', onClick: save,
					disabled: isWrite && (!pageInfo.enable || !isChanged), validate: true
				},
				{ label: "キャンセル", variant: 'outlined', color: 'default', onClick: close },
			]}>
			{isWrite && <div className={classes.selectArea}>
				<Select className={classes.select} name='placeId' variant='outlined' value={pageInfo.placeId}
					onChange={onSelectChange}>
					<MenuItem value={0}>新規</MenuItem>
					{places.map((p) => <MenuItem value={p.placeId}>{p.placeName}</MenuItem>)}
				</Select>
			</div>}
			<InputField label="場所名" name='placeName' width='200px'
				value={pageInfo.placeName} onChange={onInputChange} maxLength={50} />
			<div className={classes.selectArea}>
				<Select className={classes.select} name='area' variant='outlined'
					value={pageInfo.area || 0} onChange={onSelectChange}>
					<MenuItem disabled value={0}>
						<span className={classes.gray}>地域</span>
					</MenuItem>
					{AREA_LIST.map((area) => <MenuItem value={area}>{area}</MenuItem>)}
				</Select>
			</div>
			<InputField label="地図URL" name='mapUrl' width='300px' placeholder="未入力可"
				value={pageInfo.mapUrl} onChange={onInputChange} maxLength={200} />
			<InputField label="場所詳細" name='detail' width='300px' placeholder="未入力可"
				value={pageInfo.detail} onChange={onInputChange} maxLength={200} />
		</ScrollDialog>
	);
}