import { createStyles, makeStyles } from '@material-ui/core';
import background from "../../img/background.jpg";

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	background: {
		display: 'block',
		height: '100%',
		backgroundSize: 'cover',
		backgroundImage: `url(${background})`,
		backgroundColor: 'rgba(255,255,255,0.8)',
		backgroundBlendMode: 'lighten',
	},
}));

/** ホーム画面 */
export default function Home() {
	const classes = useStyles();
	return <div className={classes.background} />;
}
