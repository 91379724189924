import { createStyles, makeStyles, MenuItem, Select } from '@material-ui/core';
import clone from 'clone';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Customer, SystemApi } from '~/axios';
import { AppContext } from '~/component/base/App';
import InputDialog from '~/component/common/InputDialog';
import ScrollDialog from '~/component/common/ScrollDialog';
import { CustomerAuxil } from '~/model/CustomerAuxil';
import { ErrorUtil } from '~/util/ErrorUtil';
import { ObjectUtil } from '~/util/ObjectUtil';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	select: {
		minWidth: '200px',
	},
	gray: {
		whiteSpace: 'nowrap',
		opacity: '0.75',
	},
}));

// プロパティ
type Props = {
	/** モード */
	mode: 'treat' | 'reserve',
	/** 予約している顧客ID */
	customerId?: number,
	/** 閉じるイベント */
	onClose: () => void,
	/** 送信イベント */
	onSubmit: (customerId: number, name?: string) => void,
};

/** 顧客選択ダイアログ */
export default function CustomerSelect(props: Props): JSX.Element {
	// 顧客ID
	const [customerId, setCustomerId] = useState<number>(0);
	// 顧客情報
	const [customers, setCustomers] = useState<Customer[]>([]);
	// 新規顧客情報入力ダイアログを開くか
	const [isInputOpen, setIsInputOpen] = useState<boolean>(false);
	// appFunctions
	const appFuncs = useContext(AppContext)!;

	// レンダリング後フック
	useEffect(() => {
		void getRecord();
	}, []);

	const isTreat = (props.mode == 'treat');

	// レコードを取得する
	const getRecord = async () => {
		try {
			const res = await new SystemApi().getCustomerList('select');
			let customers = res.data.filter((c) => !CustomerAuxil.isTemporaryCustomer(c.customerId!));
			if (isTreat) {	// 実施者選択モードのとき
				ObjectUtil.sort(customers, 'createDate', 'desc');
			} else {	// 予約者選択モードのとき
				const newCustomers = res.data.filter((c) => CustomerAuxil.isTemporaryCustomer(c.customerId!));
				ObjectUtil.sort(customers, 'lastTreatmentDate', 'desc');
				customers = newCustomers.concat(customers);
			}
			setCustomers(customers);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err), true);
		}
	};

	// 送信する
	const submit = () => {
		if (customerId == -1) {	// 新規顧客のとき
			const name = customers.find((c) => c.customerId == -1)!.name!;
			props.onSubmit(customerId, name);
		} else {
			props.onSubmit(customerId);
		}
	};

	// 閉じる
	const close = () => {
		props.onClose();
	};

	// 選択内容が変更された際に呼ばれる
	const onSelectChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
		const id = Number(event.target.value);
		if (id == -2) {	// 新規顧客入力のとき
			setIsInputOpen(true);
		} else {
			setCustomerId(id);
		}
	};

	// 新規顧客情報入力ダイアログが承認される際に呼ばれる
	const onInputSubmit = (input: string) => {
		setIsInputOpen(false);
		const name = input + "（新規）";
		setCustomers((customers) => {
			const cList = clone(customers);
			const newCustomer = cList.find((c) => c.customerId == -1);
			if (!newCustomer) {	// プルダウンに新規顧客を追加していないとき
				const customer: Customer = {};
				customer.customerId = -1;
				customer.name = name;
				cList.unshift(customer);
			} else {
				newCustomer.name = name;
			}
			return cList;
		});
		setCustomerId(-1);
	};

	// 新規顧客情報入力ダイアログを閉じる
	const closeInput = () => {
		setIsInputOpen(false);
	};

	// 描画
	const customer = customers.find((c) => c.customerId == props.customerId);
	const classes = useStyles();
	return (
		<ScrollDialog title={isTreat ? "実施者選択" : props.customerId ? "予約者変更" : "予約者選択"}
			isOutsideClose={false} onClose={close}
			buttons={[
				{
					label: "送信", variant: 'contained', color: 'primary', onClick: submit,
					disabled: customerId == 0 || customerId == props.customerId, validate: true
				},
				{ label: "キャンセル", variant: 'outlined', color: 'default', onClick: close },
			]}>
			{!!props.customerId && <>
				<span>{customer?.name ?? ""}</span>
				{customer?.nickname && <span className={classes.gray}>&ensp;{customer.nickname}</span>}
				<span>&ensp;から</span>
			</>}
			<Select className={classes.select} variant='outlined' value={customerId} onChange={onSelectChange}>
				<MenuItem disabled value={0}>
					<span className={classes.gray}>{isTreat ? "実施者の名前" : "予約者の名前"}</span>
				</MenuItem>
				{!isTreat && <MenuItem value={-2}>新規</MenuItem>}
				{customers.map((c, idx) => <MenuItem key={idx} value={c.customerId}>
					<span>{c.name}</span>
					{c.nickname && <span className={classes.gray}>&ensp;{c.nickname}</span>}
				</MenuItem>)}
			</Select>
			{!!props.customerId && <span>に変更</span>}
			{/** 新規顧客情報入力ダイアログ */}
			{isInputOpen
				&& <InputDialog title="新規顧客情報" message="" label="名前"
					buttonType='okCancel' onClose={closeInput} onSubmit={onInputSubmit} />}
		</ScrollDialog>
	);
}