/** 情報フラグタイプ */
export enum InfoFlagType {
	/** 病気か、だったか */
	IsIll = 0,
	/** 服薬しているか、していたか */
	IsTakingMedicine = 1,
	/** 妊娠しているか、していたか */
	IsPregnant = 2,
	/** 怪我しているか、していたか */
	IsInjured = 3,
	/** 「別人級美人エステ」について知ってるか */
	IsKnowingEsthetic = 4,
	/** 希望のプラン */
	Plan = 5,
	/** 自宅でケアしているか */
	IsCaring = 6,
}