import { Button, createStyles, FormControlLabel, FormLabel, Grid, makeStyles, Switch } from '@material-ui/core';
import Anchor from '@material-ui/core/Link';
import clone from 'clone';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useRef, useState } from "react";
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Link, useHistory, useParams } from 'react-router-dom';
import { usePostalJp } from 'use-postal-jp';
import { Customer, SystemApi } from "~/axios";
import { AppContext } from '~/component/base/App';
import InputField from '~/component/common/InputField';
import MessageDialog from '~/component/common/MessageDialog';
import Pane from '~/component/common/Pane';
import RadioField from "~/component/common/RadioField";
import PasswordEdit from '~/component/system/PasswordEdit';
import { InfoFlagType } from '~/constant/InfoFlagType';
import { PermissionType } from '~/constant/PermissionType';
import { PermissionValueType } from '~/constant/PermissionValueType';
import { CustomerAuxil } from '~/model/CustomerAuxil';
import { DateUtil } from '~/util/DateUtil';
import { ErrorUtil } from '~/util/ErrorUtil';
import { PermissionUtil } from '~/util/PermissionUtil';
import { StringUtil } from "~/util/StringUtil";

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	form: {
		margin: theme.spacing(0, -2, 3, -2),
	},
	switchBox: {
		maxWidth: '100%',
		width: '350px',
		textAlign: 'right',
		'& div': {
			display: 'inline-grid',
			width: '225px',
		},
		'& .MuiTypography-body1': {
			fontSize: '0.8rem',
		}
	},
	box: {
		maxWidth: '100%',
		width: '350px',
		textAlign: 'left',
		'& table td': {
			paddingTop: theme.spacing(2),
			paddingRight: theme.spacing(1),
		},
	},
	label: {
		paddingLeft: theme.spacing(3),
		whiteSpace: 'nowrap',
	},
	nameBox: {
		padding: theme.spacing(2, 3, 0, 3),
	},
	ruby: {
		fontSize: '0.8rem',
		paddingLeft: theme.spacing(1),
	},
	name: {
		fontSize: '1.5rem',
		[theme.breakpoints.up('sm')]: {
			width: '500px',
		},
		'& div': {
			display: 'inline-block',
		},
	},
	gray: {
		whiteSpace: 'nowrap',
		color: 'gray',
	},
	remark: {
		[theme.breakpoints.up('sm')]: {
			width: '700px',
		},
	},
	breakText: {
		wordBreak: 'break-all',
	},
	inputLabel: {
		padding: theme.spacing(2, 1, 1, 3),
	},
	legend: {
		fontSize: '0.9rem',
	},
	button: {
		margin: theme.spacing(1),
	},
	buttonSmall: {
		fontSize: '0.7rem',
	},
}));

// ページ情報
class PageInfo {
	// 顧客ID
	customerId = 0;
	// 名前
	name = "";
	// ふりがな
	ruby = "";
	// ニックネーム
	nickname = "";
	// 備考
	remark = "";
	// 次回予約の施術日時
	nextTreatmentDate = "";
	// 次回予約の施術時間（単位：分）
	nextTreatmentTime = 0;
	// 最終施術日時
	lastTreatmentDate = "";
	// 最終施術時間（単位：分）
	lastTreatmentTime = 0;
	// 郵便番号
	postalCode = "";
	// 住所
	address = "";
	// 電話番号
	telephone = "";
	// メールアドレス
	mailAddress = "";
	// 生年月日
	birthday = "2000-01-01";
	// 権限フラグ
	permissionFlag = "";
	// 情報フラグ
	infoFlag = StringUtil.getDefaultFlag(InfoFlagType);
	// 病気詳細
	illness = "";
	// 薬詳細
	medicine = "";
	// 妊娠詳細
	pregnancy = "";
	// 怪我詳細
	injury = "";
	// 一番求めるもの
	request = "";
	// 身長
	height = "";
	// 体重
	weight = "";
	// 更新日時
	updateDate = "";
	// 編集モードか
	isEdit = false;
	// 詳細情報編集モードか
	isEditDetail = false;
	// 保存可能か
	enable = false;
	// 一時JSON用ID
	jsonId = "";

	/** 入力内容が変化したか */
	isChanged(src: PageInfo): boolean {
		return (this.name != src.name || this.ruby != src.ruby || this.nickname != src.nickname
			|| this.remark != src.remark || this.postalCode != src.postalCode || this.address != src.address
			|| this.telephone != src.telephone || this.birthday != src.birthday
			|| this.isDetailChanged(src));
	}
	/** 詳細情報の入力内容が変化したか */
	isDetailChanged(src: PageInfo): boolean {
		return (this.infoFlag != src.infoFlag || this.illness != src.illness || this.medicine != src.medicine
			|| this.pregnancy != src.pregnancy || this.injury != src.injury || this.request != src.request
			|| this.height != src.height || this.weight != src.weight);
	}
	/** 詳細情報のみコピーする */
	copyOnlyDetail(src: PageInfo): PageInfo {
		this.infoFlag = src.infoFlag!;
		this.illness = src.illness!;
		this.medicine = src.medicine!;
		this.pregnancy = src.pregnancy!;
		this.injury = src.injury!;
		this.request = src.request!;
		this.height = src.height!;
		this.weight = src.weight!;
		return this;
	}
	/** Customerから変換する */
	static fromCustomer(src: Customer): PageInfo {
		const p = new PageInfo();
		p.customerId = src.customerId!;
		p.name = src.name!;
		p.ruby = src.ruby!;
		p.nickname = src.nickname!;
		p.remark = src.remark ?? p.remark;
		p.nextTreatmentDate = src.nextTreatment?.treatmentDate ?? p.nextTreatmentDate;
		p.nextTreatmentTime = src.nextTreatment?.treatmentTime ?? p.nextTreatmentTime;
		p.lastTreatmentDate = src.lastTreatment?.treatmentDate ?? p.lastTreatmentDate;
		p.lastTreatmentTime = src.lastTreatment?.treatmentTime ?? p.lastTreatmentTime;
		p.postalCode = src.postalCode!;
		p.address = src.address!;
		p.telephone = src.telephone!;
		p.mailAddress = src.mailAddress!;
		p.birthday = src.birthday!;
		p.permissionFlag = src.permissionFlag ?? p.permissionFlag;
		p.infoFlag = src.infoFlag ?? p.infoFlag;
		p.illness = src.illness ?? p.illness;
		p.medicine = src.medicine ?? p.medicine;
		p.pregnancy = src.pregnancy ?? p.pregnancy;
		p.injury = src.injury ?? p.injury;
		p.request = src.request ?? p.request;
		p.height = src.height ? String(src.height) : p.height;
		p.weight = src.weight ? String(src.weight) : p.weight;
		p.updateDate = src.updateDate!;
		p.enable = true;
		return p;
	}
	/** レコード保存用Customerへ変換する */
	toCustomer(): Customer {
		const ret: Customer = {};
		ret.name = this.name;
		ret.ruby = this.ruby;
		ret.nickname = this.nickname;
		ret.remark = this.remark;
		ret.postalCode = this.postalCode;
		ret.address = this.address;
		ret.telephone = this.telephone;
		ret.birthday = this.birthday;
		ret.infoFlag = this.infoFlag;
		ret.illness = this.illness;
		ret.medicine = this.medicine;
		ret.pregnancy = this.pregnancy;
		ret.injury = this.injury;
		ret.request = this.request;
		ret.height = Number(this.height);
		ret.weight = Number(this.weight);
		ret.updateDate = this.updateDate!;
		return ret;
	}
	/** 一時保存用JSONから変換する */
	static fromJson(customerId?: string, json?: string): PageInfo {
		if (!customerId || !json) {	// パスの顧客IDがない、または、一時保存用JSONがないとき
			return new PageInfo();
		}
		try {
			const obj = JSON.parse(json) as Record<string, unknown>;
			if (obj.jsonId == `customer-${customerId}`) {	// 対象の一時保存用JSONがあるとき
				return Object.assign(new PageInfo(), obj);
			} else {
				return new PageInfo();
			}
		} catch {
			return new PageInfo();
		}
	}
	/** 一時保存用JSONへ変換する */
	toJson(): string {
		this.jsonId = `customer-${this.customerId}`;
		return JSON.stringify(this);
	}
}

// メッセージ情報
type MessageInfo = {
	/** メッセージ */
	title: string,
	/** メッセージ */
	message: string,
};

// 確認メッセージ情報
type ConfirmMessageInfo = {
	/** メッセージ */
	message: string,
	/** 承認イベント */
	onSubmit: () => void,
};

// パスパラメータ
type Params = {
	// 顧客ID
	id?: string;
};

/** 顧客情報編集画面、個人情報編集画面 */
export default function CustomerEdit(): JSX.Element {
	// パスパラメータ
	const params = useParams<Params>();
	// ページ情報
	const [pageInfo, setPageInfo] = useState<PageInfo>(PageInfo.fromJson(params.id, Cookies.get('tmp')));
	// オープン時ページ情報
	const [openInfo, setOpenInfo] = useState<PageInfo>(new PageInfo());
	// 有効か
	const [enable, setEnable] = useState<boolean>(false);
	// 保存回数
	const [saveCount, setSaveCount] = useState<number>(0);
	// パスワード編集ダイアログを開くか
	const [isPasswordEditOpen, setIsPasswordEditOpen] = useState<boolean>(false);
	// メッセージ
	const [message, setMessage] = useState<MessageInfo | null>(null);
	// 確認メッセージ
	const [confirmMessage, setConfirmMessage] = useState<ConfirmMessageInfo | null>(null);
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// 検証フォーム
	const form = useRef<ValidatorForm | null>(null);
	// history
	const history = useHistory();
	// 郵便番号
	const [address, loading, error] = usePostalJp(pageInfo.postalCode, (pageInfo.postalCode.length >= 7));

	// レンダリング後フック
	useEffect(() => {
		void getRecord();
	}, [saveCount]);

	// レンダリング後フック
	useEffect(() => {
		if (params.id && pageInfo.isEdit && pageInfo.isChanged(openInfo)) {	// パス顧客IDがあり、入力内容が変化しているとき
			Cookies.set('tmp', pageInfo.toJson());
		}
	}, [pageInfo]);

	// レコードを取得する
	const getRecord = async () => {
		try {
			const res = (params.id !== undefined)
				? await new SystemApi().getCustomer(Number(params.id))
				: await new SystemApi().getPersonal();
			if (Cookies.get('phrase')) {	// ログイン権限があり、パスフレーズが設定されているとき
				history.push('/check');
			}
			const p = PageInfo.fromCustomer(res.data);
			setOpenInfo(p);
			if (saveCount > 0 || !pageInfo.enable) {	// オープン時の一時保存からの復帰ではないとき
				setPageInfo(p);
			}
			setEnable(true);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err), true);
		}
	};

	// 保存する
	const save = async () => {
		const isValid = await form.current?.isFormValid(false);
		if (!isValid) {	// 入力エラーがあるとき
			appFuncs.setErrorMessage("入力エラーがあります。");
			return;
		}
		try {
			await new SystemApi().saveCustomer(pageInfo.customerId, pageInfo.toCustomer());
			setSaveCount((saveCount) => saveCount + 1);
			Cookies.remove('tmp');
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 初期パスワードを発行する
	const createPassword = () => {
		const msg = "仮パスワードを発行すると、顧客に仮パスワード発行メールが送信されます。";
		setConfirmMessage({ message: msg, onSubmit: onCreatePasswordConfirmSubmit });
	};

	// 初期パスワード発行確認を承認したときに呼ばれる
	const onCreatePasswordConfirmSubmit = async () => {
		setConfirmMessage(null);
		try {
			const res = await new SystemApi().createInitialPassword({ customerId: pageInfo.customerId });
			setMessage({ title: "仮パスワード発行", message: `仮パスワードは「${res.data.password!}」です。有効期限は${DateUtil.toString(res.data.expireDate!)}です。` });
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 入力内容が変更された際に呼ばれる
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const split = event.target.name.split('-');
		const name = split[0] as keyof PageInfo;
		if (name == 'name' || name == 'ruby' || name == 'nickname' || name == 'remark'
			|| name == 'postalCode' || name == 'address' || name == 'birthday' || name == 'telephone'
			|| name == 'height' || name == 'weight' || name == 'illness' || name == 'medicine'
			|| name == 'pregnancy' || name == 'injury' || name == 'request') {	// 入力欄のとき
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				if (name == 'height' || name == 'weight') {	// 実数入力制限のとき
					p[name] = StringUtil.filterFloat(value);
				} else if (name == 'postalCode') {	// 郵便番号入力制限のとき
					p[name] = StringUtil.filterPostalCode(value);
				} else if (name == 'telephone') {	// 電話番号入力制限のとき
					p[name] = StringUtil.filterTelephone(value);
				} else {
					p[name] = value;
				}
				return p;
			});
		} else if (name == 'isEdit' || name == 'isEditDetail') {	// スイッチのとき
			if (!event.target.checked) {	// スイッチをOFFにするとき
				if (name == 'isEdit') {	// 編集モードをOFFにするとき
					if (pageInfo.isChanged(openInfo)) {	// 入力内容が変化しているとき
						openConfirm(name);
						return;
					}
					Cookies.remove('tmp');
				} else {	// 詳細編集モードをOFFにするとき
					if (pageInfo.isDetailChanged(openInfo)) {	// 詳細の入力内容が変化しているとき
						openConfirm(name);
						return;
					}
				}
			}
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p[name] = event.target.checked;
				return p;
			});
		} else if (name == 'infoFlag') {	// ラジオボタンのとき
			const type = Number(split[1]);
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p.infoFlag = StringUtil.setFlag(p.infoFlag, type, value, InfoFlagType);
				return p;
			});
		}
	};

	// パスワード編集ダイアログを開く
	const openPasswordEdit = () => {
		setIsPasswordEditOpen(true);
	};

	// パスワード編集ダイアログを閉じる
	const closePasswordEdit = (isSubmitted: boolean) => {
		setIsPasswordEditOpen(false);
	};

	// 編集モード切り替え確認ダイアログを開く
	const openConfirm = (name: string) => {
		const msg = (name == 'isEdit')
			? "変更内容が破棄されます。編集モードをOFFにしますか？\n（変更内容を保存したい場合は、保存ボタンを押してください。）"
			: "一部の変更内容が破棄されます。詳細情報編集モードをOFFにしますか？\n（変更内容を保存したい場合は、保存ボタンを押した後に詳細情報編集モードをOFFにしてください。）";
		setConfirmMessage({ message: msg, onSubmit: onChangeModeConfirmSubmit });
	};

	// 編集モード切り替え確認を承認したときに呼ばれる
	const onChangeModeConfirmSubmit = () => {
		if (confirmMessage?.message.indexOf("詳細情報") != -1) {	// 詳細情報編集モードをOFFにするとき
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p.copyOnlyDetail(openInfo);
				p.isEditDetail = false;
				return p;
			});
		} else {	// 編集モードをOFFにするとき
			Cookies.remove('tmp');
			setPageInfo(openInfo);
		}
		setConfirmMessage(null);
	};

	// 確認メッセージを閉じる
	const closeConfirm = () => {
		setConfirmMessage(null);
	};

	// メッセージを閉じる
	const closeMessage = () => {
		setMessage(null);
	};

	// 情報フラグの値を取得する
	const getInfoFlagValue = (type: InfoFlagType): string => {
		return StringUtil.getFlag(pageInfo.infoFlag, type, InfoFlagType);
	};

	// 郵便番号から変換された住所
	const addressSearched = pageInfo.address || (address ? [
		address.prefecture,
		address.address1,
		address.address2,
		address.address3,
		address.address4
	].join("") : "");

	// 描画
	const isAdminAll = appFuncs.checkPermission(PermissionValueType.AdminAll);
	const canWritePersonal = appFuncs.checkPermission(PermissionValueType.PersonalWrite);
	const edit = pageInfo.isEdit;
	const editDetail = edit && pageInfo.isEditDetail;
	const classes = useStyles();
	return (
		<Pane title={isAdminAll ? "顧客情報" : "登録情報"} enableContent={enable}>
			<ValidatorForm className={classes.form} autoComplete='off' onSubmit={() => { return; }} ref={form}>
				<Grid container justifyContent='space-evenly' alignItems='flex-start' direction='row-reverse'>
					<Grid item className={classes.switchBox}>
						{(isAdminAll || canWritePersonal) && <div>
							<FormControlLabel label={`編集モード${edit ? "ON" : "OFF"}`}
								control={<Switch checked={edit} name='isEdit' color='primary' onChange={onInputChange} />} />
							{isAdminAll && edit && <FormControlLabel label={`詳細情報編集モード${editDetail ? "ON" : "OFF"}`}
								control={<Switch checked={editDetail} name='isEditDetail'
									color='primary' onChange={onInputChange} />} />}
						</div>}
					</Grid>
					<Grid item className={classes.box}>
						{edit
							? <>
								<InputField label="ふりがな" name='ruby' width='200px' required={true}
									value={pageInfo.ruby} onChange={onInputChange} maxLength={50} />
								<div className={classes.name}>
									<InputField label="名前" name='name' width='200px' required={true}
										value={pageInfo.name} onChange={onInputChange} maxLength={50} />
									<InputField label="ニックネーム" name='nickname' width='200px'
										value={pageInfo.nickname} onChange={onInputChange} maxLength={50} placeholder="未入力可" />
								</div>
							</>
							: <div className={classes.nameBox}>
								<span className={classes.ruby}>{pageInfo.ruby}</span>
								<div className={classes.name}>
									<span>{pageInfo.name}</span>
									{pageInfo.nickname && <span className={classes.gray}>&ensp;{pageInfo.nickname}</span>}
								</div>
							</div>}
						{isAdminAll && <div className={classes.remark}>
							<InputField label="メモ" name='remark' type={pageInfo.isEdit ? 'text' : 'label'}
								width='700px' rows={4} maxLength={1000}
								value={pageInfo.remark} onChange={onInputChange} />
						</div>}
					</Grid>
				</Grid>
				<Grid container justifyContent='space-evenly' alignItems='flex-start'>
					{edit
						? <Grid item className={classes.box}>
							<InputField label="誕生日" name='birthday' type='date' width='200px' required={true}
								value={pageInfo.birthday} onChange={onInputChange} />
							<InputField label="郵便番号" name='postalCode' width='100px'
								value={pageInfo.postalCode} onChange={onInputChange} maxLength={8} placeholder="未入力可" />
							<InputField label="住所" name='address' width='300px' required={true}
								value={addressSearched} onChange={onInputChange} maxLength={200} />
							<InputField label="電話番号" name='telephone' width='200px'
								value={pageInfo.telephone} onChange={onInputChange} maxLength={20} placeholder="未入力可" />
						</Grid>
						: <Grid item className={classes.box}>
							<table>
								<tbody>
									<tr>
										<td className={classes.label}>次回予約日 :</td>
										<td>
											{(pageInfo.nextTreatmentDate == "") ? "未定"
												: <span className={classes.breakText}>
													<span>{DateUtil.toDateString(pageInfo.nextTreatmentDate)}</span>
													<span className={classes.gray}>
														<span>&ensp;{DateUtil.toTimeString(pageInfo.nextTreatmentDate)}</span>
														{pageInfo.nextTreatmentTime
															&& <span>〜{DateUtil.toTimeString(DateUtil.addSeconds(
																pageInfo.nextTreatmentDate, pageInfo.nextTreatmentTime * 60))}</span>}
													</span>
												</span>}
										</td>
									</tr>
									{isAdminAll && <tr>
										<td className={classes.label}>最終施術日 :</td>
										<td>
											{(pageInfo.lastTreatmentDate == "") ? "なし"
												: <>
													<span>{DateUtil.toDateString(pageInfo.lastTreatmentDate)}</span>
													<span className={classes.gray}>
														<span>&ensp;{DateUtil.toTimeString(pageInfo.lastTreatmentDate)}</span>
														{pageInfo.lastTreatmentTime
															&& <span>〜{DateUtil.toTimeString(DateUtil.addSeconds(
																pageInfo.lastTreatmentDate, pageInfo.lastTreatmentTime * 60))}</span>}
													</span>
												</>}
										</td>
									</tr>}
									{isAdminAll && <tr>
										<td className={classes.label}>施術一覧 :</td>
										<td>
											<Link to={`/treatments?search=${pageInfo.name}`}>表示</Link>
										</td>
									</tr>}
									<tr>
										<td className={classes.label}>測定結果 :</td>
										<td>
											<Link to={isAdminAll ? `/measurements?search=${pageInfo.name}` : '/measurements'}>表示</Link>
										</td>
									</tr>
									<tr>
										<td className={classes.label}>誕生日 :</td>
										<td>{pageInfo.birthday}</td>
									</tr>
									<tr>
										<td className={classes.label}>年齢 :</td>
										<td>{DateUtil.convertToAge(pageInfo.birthday)}才</td>
									</tr>
									<tr>
										<td className={classes.label}>住所 :</td>
										{pageInfo.postalCode
											? <td>〒{pageInfo.postalCode}</td> : <td className={classes.breakText}>{pageInfo.address}</td>}
									</tr>
									{(pageInfo.postalCode != "") && <tr>
										<td></td>
										<td>{pageInfo.address}</td>
									</tr>}
									{(pageInfo.telephone != "") && <tr>
										<td className={classes.label}>電話番号 :</td>
										<td>{pageInfo.telephone}</td>
									</tr>}
									<tr>
										<td className={classes.label}>メールアドレス :</td>
										<td className={classes.breakText}>
											{CustomerAuxil.isMailAddressInvalid(pageInfo.mailAddress)
												? "未設定" : pageInfo.mailAddress}
										</td>
									</tr>
									<tr>
										<td className={classes.label}>パスワード :</td>
										<td>
											********
											&ensp;{isAdminAll
												? <Button className={classes.buttonSmall}
													variant='outlined' color='primary' size='small'
													onClick={createPassword}>仮パスワード発行</Button>
												: <Anchor onClick={openPasswordEdit}>編集</Anchor>}
										</td>
									</tr>
									{isAdminAll && <tr>
										<td className={classes.label}>会員タイプ :</td>
										<td>
											{PermissionUtil.getString(pageInfo.permissionFlag, PermissionType.Reservation)}
										</td>
									</tr>}
								</tbody>
							</table>
						</Grid>}
					<Grid item className={classes.box}>
						{isAdminAll && <>
							<RadioField label="今までにかかった病気はありますか？"
								name={`infoFlag-${InfoFlagType.IsIll}`}
								value={getInfoFlagValue(InfoFlagType.IsIll)}
								isRead={!editDetail}
								radioButtons={[
									{
										label: "はい", value: 'I', input: {
											name: 'illness', value: pageInfo.illness, required: true,
											maxLength: 200, placeholder: "心臓に関する病気 etc."
										}
									},
									{ label: "いいえ", value: 'n' },
									{ label: "未回答", value: StringUtil.FLAG_NONE_VALUE },
								]}
								onChange={onInputChange} />
							<RadioField label="現在服用している薬はありますか？"
								name={`infoFlag-${InfoFlagType.IsTakingMedicine}`}
								value={getInfoFlagValue(InfoFlagType.IsTakingMedicine)}
								isRead={!editDetail}
								radioButtons={[
									{
										label: "はい", value: 'M', input: {
											name: 'medicine', value: pageInfo.medicine, required: true, maxLength: 200
										}
									},
									{ label: "いいえ", value: 'n' },
									{ label: "未回答", value: StringUtil.FLAG_NONE_VALUE },
								]}
								onChange={onInputChange} />
							<RadioField label="妊娠されていますか？"
								name={`infoFlag-${InfoFlagType.IsPregnant}`}
								value={getInfoFlagValue(InfoFlagType.IsPregnant)}
								isRead={!editDetail}
								radioButtons={[
									{
										label: "はい", value: 'P', input: {
											name: 'pregnancy', value: pageInfo.pregnancy, required: true,
											maxLength: 200, placeholder: "妊娠〇週目"
										}
									},
									{ label: "いいえ", value: 'n' },
									{ label: "未回答", value: StringUtil.FLAG_NONE_VALUE },
								]}
								onChange={onInputChange} />
							<RadioField label="触れられたくない部位や、怪我をしている部位はありますか？"
								name={`infoFlag-${InfoFlagType.IsInjured}`}
								value={getInfoFlagValue(InfoFlagType.IsInjured)}
								isRead={!editDetail}
								radioButtons={[
									{
										label: "はい", value: 'I', input: {
											name: 'injury', value: pageInfo.injury, required: true, maxLength: 200
										}
									},
									{ label: "いいえ", value: 'n' },
									{ label: "未回答", value: StringUtil.FLAG_NONE_VALUE },
								]}
								onChange={onInputChange} />
							<RadioField label="「別人級美人エステ」について知っていますか？"
								name={`infoFlag-${InfoFlagType.IsKnowingEsthetic}`}
								value={getInfoFlagValue(InfoFlagType.IsKnowingEsthetic)}
								isRead={!editDetail}
								radioButtons={[
									{ label: "はい", value: 'K' },
									{ label: "いいえ", value: 'n' },
									{ label: "未回答", value: StringUtil.FLAG_NONE_VALUE },
								]}
								onChange={onInputChange} />
							<div className={classes.inputLabel}>
								<FormLabel className={classes.legend} component="legend">このエステで一番求めるものは何ですか？</FormLabel>
								{editDetail
									? <InputField variant='standard' name='request' width='300px' padding='0'
										required={true} value={pageInfo.request} onChange={onInputChange} maxLength={200} />
									: <span>{pageInfo.request || "未回答"}</span>}
							</div>
							<RadioField label="ご自身の希望のプランをお答えください"
								name={`infoFlag-${InfoFlagType.Plan}`}
								value={getInfoFlagValue(InfoFlagType.Plan)}
								isRead={!editDetail}
								radioButtons={[
									{ label: "ゆるっと6ヵ月プラン", value: 'L' },
									{ label: "ぼちぼち3ヵ月プラン", value: 'M' },
									{ label: "しっかり1ヵ月プラン", value: 'H' },
									{ label: "未回答", value: StringUtil.FLAG_NONE_VALUE },
								]}
								onChange={onInputChange} />
							<RadioField label="ご自宅でのケアを考えていますか？"
								name={`infoFlag-${InfoFlagType.IsCaring}`}
								value={getInfoFlagValue(InfoFlagType.IsCaring)}
								isRead={!editDetail}
								radioButtons={[
									{ label: "はい", value: 'C' },
									{ label: "いいえ", value: 'n' },
									{ label: "未回答", value: StringUtil.FLAG_NONE_VALUE },
								]}
								onChange={onInputChange} />
							{editDetail
								? <>
									<InputField label="身長" name='height' width='100px'
										value={pageInfo.height} endAdornment="cm" onChange={onInputChange} maxLength={5}
									/>
									<InputField label="体重" name='weight' width='100px'
										value={pageInfo.weight} endAdornment="kg" onChange={onInputChange} maxLength={4}
									/>
								</>
								: <table>
									<tbody>
										<tr>
											<td className={classes.label}>身長 :</td>
											<td>{pageInfo.height ? `${pageInfo.height} cm` : "未入力"}</td>
										</tr>
										<tr>
											<td className={classes.label}>体重 :</td>
											<td>{pageInfo.weight ? `${pageInfo.weight} kg` : "未入力"}</td>
										</tr>
									</tbody>
								</table>}
						</>}
					</Grid>
				</Grid>
				{edit && <Button className={classes.button} type='submit' variant='contained'
					color='primary' disabled={!pageInfo.enable || !pageInfo.isChanged(openInfo)} onClick={save}>
					保存
				</Button>}
			</ValidatorForm>
			{/** メッセージ */}
			{message
				&& <MessageDialog title={message.title} message={message.message} onClose={closeMessage} />}
			{/** 確認メッセージ */}
			{confirmMessage
				&& <MessageDialog title="確認" message={confirmMessage.message} buttonType='okCancel'
					onClose={closeConfirm} onSubmit={confirmMessage.onSubmit} />}
			{/** パスワード編集ダイアログ */}
			{isPasswordEditOpen
				&& <PasswordEdit customerId={pageInfo.customerId} onClose={closePasswordEdit} />}
		</Pane>
	);
}
