import { Button, createStyles, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import MessageDialog from '~/component/common/MessageDialog';
import ScrollDialog from '~/component/common/ScrollDialog';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	copyButtonArea: {
		textAlign: 'center',
	},
}));

// プロパティ
type Props = {
	// クローズイベント
	onClose: () => void,
};

/** シェアダイアログ */
export default function Share(props: Props) {
	// 確認メッセージ
	const [message, setMessage] = useState<string>("");

	// コピーが完了した際に呼ばれる
	const onCopy = () => {
		setMessage("リンクをコピーしました。");
	};

	// 描画
	const shareUrl = window.location.href;
	//const title = "サロン・ド・グロリア管理サイト | Gloria System";
	const classes = useStyles();
	return (
		<ScrollDialog title="共有"
			buttons={[
				{ label: "閉じる", variant: 'outlined', color: 'default', onClick: props.onClose },
			]}
			isOutsideClose={true} onClose={props.onClose}>
			{/*<FacebookShareButton url={shareUrl} quote={title}>
				<FacebookIcon size={48} round />
			</FacebookShareButton>
			<TwitterShareButton url={shareUrl} title={title}>
				<TwitterIcon size={48} round />
			</TwitterShareButton>
			<EmailShareButton url={shareUrl} subject={title} body='body'>
				<EmailIcon size={48} round />
			</EmailShareButton>*/}
			<CopyToClipboard text={shareUrl} onCopy={onCopy}>
				<div className={classes.copyButtonArea}>
					<Button variant='contained' color='primary'>
						リンクをコピー
					</Button>
				</div>
			</CopyToClipboard>
			{/** コピー完了メッセージ */}
			<MessageDialog title="" message={message} onClose={props.onClose} />
		</ScrollDialog>
	);
}