import { createStyles, makeStyles } from '@material-ui/core';
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BaseApi } from "~/axios";
import { AppContext } from '~/component/base/App';
import BackgroundImage from "../../img/background.jpg";

// スタイル
const useStyles = makeStyles(() => createStyles({
	background: {
		display: 'block',
		height: '100%',
		backgroundSize: 'cover',
		backgroundImage: `url(${BackgroundImage})`,
		backgroundColor: 'rgba(255,255,255,0.8)',
		backgroundBlendMode: 'lighten',
	},
}));

/** ログアウト画面 */
export default function Logout(): JSX.Element {
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// history
	const history = useHistory();

	// レンダリング後フック
	useEffect(() => {
		void logout();
	}, []);

	// ログアウトする
	const logout = async () => {
		try {
			await new BaseApi().logout();
		} catch {
			// エラーを握りつぶす
		} finally {
			appFuncs.updatePermission();
			history.push('/');
		}
	};

	const classes = useStyles();
	return (
		<div className={classes.background}></div>
	);
}
