import { Button, createStyles, FormControlLabel, Grid, Link, makeStyles, Switch, Theme, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SystemApi, Treatment } from '~/axios';
import { AppContext } from '~/component/base/App';
import Pane from '~/component/common/Pane';
import VirtualizedTable, { ColumnInfo } from '~/component/common/VirtualizedTable';
import MeasurementEdit from '~/component/system/MeasurementEdit';
import TreatmentEdit from '~/component/system/TreatmentEdit';
import { PermissionValueType } from '~/constant/PermissionValueType';
import { TreatmentStateType } from '~/constant/TreatmentStateType';
import { CustomerAuxil } from '~/model/CustomerAuxil';
import { DateUtil } from '~/util/DateUtil';
import { ErrorUtil } from '~/util/ErrorUtil';
import { ObjectUtil } from '~/util/ObjectUtil';

// TODO: Colorクラスに定数化
const pink = '#ff3366';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	switchBox: {
		maxWidth: '100%',
		width: '300px',
		textAlign: 'right',
		'& div': {
			display: 'inline-grid',
			width: '225px',
		},
		'& .MuiTypography-body1': {
			fontSize: '0.8rem',
		},
	},
	noWrap: {
		whiteSpace: 'nowrap',
	},
	state: {
		fontWeight: 'bold',
	},
	label: {
		paddingLeft: theme.spacing(3),
	},
	pink: {
		color: pink,
	},
	gray: {
		color: 'gray',
	},
}));

/** 施術一覧画面 */
export default function TreatmentList(): JSX.Element {
	// 行情報
	const [treatedRows, setTreatedRows] = useState<Treatment[]>([]);
	// 有効か
	const [enable, setEnable] = useState<boolean>(false);
	// 予約のみ表示するか（個人管理権限用）
	const [isOnlyReserved, setIsOnlyReserved] = useState<boolean>(true);
	// 検索値
	const [searchValue, setSearchValue] = useState<string>("");
	// 保存回数
	const [saveCount, setSaveCount] = useState<number>(0);
	// 施術情報編集ダイアログ用施術ID
	const [treatmentId, setTreatmentId] = useState<number>(0);
	// 測定結果編集ダイアログ用施術ID
	const [measurementTreatmentId, setMeasurementTreatmentId] = useState<number>(0);
	// モバイルか
	const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// history
	const history = useHistory();
	// location
	const location = useLocation();

	// レンダリング後フック
	useEffect(() => {
		const params = new URLSearchParams(location.search);
		setSearchValue(params.get('search') ?? "");
		params.delete('search');
		history.push(location.pathname + `?${params.toString()}`);	// TODO: paramがないときも問題はないがあまり良くないのでUrlUtilにメソッド化。
		// TODO: searchのこの機能をPaneに移植
	}, []);

	// レンダリング後フック
	useEffect(() => {
		void getRecord();
	}, [saveCount]);

	const isAdminAll = appFuncs.checkPermission(PermissionValueType.AdminAll);

	// レコードを取得する
	const getRecord = async () => {
		try {
			const res = await new SystemApi().getTreatmentList('treatments');
			if (Cookies.get('phrase')) {	// ログイン権限があり、パスフレーズが設定されているとき
				history.push('/check');
			}
			const treatments = res.data;
			ObjectUtil.sort(treatments, 'treatmentDate', 'desc');
			setTreatedRows(treatments);
			setEnable(true);
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err), true);
		}
	};

	// 入力内容が変更された際に呼ばれる
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		if (name == 'isOnlyReserved') {	// スイッチのとき
			setIsOnlyReserved(!event.target.checked);
		}
	};

	// フィルター判定
	const filterPredicate = (value: Treatment, index: number, array: Treatment[]): boolean => {
		if (!isAdminAll && isOnlyReserved) {	// 個人管理権限で予約のみ表示のとき
			if (value.state == TreatmentStateType.Finished) {	// 施術済みのとき
				return false;
			}
		}
		if (!searchValue) {	// 検索値がないとき
			return true;
		}
		return (value.treatmentDate && DateUtil.toDateString(value.treatmentDate).indexOf(searchValue.replace(/-/g, '/')) != -1
			|| (value.state?.indexOf(searchValue) ?? -1) != -1
			|| (value.reservation?.customer?.name?.indexOf(searchValue) ?? -1) != -1
			|| (value.reservation?.customer?.ruby?.indexOf(searchValue) ?? -1) != -1
			|| (value.reservation?.customer?.nickname?.indexOf(searchValue) ?? -1) != -1
			|| (value.place?.placeName?.indexOf(searchValue) ?? -1) != -1
			|| (value.tea?.indexOf(searchValue) ?? -1) != -1
			|| (value.aroma?.indexOf(searchValue) ?? -1) != -1);
	};

	// 行を押したときに呼ばれる
	const onRowClick = (rowData: Treatment) => {
		setTreatmentId(rowData.treatmentId!);
	};

	// 施術情報編集ダイアログを閉じる
	const closeTreatmentEdit = (isSaved: boolean) => {
		setTreatmentId(0);
		if (isSaved) {	// 保存済のとき
			setSaveCount((saveCount) => saveCount + 1);
		}
	};

	// 測定結果編集ダイアログを開く
	const openMeasurementEdit = (treatmentId: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		setMeasurementTreatmentId(treatmentId);
	};

	// 測定結果編集ダイアログを閉じる
	const closeMeasurementEdit = (isSaved: boolean) => {
		setMeasurementTreatmentId(0);
		if (isSaved) {	// 保存済のとき
			setSaveCount((saveCount) => saveCount + 1);
		}
	};

	// 状態を描画する
	const renderState = (rowData: Treatment, dataKey?: keyof Treatment): React.ReactNode => {
		const className = clsx(classes.state, {
			[classes.pink]: (rowData.state == TreatmentStateType.ReservedTemporary)
		});
		return (
			<div>
				<span className={className}>
					{TreatmentStateType.getString(rowData.state, isAdminAll ? 'treatment' : 'reservation')}
				</span>
			</div>
		);
	};

	// 日時を描画する
	const renderDate = (rowData: Treatment, dataKey?: keyof Treatment): React.ReactNode => {
		return (
			<div>
				<span className={classes.noWrap}>{DateUtil.toDateString(rowData.treatmentDate!)}</span>
				&ensp;<span className={classes.gray}>{DateUtil.toTimeString(rowData.treatmentDate!)}</span>
			</div>
		);
	};

	// 名前を描画する
	const renderName = (rowData: Treatment, dataKey?: keyof Treatment): React.ReactNode => {
		return (
			<div>
				<span className={classes.noWrap}>{rowData.reservation?.customer?.name ?? ""}</span>
				{rowData.reservation?.customer?.nickname && <>
					&ensp;<span className={clsx(classes.noWrap, classes.gray)}>{rowData.reservation.customer.nickname}</span>
				</>}
			</div>
		);
	};

	// 場所を描画する
	const renderPlace = (rowData: Treatment, dataKey?: keyof Treatment): React.ReactNode => {
		return rowData.place?.placeName
			? <div><span>{rowData.place.placeName}</span></div>
			: <div><span className={classes.pink}>未入力</span></div>;
	};

	// 測定結果入力・編集ボタンを描画する
	const renderMeasurementButton = (rowData: Treatment, dataKey?: keyof Treatment): React.ReactNode => {
		let label: string;
		if (isAdminAll) {	// 全体管理権限があるとき
			if (rowData.measurementCount === null) {	// 測定結果なしのとき
				return <span className={classes.label}>ー</span>;
			} else if (rowData.measurementCount == 2) {	// 施術前後の測定結果があるとき
				label = "表示";
			} else {	// 測定結果が入力完了していないとき
				if (new Date(rowData.treatmentDate!).getTime() >= DateUtil.getMidnightTime(new Date(), 1)) {	// 翌日以降の施術のとき
					return "";
				} else if (CustomerAuxil.isTemporaryCustomer(rowData.reservation!.customerId!)) {	// 仮顧客のとき
					return "";
				}
				label = "入力";
			}
		} else {
			if (rowData.measurementCount != 2) {	// 前後の測定結果がないとき
				return "";
			}
			label = "表示";
		}
		return (
			<Button variant='outlined' color='primary'
				onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
					openMeasurementEdit(rowData.treatmentId!, event)}>
				{label}
			</Button>
		);
	};

	// テーブルを描画する
	const renderTable = (): React.ReactNode => {
		const rate = (isAdminAll && isMobile) ? 1.4 : 1;
		const columns: ColumnInfo<Treatment>[] = [
			{ label: '施術日', dataKey: 'treatmentDate', width: 200 * rate, renderCellData: renderDate }
		];
		if (isAdminAll) {
			columns.push({ label: '名前', width: 200 * rate, renderCellData: renderName });
		}
		if (!isAdminAll || !isMobile) {
			columns.push({ label: '場所', width: 200, renderCellData: renderPlace });
			columns.unshift({ label: '', dataKey: 'state', width: 100, renderCellData: renderState });
		}
		if (isAdminAll) {
			columns.push({ label: 'お茶', dataKey: 'tea', width: 200, isSortHidden: true });
			columns.push({ label: 'アロマ', dataKey: 'aroma', width: 200, isSortHidden: true });
			if (!isMobile) {
				columns.push({ label: '測定結果', width: 150, renderCellData: renderMeasurementButton });
			}
		}
		return (
			<VirtualizedTable
				data={filteredTreatedRows}
				columns={columns}
				defaultSortColumnDataKey={isAdminAll ? 'state' : 'treatmentDate'}
				defaultSortOrder={isAdminAll ? 'desc' : 'asc'}
				onRowClick={onRowClick} />
		);
	};

	// 通知メッセージを描画する
	const renderMessage = (): React.ReactNode => {
		const today = DateUtil.getMidnightTime(new Date());
		if (treatedRows.findIndex((t) => DateUtil.getMidnightTime(t.treatmentDate!) == today) == -1) {	// 本日の施術がないとき
			return '';
		}
		return (
			<div>
				<span>本日の施術があります。</span>
				<Link onClick={() => setSearchValue(DateUtil.toDateString(today))}>本日の施術を表示</Link>
			</div>
		);
	};

	// 描画
	const filteredTreatedRows = treatedRows.filter(filterPredicate);
	const classes = useStyles();
	return (
		<Pane title={isAdminAll ? "施術一覧" : "予約一覧"} enableContent={enable}
			searchValue={searchValue} onSearchChange={setSearchValue}
			headerElement={isAdminAll ? renderMessage()
				: <Grid container justifyContent='flex-end' alignItems='flex-end'>
					<Grid item className={classes.switchBox}>
						<FormControlLabel label="施術済みも表示"
							control={<Switch checked={!isOnlyReserved} name='isOnlyReserved'
								color='primary' onChange={onInputChange} />} />
					</Grid>
				</Grid>}
			rightElementOfSearch={<div>件数: {filteredTreatedRows.length}</div>}>
			{renderTable()}
			{/** 施術情報編集ダイアログ */}
			{(treatmentId != 0)
				&& <TreatmentEdit treatmentId={treatmentId} onClose={closeTreatmentEdit} />}
			{/** 測定結果編集ダイアログ */}
			{(measurementTreatmentId != 0)
				&& <MeasurementEdit treatmentId={measurementTreatmentId} onClose={closeMeasurementEdit} />}
		</Pane>
	);
}