import '@fullcalendar/react';
import { createStyles, makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import ContractList from '~/component/admin/ContractList';
import ScheduleReleaseSetting from '~/component/admin/ScheduleReleaseSetting';
import { AppContext } from '~/component/base/App';
import CheckPhrase from '~/component/base/CheckPhrase';
import Home from "~/component/base/Home";
import Login from "~/component/base/Login";
import Logout from '~/component/base/Logout';
import CustomerEdit from "~/component/system/CustomerEdit";
import CustomerList from '~/component/system/CustomerList';
import MeasurementList from "~/component/system/MeasurementList";
import Register from '~/component/system/Register';
import Schedule from '~/component/system/Schedule';
import TreatmentList from '~/component/system/TreatmentList';
import { PermissionValueType } from '~/constant/PermissionValueType';

// 設定値
const topBarHeight = '56px';
const bottomNavHeight = '56px';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	content: {
		width: '100%',
		minHeight: '100vh',
		boxSizing: 'border-box',
		textAlign: 'center',
		backgroundColor: theme.palette.background.default,
		paddingTop: topBarHeight,
		[theme.breakpoints.up('md')]: {
			borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
		},
		[theme.breakpoints.down('sm')]: {
			paddingBottom: bottomNavHeight,
		},
	}
}));

/** メイン画面 */
export default function MainPane(): JSX.Element {
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// location
	const location = useLocation();
	// history
	const history = useHistory();

	// 描画
	const classes = useStyles();
	if (["/", "/login"].indexOf(location.pathname) == -1
		&& !appFuncs.checkPermission(PermissionValueType.AdminPersonal)) {	// ログイン後画面でログイン権限がないとき
		history.push("/login");
	}
	return (
		<main className={classes.content}>
			<Switch>
				{/** 顧客情報系画面 */}
				<Route exact path="/register" component={Register} />
				<Route exact path="/customers" component={CustomerList} />
				<Route exact path="/customers/:id" component={CustomerEdit} />
				<Route exact path="/personal" component={CustomerEdit} />
				{/** 施術情報系画面 */}
				<Route exact path="/schedules" component={Schedule} />				{/** Aスケジュール、P空き状況 */}
				<Route exact path="/treatments" component={TreatmentList} />		{/** A施術一覧 */}
				<Route exact path="/reservations" component={TreatmentList} />		{/** P予約一覧（予約|施術済み） */}
				<Route exact path="/measurements" component={MeasurementList} />	{/** AP測定結果 */}
				{/** 施術補助系画面 */}
				<Route exact path="/contracts" component={ContractList} />			{/** A契約一覧 */}
				<Route exact path="/releases" component={ScheduleReleaseSetting} />	{/** Aスケジュール公開設定 */}
				{/** その他画面 */}
				<Route exact path="/check" component={CheckPhrase} />
				<Route exact path="/login" component={Login} />
				<Route exact path="/logout" component={Logout} />
				<Route path="/" component={Home} />
			</Switch>
		</main>
	);
}
