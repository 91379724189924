import { Button, createStyles, Divider, makeStyles } from '@material-ui/core';
import Anchor from '@material-ui/core/Link';
import clone from "clone";
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useHistory, useLocation } from "react-router-dom";
import { BaseApi, LoginInfo } from "~/axios";
import { AppContext } from '~/component/base/App';
import PasswordResetRequest from '~/component/base/PasswordResetRequest';
import InputField from "~/component/common/InputField";
import MessageDialog from '~/component/common/MessageDialog';
import PasswordEdit from '~/component/system/PasswordEdit';
import { PermissionValueType } from '~/constant/PermissionValueType';
import { ErrorUtil } from '~/util/ErrorUtil';
import { StringUtil } from "~/util/StringUtil";
import background from "../../img/background.jpg";

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		backgroundSize: 'cover',
		backgroundImage: `url(${background})`,
		backgroundColor: 'rgba(255,255,255,0.8)',
		backgroundBlendMode: 'lighten',
	},
	box: {
		border: '1px solid rgba(0, 0, 0, 0.12)',
		backgroundColor: 'white',
	},
	title: {
		marginBottom: '0.3em',
	},
	divider: {
		marginBottom: theme.spacing(2),
	},
	button: {
		margin: theme.spacing(3),
	},
}));

// ページ情報
class PageInfo {
	// メールアドレス
	mailAddress = "";
	// パスワード
	password = "";

	/** ログイン用LoginInfoへ変換する */
	toLoginInfo(): LoginInfo {
		const ret: LoginInfo = {};
		ret.mailAddress = this.mailAddress;
		ret.password = this.password;
		return ret;
	}
}

// パスワード編集情報
type PasswordEditInfo = {
	// 顧客ID
	customerId: number;
	// キー
	key?: string;
	// 現在のパスワード
	currentPassword?: string;
};

/** ログイン画面 */
export default function Login(): JSX.Element {
	// ページ情報
	const [pageInfo, setPageInfo] = useState<PageInfo>(new PageInfo());
	// パスワードリセット要求ダイアログを開くか
	const [isRequestOpen, setIsRequestOpen] = useState<boolean>(false);
	// パスワード編集ダイアログ情報
	const [passwordEditInfo, setPasswordEditInfo] = useState<PasswordEditInfo | null>(null);
	// ログイン保持確認メッセージ
	const [confirmMessage, setConfirmMessage] = useState<string>("");
	// appFunctions
	const appFuncs = useContext(AppContext)!;
	// location
	const location = useLocation();
	// history
	const history = useHistory();

	// レンダリング後フック
	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const id = params.get('id') ? Number(params.get('id')) : NaN;
		const key = params.get('key') ?? "";
		const mailAddress = params.get('mailAddress') ?? "";
		if (!isNaN(id) && key) {	// パスワード再設定情報がクエリに指定されているとき
			setPasswordEditInfo({ customerId: id, key: key });
		} else {
			if (appFuncs.checkPermission(PermissionValueType.AdminPersonal)) {	// ログイン済のとき
				history.push('/');
			} else if (mailAddress) {	// メールアドレスがクエリに指定されているとき
				// TODO: 必要ならhistory.pushでURLを戻す。
				const p = new PageInfo();
				p.mailAddress = mailAddress;
				setPageInfo(p);
			}
		}
	}, []);

	// ログインする
	const login = async () => {
		try {
			const res = await new BaseApi().login(pageInfo.toLoginInfo());
			if (res.data?.customerId) {	// 初期パスワードによるログインのとき
				setPasswordEditInfo({ customerId: res.data.customerId, currentPassword: pageInfo.password });
				return;
			}
			appFuncs.updatePermission();
			Cookies.remove('phrase');
			setConfirmMessage("ログイン情報を保持しますか？");
		} catch (err) {
			appFuncs.setErrorMessage(ErrorUtil.getMessage(err));
		}
	};

	// 入力内容が変更された際に呼ばれる
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const name = event.target.name as keyof PageInfo;
		if (name == 'mailAddress' || name == 'password') {	// 半角英数入力制限のとき
			setPageInfo((pageInfo) => {
				const p = clone(pageInfo);
				p[name] = StringUtil.filterAscii(value);
				return p;
			});
		}
	};

	// ログイン情報保持するかの確認ダイアログが承認された際に呼ばれる
	const onConfirmSubmit = async () => {
		try {
			await new BaseApi().keepLoggedIn();
		} catch {
			// エラーを握りつぶす
			appFuncs.updatePermission();
		} finally {
			history.push('/');
		}
	};

	// ログイン情報保持するかの確認ダイアログを閉じる
	const closeConfirm = () => {
		history.push('/');
	};

	// パスワードリセット要求リンクが押された際に呼ばれる
	const onRequestLinkClick = () => {
		setIsRequestOpen(true);
	};

	// パスワードリセット要求ダイアログを閉じる
	const closeRequest = () => {
		setIsRequestOpen(false);
	};

	// パスワード編集ダイアログを閉じる
	const closePasswordReset = (isSubmitted: boolean) => {
		const hasKey = (passwordEditInfo?.key != null);
		setPasswordEditInfo(null);
		if (hasKey) {	// 再設定のとき
			history.push('/login');
		} else {	// 初期パスワード変更のとき
			if (isSubmitted) {	// ログインしたとき
				appFuncs.updatePermission();
				Cookies.remove('phrase');
				history.push('/');
			}
		}
	};

	// 描画 
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.box}>
				<h2 className={classes.title}>ログイン</h2>
				<Divider className={classes.divider} variant='middle' />
				<ValidatorForm autoComplete='off' onSubmit={login}>
					<InputField label="メールアドレス" name='mailAddress' width='300px'
						value={pageInfo.mailAddress} onChange={onInputChange} />
					<InputField label="パスワード" name='password' type='password' width='300px'
						value={pageInfo.password} onChange={onInputChange} />
					<div>
						<Anchor onClick={onRequestLinkClick}>パスワードを忘れた場合はこちら</Anchor>
					</div>
					<Button className={classes.button} type='submit' variant='contained' color='primary'>ログイン</Button>
				</ValidatorForm>
			</div>
			{/** パスワードリセット要求ダイアログ */}
			<MessageDialog title="確認" message={confirmMessage} buttonType='yesNo' onClose={closeConfirm} onSubmit={onConfirmSubmit} />
			{/** パスワードリセット要求ダイアログ */}
			{isRequestOpen && <PasswordResetRequest onClose={closeRequest} />}
			{/** パスワード編集ダイアログ */}
			{passwordEditInfo
				&& <PasswordEdit customerId={passwordEditInfo.customerId} passwordResetKey={passwordEditInfo.key}
					currentPassword={passwordEditInfo.currentPassword} onClose={closePasswordReset} />}
		</div>
	);
}
