import { CircularProgress, Container, createStyles, Divider, Grid, makeStyles, TextField } from '@material-ui/core';

// 設定値
const topBarHeight = '56px';
const bottomNavHeight = '56px';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	root: {
		position: 'relative',
		flex: '1 1 auto',
		height: '100%',
	},
	title: {
		marginBottom: '0.3em',
	},
	divider: {
		marginBottom: theme.spacing(2),
	},
	tableArea: {
		height: `calc(100vh - ${topBarHeight})`,
		[theme.breakpoints.down('sm')]: {
			height: `calc(100vh - ${topBarHeight} - ${bottomNavHeight})`,
		},
	},
	indicator: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		margin: 'auto',
	},
}));

// プロパティ
type Props = {
	/** タイトル */
	title: string,
	/** 中身が表示可能な状態か default=true */
	enableContent?: boolean,
	/** 検索値 */
	searchValue?: string,
	/** 検索値変化イベント */
	onSearchChange?: (value: string) => void,
	/** 上部の要素 検索ボックスより上に要素を表示したい場合に使用する */
	headerElement?: React.ReactNode,
	/** 検索ボックスの右の要素 */
	rightElementOfSearch?: React.ReactNode,
	/** ウェイトインジケータを表示するか default=false */
	isWaiting?: boolean,
	/** 子要素 */
	children?: React.ReactNode,
};

/** 画面 */
export default function Pane(props: Props): JSX.Element {
	// 入力内容が変更された際に呼ばれる
	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const value = event.target.value;
		if (name == 'search') {	// 検索欄のとき
			props.onSearchChange!(value);
		}
	};

	// 描画
	const isEnable = props.enableContent ?? true;
	const classes = useStyles();
	return (
		<Container className={classes.root}>
			<h2 className={classes.title}>{props.title}</h2>
			<Divider className={classes.divider} variant='middle' />
			{isEnable && <>
				{(props.headerElement != null) && props.headerElement}
				{(props.onSearchChange != null) ? <div className={classes.tableArea}>
					<Grid container justifyContent='space-between' alignItems='flex-end'>
						<Grid item>
							<table>
								<tbody>
									<tr>
										<td>検索 :</td>
										<td>
											<TextField name='search' variant='outlined' size='small'
												value={props.searchValue} onChange={onInputChange} />
										</td>
									</tr>
								</tbody>
							</table>
						</Grid>
						{(props.rightElementOfSearch != null) && <Grid item>
							{props.rightElementOfSearch}
						</Grid>}
					</Grid>
					{props.children}
				</div> : props.children}
			</>}
			{(props.isWaiting || !isEnable) && <CircularProgress className={classes.indicator} />}
		</Container>
	);
}