import { createStyles, Drawer, Hidden, List, makeStyles } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import EventIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import HomeIcon from '@material-ui/icons/Home';
import InputIcon from '@material-ui/icons/Input';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useContext } from 'react';
import { AppContext } from '~/component/base/App';
import ListButton from '~/component/common/ListButton';
import { Constant } from '~/constant/Constant';
import { PermissionValueType } from '~/constant/PermissionValueType';

const drawerWidth = '240px';

// スタイル
const useStyles = makeStyles((theme) => createStyles({
	drawerPaper: {
		width: drawerWidth,
		height: '100vh',
		[theme.breakpoints.up('md')]: {
			position: 'relative',
			border: 'none',
		}
	},
	nav: {
		position: 'fixed',
		width: drawerWidth,
	},
}));

// プロパティ
type Props = {
	// 開くか
	open: boolean,
	// クローズイベント
	onClose: () => void,
};

/** サイドナビゲーション */
export default function SideNav(props: Props) {
	// appFunctions
	const appFuncs = useContext(AppContext)!;

	// サロンホームページを開く
	const openHomepage = () => {
		props.onClose();
		window.open(Constant.HOMEPAGE, '_blank');
	};

	const isAllAdmin = appFuncs.checkPermission(PermissionValueType.AdminAll);
	const isPersonalAdmin = appFuncs.checkPermission(PermissionValueType.AdminPersonal) && !isAllAdmin;
	const canReserve = appFuncs.checkPermission(PermissionValueType.ReservationNormal);

	let nav: React.ReactNode;
	if (isAllAdmin) {	// 全体管理権限があるとき
		nav = (
			<List>
				<ListButton text="新規登録" to="/register" onClick={props.onClose} icon={<PersonAddIcon />} />
				<ListButton text="顧客リスト" to="/customers" onClick={props.onClose} icon={<PeopleIcon />} />
				<ListButton text="スケジュール" to="/schedules" onClick={props.onClose} icon={<EventIcon />} />
				<ListButton text="施術一覧" to="/treatments" onClick={props.onClose} icon={<FormatListBulletedIcon />} />
				<ListButton text="測定結果" to="/measurements" onClick={props.onClose} icon={<DescriptionIcon />} />
				<ListButton text="契約一覧" to="/contracts" onClick={props.onClose} icon={<PaymentIcon />} />
				<ListButton text="公開設定" to="/releases" onClick={props.onClose} icon={<EventAvailableIcon />} />
				<ListButton text="サロン情報" to="/" onClick={openHomepage} icon={<HomeIcon />} />
				<ListButton text="ログアウト" to="/logout" onClick={props.onClose} icon={<LockOutlinedIcon />} />
			</List>
		);
	} else if (isPersonalAdmin) {	// 個人管理権限があるとき
		nav = (
			<List>
				{canReserve && <ListButton text="空き状況" to="/schedules" onClick={props.onClose} icon={<EventIcon />} />}
				<ListButton text="予約リスト" to="/reservations" onClick={props.onClose} icon={<EventAvailableIcon />} />
				<ListButton text="測定結果" to="/measurements" onClick={props.onClose} icon={<DescriptionIcon />} />
				<ListButton text="登録情報" to="/personal" onClick={props.onClose} icon={<ListAltIcon />} />
				<ListButton text="サロン情報" to="/" onClick={openHomepage} icon={<HomeIcon />} />
				<ListButton text="ログアウト" to="/logout" onClick={props.onClose} icon={<LockOutlinedIcon />} />
			</List>
		);
	} else {
		nav = (
			<List>
				<ListButton text="ログイン" to="/login" onClick={props.onClose} icon={<InputIcon />} />
				<ListButton text="サロン情報" to="/" onClick={openHomepage} icon={<HomeIcon />} />
			</List>
		);
	}

	// 描画
	const classes = useStyles();
	return (
		<div>
			<Hidden mdUp implementation='css'>
				<Drawer variant='temporary' anchor='left' open={props.open} onClose={props.onClose}
					classes={{ paper: classes.drawerPaper }} ModalProps={{ keepMounted: true }}>
					{nav}
				</Drawer>
			</Hidden>
			<Hidden smDown implementation='css'>
				<Drawer variant='permanent' open classes={{ paper: classes.drawerPaper }}>
					<div className={classes.nav}>
						{nav}
					</div>
				</Drawer>
			</Hidden>
		</div>
	);
}