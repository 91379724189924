import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import InputField from '~/component/common/InputField';
import ScrollDialog, { ButtonInfo } from '~/component/common/ScrollDialog';

// プロパティ
type Props = {
    /** タイトル */
    title: string,
    /** メッセージ */
    message: string,
    /** 入力ラベル */
    label: string,
    /** ボタンタイプ default='ok' */
    buttonType?: 'ok' | 'okCancel' | 'okBack',
    /** ダイアログ外で閉じるか default='false' */
    isOutsideClose?: boolean,
    /** 閉じるイベント */
    onClose?: () => void,
    /** 戻るイベント */
    onBack?: () => void,
    /** 送信イベント */
    onSubmit: (input: string) => void,
};

/** メッセージ入力ダイアログ */
export default function InputDialog(props: Props): JSX.Element {
    // 入力フォーム
    const [input, setInput] = useState<string>("");
    // history
    const history = useHistory();

    // 閉じる
    const close = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    // キャンセルボタンが押された際に呼ばれる
    const onCancelButtonClick = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    // 戻る
    const back = () => {
        if (props.onBack) {
            props.onBack();
        } else {
            history.goBack();
        }
    };

    // 送信する
    const submit = () => {
        props.onSubmit(input);
    };

    // 入力内容が変更されたときに呼ばれる
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInput(event.target.value);
    };

    let buttons: ButtonInfo[];
    if ((props.buttonType ?? 'ok') == 'ok') {
        buttons = [
            { label: "OK", variant: 'contained', color: 'primary', onClick: submit },
        ];
    } else if (props.buttonType == 'okCancel') {
        buttons = [
            { label: "OK", variant: 'contained', color: 'primary', onClick: submit },
            { label: "キャンセル", variant: 'outlined', color: 'default', onClick: onCancelButtonClick },
        ];
    } else {
        buttons = [
            { label: "OK", variant: 'contained', color: 'primary', onClick: submit },
            { label: "戻る", variant: 'outlined', color: 'default', onClick: back },
        ];
    }

    // 描画
    return (
        <ScrollDialog title={props.title} buttons={buttons} closeButton={false}
            isOutsideClose={props.isOutsideClose ?? false} onClose={close}>
            {props.message}
            <InputField label={props.label} name='input' width='200px' required={true} autoFocus={true}
                value={input} onChange={onInputChange}
            />
        </ScrollDialog>
    );
}