import { createTheme } from '@material-ui/core';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ee7800',
            contrastText: '#ffffff',
        }
    },
    props: {
        MuiTextField: {
            variant: "outlined"
        },
        MuiCheckbox: {
            color: "primary"
        },
        MuiRadio: {
            color: "primary"
        },
        MuiSwitch: {
            color: "primary"
        },
        MuiTypography: {
            variantMapping: {
                h2: 'h2',
            },
        },
    }
});

export default theme;