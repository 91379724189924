/** 顧客情報補助クラス */
export class CustomerAuxil {
	/** 新規用の仮顧客IDの桁数 */
	protected static readonly TEMPORARY_ID_DIGIT = 2;
	/** メールアドレス無効値の文字列長 */
	protected static readonly INVALID_MAIL_ADDRESS_LENGTH = 100;

	/** 仮顧客か */
	static isTemporaryCustomer(customerId: number): boolean {
		return (customerId < Math.pow(10, CustomerAuxil.TEMPORARY_ID_DIGIT));
	}
	/** メールアドレスが無効値か */
	static isMailAddressInvalid(mailAddress: string): boolean {
		return (mailAddress.length == CustomerAuxil.INVALID_MAIL_ADDRESS_LENGTH
			&& !mailAddress.includes('@'));
	}
}